import { getAuthorizationParamsWithScope } from 'app/hooks';
import { store } from 'src/store';
import { Permissions } from '../../../app/constants/permissions';
import HTTP from '../httpImpls/fetch';
import { DriveWealthError, DriveWealthSessionError } from './Error';

/**
 * @function request
 * @description Normally, it is unnecessary to use this function and this is for internal use only.
 *  Call request() if you need to make a custom API call that is not covered by another function.
 * @param {object} options
 * @param {string} [options.method] - HTTP method (GET, POST, PUT, PATCH, DELETE)
 * @param {string} options.endpoint - API endpoint to call
 * @param {string | Object} [options.body] - Request body
 * @param {Object.<string, string>} [options.addlHeaders] - Additional headers
 * @param {string} [options.externalUrl] - External URL to override the endpoint
 * @param {boolean} [options.noAuth=false] - Flag to skip authentication header
 * @param {Permissions} [options.scope] - Permission scope for the request
 * @returns {Promise.<{body: string | Object | boolean, statusCode: number, headers: Object<string, string>}>}
 * @deprecated
 */
export default function request({
  method = 'GET',
  endpoint,
  body,
  addlHeaders = {},
  externalUrl,
  noAuth = false,
  scope,
}) {
  return new Promise(async (resolve, reject) => {
    const { auth } = store.getState();

    const token = await auth.accessTokenFunction(getAuthorizationParamsWithScope(scope));

    // Initialize headers
    const headers = {
      Accept: 'application/json',
    };

    // Set Content-Type to JSON for certain methods if not already set
    const shouldDefaultContentTypeToJSON =
      method === 'POST' || method === 'PUT' || method === 'PATCH';
    if (shouldDefaultContentTypeToJSON && !addlHeaders['Content-type']) {
      headers['Content-Type'] = 'application/json';
    }

    // Add additional headers
    for (const header in addlHeaders) {
      if (!addlHeaders[header] || addlHeaders[header] === 'form-data') continue;
      headers[header] = addlHeaders[header];
    }

    // Add authentication headers if noAuth is false
    if (!noAuth) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    // Ensure endpoint starts with /api and has proper path structure
    if (!endpoint.startsWith('/')) {
      endpoint = '/' + endpoint;
    }
    if (!endpoint.includes('/api')) {
      endpoint = '/api' + endpoint;
    }

    // Make the HTTP request
    HTTP(method, externalUrl || endpoint, headers, body, (statusCode, resHeaders, resBody) => {
      // Parse JSON response if applicable
      const contentType = resHeaders['Content-Type'] || resHeaders['content-type'] || '';
      if (resBody && contentType.indexOf('application/json') !== -1) {
        try {
          resBody = JSON.parse(resBody);
        } catch (err) {
          // resBody will remain as is
        }
      }

      // Resolve or reject the promise based on status code
      if (String(statusCode)[0] === '2' || String(statusCode)[0] === '3') {
        return resolve({
          body: resBody,
          statusCode,
          headers: resHeaders,
        });
      }

      // Handle error responses
      let errorMessage = 'No error message was returned from the server.';
      let errorCode = '-1';
      if (resBody) {
        if (resBody.error) {
          errorMessage = resBody.error.message;
          errorCode = resBody.error.errorCode;
        }

        if (resBody.message && resBody.errorCode) {
          errorMessage = resBody.message;
          errorCode = resBody.errorCode;
        }

        if (resBody.verificationResponse) {
          errorMessage = resBody.verificationResponse.message;
          errorCode = '-1';
        }
      }

      if (statusCode === 504) {
        errorMessage = resBody.message;
        errorCode = '-1';
      }

      if (statusCode === 401) {
        console.log('401 error in request.js, signing out - 146');
        return reject(
          new DriveWealthSessionError(errorMessage, resBody, statusCode, resHeaders, errorCode),
        );
      }

      // Redirect if server middleware detects an invalid IP
      const INVALID_IP_MSG = 'Invalid IP';
      if (statusCode === 403 && resBody && resBody.message === INVALID_IP_MSG) {
        console.log('403 error in request.js, signing out - 162');
        return reject(
          new DriveWealthSessionError(errorMessage, resBody, statusCode, resHeaders, errorCode),
        );
      }

      return reject(new DriveWealthError(errorMessage, resBody, statusCode, resHeaders, errorCode));
    });
  });
}
