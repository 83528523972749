import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { Location } from 'history';
import { isUndefined } from 'lodash';
import { match as RouterMatch } from 'react-router-dom';
import { FeatureToggle } from 'src/app/constants';

import { AllBatchPermissions, Permissions } from '../../constants';
import { copyText } from '../../internationalization';
import { MarginIcon, ReportingIcon } from '../Icons';
import { RouterConfig } from './types';

const {
  DOCUMENTS_STATUS_EDIT,
  USERS_EDIT_PRINCIPAL_STATUS,
  USERS_EDIT_KYC_STATUS,
  DEPOSITS_GET_ALL,
  REDEMPTIONS_GET_ALL,
  SUBSCRIPTIONS_GET,
  RECONCILIATIONS_GET_ALL,
  RECONCILIATIONS_VIEW,
  BULK_SETTLEMENTS_VIEW,
  SINGLE_SETTLEMENT_VIEW,
  USERS_CREATE_ADVISOR,
  WLP_GET,
  WLP_FINTRAN_TYPES_VIEW,
  PERMISSIONS_UPDATE,
  INSTRUMENTS_VIEW,
  INSTRUMENTS_CREATE,
  INSTRUMENT_GROUP_READ,
  INSTRUMENT_GROUP_CREATE,
  MARGIN_CALL_ALERT_GET,
  REPORTS_GET,
  MANAGE_TEAM_VIEW,
  TAX_CENTER_UTIL_LINK_VIEW,
  PARTNER_SETTING_VIEW,
  VIRTUAL_ACCOUNTS_VIEW,
  VIEW_RECENT_BATCHES,
  BROKER_DAILY_READ,
  POSITION_READ,
  DAILY_CALC_READ,
  RECON_READ,
  RECON_HISTORY_READ,
  INSTRUMENT_REQUEST_CREATE,
  OMNI_VIEW,
  UPLOAD_ERRORS,
  DAILY_LENDABLE,
  SEC_LENDING_CLIENT_DAILY_READ,
  SEC_LENDING_CLIENT_MTD_READ,
  SEC_LENDING_CLIENT_EOM_READ,
  SEC_LENDING_PARTNER_DAILY_READ,
  SEC_LENDING_PARTNER_MTD_READ,
  SEC_LENDING_SUPPLEMENTAL_DAILY_READ,
  SEC_LENDING_DW_MTD_READ,
  SEC_LENDING_COLLATERAL_READ,
  CANCEL_REBILL_READ,
  ACAT_VIEW,
  MINOR_GRADUATION_VIEW,
  MANAGE_EXCLUSIONS_READ,
} = Permissions;
export type IsActiveMatch = RouterMatch<Record<string, string | undefined>> | null;

const getPathParts = (path: string) => path.split('/').filter(part => part !== '');

const {
  navbar: {
    routes: {
      settings,
      platformSettings,
      manageCustomers,
      managePartners,
      manageTeam,
      dashboard,
      clients,
      create,
      virtualAccounts,
      deposits,
      withdrawals,
      subscriptions,
      reconciliations,
      bulkFunding,
      settlements,
      cancelAndRebill,
      assetTransfer,
      partners,
      createAdvisor,
      createWLP,
      permissions,
      wlpFintranTypes,
      instruments,
      search,
      instrumentGroups,
      createInstrumentGroup,
      batch,
      createBatch,
      viewRecentBatches,
      reports,
      marginCalls,
      otherReports,
      requests,
      omni,
      uploadErrors,
      dailyLendable,
      manageExclusions,
    },
  },
} = copyText;

export const getSettings = (featureToggle: FeatureToggle): RouterConfig[] => {
  // eslint-disable-next-line no-empty-pattern
  const {} = featureToggle;

  return [
    {
      icon: SettingsIcon,
      text: settings,
      permissions: [],
      environments: ['LIVE', 'TEST'],
      subRoutes: [
        {
          text: platformSettings,
          permissions: [],
          path: '/settings',
          exact: true,
          environments: ['LIVE', 'TEST'],
        },
        {
          text: manageTeam,
          permissions: [MANAGE_TEAM_VIEW],
          path: '/manage-users',
          exact: true,
          environments: ['LIVE', 'TEST'],
        },
        {
          text: manageCustomers,
          permissions: [TAX_CENTER_UTIL_LINK_VIEW],
          path: '/manage-customers',
          exact: true,
          environments: ['LIVE', 'TEST'],
        },
        {
          text: managePartners,
          permissions: [PARTNER_SETTING_VIEW],
          path: '/manage-partners',
          exact: true,
          environments: ['LIVE', 'TEST'],
        },
      ],
    },
  ];
};

export const getRoutes = (featureToggle: FeatureToggle): RouterConfig[] => {
  const { FPSL_VIEW, CANCEL_REBILL } = featureToggle;

  const fpslRoutes: RouterConfig[] = FPSL_VIEW
    ? [
        {
          text: omni,
          path: '/sec-lending/omni',
          permissions: [OMNI_VIEW],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: uploadErrors,
          path: '/sec-lending/upload-errors',
          permissions: [UPLOAD_ERRORS],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: dailyLendable,
          path: '/sec-lending/daily-lendable',
          permissions: [DAILY_LENDABLE],
          environments: ['LIVE', 'TEST'],
        },
      ]
    : [];

  const clientRoutes: RouterConfig[] = [
    {
      text: create,
      permissions: [
        DOCUMENTS_STATUS_EDIT,
        USERS_EDIT_PRINCIPAL_STATUS,
        USERS_EDIT_KYC_STATUS,
        DEPOSITS_GET_ALL,
        REDEMPTIONS_GET_ALL,
        SUBSCRIPTIONS_GET,
        RECONCILIATIONS_GET_ALL,
        BULK_SETTLEMENTS_VIEW,
        SINGLE_SETTLEMENT_VIEW,
        CANCEL_REBILL_READ,
        ACAT_VIEW,
        MINOR_GRADUATION_VIEW,
      ],
      environments: ['LIVE', 'TEST'],
      path: '/users/new',
    },
    {
      text: virtualAccounts,
      permissions: [VIRTUAL_ACCOUNTS_VIEW],
      environments: ['LIVE', 'TEST'],
      path: '/virtual-accounts?status=All',
    },
    {
      text: deposits,
      permissions: [DEPOSITS_GET_ALL],
      environments: ['LIVE', 'TEST'],
      path: '/deposits?status=PENDING',
    },
    {
      text: withdrawals,
      permissions: [REDEMPTIONS_GET_ALL],
      environments: ['LIVE', 'TEST'],
      path: '/redemptions?status=PENDING',
    },
    {
      text: subscriptions,
      permissions: [SUBSCRIPTIONS_GET],
      environments: ['LIVE', 'TEST'],
      path: '/subscriptions?status=PENDING',
    },
    {
      text: reconciliations,
      permissions: [RECONCILIATIONS_VIEW],
      environments: ['LIVE', 'TEST'],
      path: '/reconciliations',
    },
    {
      text: bulkFunding,
      permissions: [BULK_SETTLEMENTS_VIEW],
      environments: ['LIVE', 'TEST'],
      path: '/settlements',
    },
    CANCEL_REBILL
      ? {
          text: cancelAndRebill,
          permissions: [CANCEL_REBILL_READ],
          environments: ['LIVE', 'TEST'],
          path: '/cancel-rebill',
        }
      : undefined,
    {
      text: assetTransfer,
      permissions: [ACAT_VIEW, MINOR_GRADUATION_VIEW],
      environments: ['LIVE', 'TEST'],
      path: '/asset-transfers',
    },
    {
      text: manageExclusions,
      permissions: [MANAGE_EXCLUSIONS_READ],
      environments: ['LIVE', 'TEST'],
      path: '/manage-exclusions',
    },
    {
      text: settlements,
      permissions: [SINGLE_SETTLEMENT_VIEW],
      environments: ['LIVE', 'TEST'],
      path: '/settlements-page', // TODO: change the name of this path to settlements when the page replaces the bulk funding page
    },
  ].filter(route => !isUndefined(route)) as RouterConfig[];

  return [
    {
      icon: DashboardOutlined,
      permissions: [],
      environments: ['LIVE', 'TEST'],
      text: dashboard,
      path: '/',
      exact: true,
    },
    {
      icon: HomeWorkOutlinedIcon,
      text: clients,
      permissions: [],
      environments: ['LIVE', 'TEST'],
      subRoutes: clientRoutes,
    },
    {
      text: partners,
      icon: PeopleAltOutlinedIcon,
      permissions: [],
      environments: ['LIVE', 'TEST'],
      subRoutes: [
        {
          text: createAdvisor,
          path: '/advisors/new',
          permissions: [USERS_CREATE_ADVISOR],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: createWLP,
          path: '/partners',
          permissions: [WLP_GET],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: permissions,
          path: '/permissions',
          permissions: [PERMISSIONS_UPDATE],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: wlpFintranTypes,
          path: '/transactions/types',
          permissions: [WLP_FINTRAN_TYPES_VIEW],
          environments: ['LIVE', 'TEST'],
        },
      ],
    },
    {
      text: instruments,
      icon: BuildOutlinedIcon,
      environments: ['LIVE', 'TEST'],
      subRoutes: [
        {
          text: 'Instruments List',
          path: '/instruments/list',
          exact: true,
          permissions: [INSTRUMENTS_VIEW],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: search,
          path: '/instruments',
          permissions: [INSTRUMENTS_VIEW],
          environments: ['LIVE', 'TEST'],
          isActive: (match: IsActiveMatch, location: Location): boolean => {
            if (match?.isExact) {
              return true;
            }

            const { pathname } = location;
            const parts = getPathParts(pathname);

            return (
              parts[0] === 'instruments' &&
              parts[1] !== 'new' &&
              parts[1] !== 'requests' &&
              parts[1] !== 'list'
            );
          },
        },
        {
          text: create,
          path: '/instruments/new',
          exact: true,
          permissions: [INSTRUMENTS_CREATE],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: requests,
          path: '/instruments/requests',
          exact: true,
          permissions: [INSTRUMENT_REQUEST_CREATE],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: instrumentGroups,
          path: '/instrumentGroups',
          permissions: [INSTRUMENT_GROUP_READ],
          environments: ['LIVE', 'TEST'],
          isActive: (match: IsActiveMatch, location: Location): boolean => {
            if (match?.isExact) {
              return true;
            }

            const { pathname } = location;
            const parts = getPathParts(pathname);

            return parts[0] === 'instrumentGroups' && parts[1] !== 'create';
          },
        },
        {
          text: createInstrumentGroup,
          path: '/instrumentGroups/create',
          exact: true,
          permissions: [INSTRUMENT_GROUP_CREATE],
          environments: ['LIVE', 'TEST'],
        },
      ],
      permissions: [],
    },
    {
      icon: FolderOpenOutlinedIcon,
      text: batch,
      permissions: [],
      environments: ['LIVE', 'TEST'],
      subRoutes: [
        {
          text: createBatch,
          permissions: Object.values(AllBatchPermissions),
          environments: ['LIVE', 'TEST'],
          path: '/batch/create',
          isActive: (match: IsActiveMatch, location: Location): boolean => {
            if (match?.isExact) {
              return true;
            }

            const { pathname } = location;
            const parts = getPathParts(pathname);

            return parts[0] === 'batch' && parts[1] === 'preview';
          },
        },
        {
          text: viewRecentBatches,
          permissions: [VIEW_RECENT_BATCHES],
          environments: ['LIVE', 'TEST'],
          path: '/batch/list',
          isActive: (match: IsActiveMatch, location: Location): boolean => {
            if (match?.isExact) {
              return true;
            }

            const { pathname } = location;
            const parts = getPathParts(pathname);

            return parts[0] === 'batch' && parts[1] === 'details';
          },
        },
      ],
    },
    {
      icon: ReportingIcon,
      text: reports,
      permissions: [],
      environments: ['LIVE', 'TEST'],
      subRoutes: [
        {
          text: marginCalls,
          path: '/margin-call',
          permissions: [MARGIN_CALL_ALERT_GET],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: otherReports,
          path: '/reporting',
          permissions: [
            REPORTS_GET,
            SEC_LENDING_CLIENT_DAILY_READ,
            SEC_LENDING_CLIENT_MTD_READ,
            SEC_LENDING_CLIENT_EOM_READ,
            SEC_LENDING_PARTNER_DAILY_READ,
            SEC_LENDING_PARTNER_MTD_READ,
            SEC_LENDING_SUPPLEMENTAL_DAILY_READ,
            SEC_LENDING_DW_MTD_READ,
            SEC_LENDING_COLLATERAL_READ,
          ],
          environments: ['LIVE', 'TEST'],
          isActive: (match: IsActiveMatch, location: Location): boolean => {
            if (match?.isExact) {
              return true;
            }

            const { pathname } = location;
            const parts = getPathParts(pathname);

            return parts[0] === 'reporting';
          },
        },
      ],
    },
    {
      icon: MarginIcon,
      text: 'Security Lending',
      permissions: [],
      environments: ['LIVE', 'TEST'],
      subRoutes: [
        {
          text: 'Broker Daily',
          path: '/sec-lending/broker-daily',
          permissions: [BROKER_DAILY_READ],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: 'Positions',
          path: '/sec-lending/positions',
          permissions: [POSITION_READ],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: 'Daily Calculation',
          path: '/sec-lending/daily-calculation',
          permissions: [DAILY_CALC_READ],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: 'Reconciliation',
          path: '/sec-lending/reconciliation',
          permissions: [RECON_READ],
          environments: ['LIVE', 'TEST'],
        },
        {
          text: 'Reconciliation History',
          path: '/sec-lending/reconciliation-history',
          permissions: [RECON_HISTORY_READ],
          environments: ['LIVE', 'TEST'],
        },
        ...fpslRoutes,
      ],
    },
  ];
};
