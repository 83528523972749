/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const instrumentEdit = {
  displayName: 'Instrument Edit',
  permission: 'INSTRUMENTS_EDIT_CREATE_BATCH',
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    symbol: {
      label: 'Symbol',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'symbol',
    },
    primaryric: {
      label: 'Primary Ric',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'primaryRic',
    },
    name: {
      label: 'Name',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'name',
    },
    instrumenttype: {
      label: 'Instrument Type',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'instrumentType',
    },
    tradestatus: {
      label: 'Trade Status',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'tradeStatus',
    },
    urlinvestor: {
      label: 'URL Investor',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'urlInvestor',
    },
    closeprice: {
      label: 'Close Price',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'price',
      serverKey: 'closePrice',
    },
    priorclose: {
      label: 'Prior Close',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'price',
      serverKey: 'priorClose',
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      symbol: 'ACBI',
      primaryRic: 'ACBI.O',
      name: 'ATLANTIC CAPITAL BANCSHARES',
      instrumentType: 'EQUITY',
      tradeStatus: 'ACTIVE',
      urlInvestor:
        'https://www.sec.gov/cgi-bin/browse-edgar?CIK=ACBI&owner=exclude&action=getcompany',
      closePrice: 100,
      priorClose: 100,
    },
  ],
};

export { instrumentEdit };
