//
import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

import { determineByPermissions, getAllPermissions } from './utils';

export const initialState = {
  allRoles: [],
  byID: {},
  byPermission: {},
  allPermissions: [],
};

const handlers = {
  PERMISSIONS_ERROR: null,
  PERMISSIONS_GET_ALL_START: (state, action) => state,
  PERMISSIONS_GET_ALL_SUCCESS: (state, action) =>
    produce(state, draftState => {
      draftState.byPermission = action.payload.byPermission;
    }),
  PERMISSIONS_GET_ALL_GROUPS_START: (state, action) => state,
  PERMISSIONS_GET_ALL_GROUPS_SUCCESS: (state, action) =>
    produce(state, draftState => {
      draftState.allRoles = action.payload.allRoles;
      draftState.byID = action.payload.byID;
      draftState.allPermissions = getAllPermissions(draftState.byID);
    }),
  PERMISSIONS_CREATE_GROUP_START: (state, action) => state,
  PERMISSIONS_CREATE_GROUP_SUCCESS: (state, action) =>
    produce(state, draftState => {
      const newRole = action.payload.newRole;
      const allRoles = draftState.allRoles;
      const byID = draftState.byID;

      byID[newRole.id] = newRole;
      allRoles.push(newRole.role);
      const allPermissions = [...draftState.allPermissions, ...newRole.permissionSet];
      const byPermission = determineByPermissions(byID, allPermissions);

      newRole.permissionSet.map(permission => {
        if (!byPermission[permission].includes(newRole.role)) {
          byPermission[permission].push(newRole.role);
        }
        return byPermission[permission];
      });

      draftState.byID = byID;
      draftState.allRoles = allRoles;
      draftState.byPermission = byPermission;
    }),
  PERMISSIONS_EDIT_ROLE_START: (state, action) => state,
  PERMISSIONS_EDIT_ROLE_SUCCESS: (state, action) =>
    produce(state, draftState => {
      const edditedRole = action.payload.edittedRole;
      draftState.byID[edditedRole.permissionID] = {
        active: edditedRole.active,
        description: edditedRole.description,
        id: edditedRole.permissionID,
        permissionSet: action.payload.edittedRole.permissionList,
        role: edditedRole.role,
      };
      draftState.allPermissions = getAllPermissions(draftState.byID);
      draftState.byPermission = determineByPermissions(draftState.byID, draftState.allPermissions);
    }),
};

export default generateReducer(initialState, handlers);
