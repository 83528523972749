import { Auth0QueryFn } from 'app/hooks';
import { WLPCreateAPIResponse } from 'app/components/WLP/wlp';

const endpoint = new URL('/api/wlp', window.location.origin).href;

/**
 * Fetches the list of wlps.
 *
 * @param {typeof global.fetch} fetch - The fetch function to use for the request.
 * @returns {Promise<WLPCreateAPIResponse[]>} A promise that resolves to an array of wlps.
 */
export const fetchWlps: Auth0QueryFn<WLPCreateAPIResponse[]> =
  fetch =>
  async ({ signal }: { signal: AbortSignal | null | undefined }) => {
    const data = await fetch(endpoint, { signal });

    return data.json();
  };
