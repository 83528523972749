import { Auth0QueryFn } from 'app/hooks';
import { WhoAmI } from 'app/types';

const endpoint = new URL(`/api/employees/me`, window.location.origin).href;

/**
 * Fetches the current user's information.
 *
 * @param {typeof global.fetch} fetch - The fetch function to use for the request.
 * @returns {Promise<WhoAmI>} A promise that resolves to the current user's information.
 */
export const fetchWhoAmI: Auth0QueryFn<WhoAmI> =
  fetch =>
  async ({ signal }) => {
    const data = await fetch(endpoint, { signal });
    const json = await data.json();

    return json;
  };
