import { useState } from 'react';
import { useTimeoutFn } from 'react-use';

import { dashboardAcknowledgmentQueries, disclosureQueries } from 'app/components/queries';
import { useAuth0Query } from './auth0';
import { useWhoAmI } from './useWhoAmI';

/**
 * Custom hook to track the initial loading state of the app.
 *
 * This hook checks if the acknowledgments or disclosures are still loading,
 * and if the user permissions have been loaded. It also ensures that at least
 * one second has passed before returning the loading state.
 *
 * @returns {boolean} - Returns true if the app is still loading, otherwise false.
 */
export const useStartUpLoader = () => {
  const { isSuccess: arePermissionsLoaded } = useWhoAmI();

  const { isLoading: isAcknowledgmentsLoading } = useAuth0Query(
    dashboardAcknowledgmentQueries.list(),
  );

  const { isLoading: isDisclosureLoading } = useAuth0Query(disclosureQueries.list());

  const areAcknowledgmentsLoading = isAcknowledgmentsLoading || isDisclosureLoading;

  const [isGreaterThanOneSecond, setIsGreaterThanOneSecond] = useState(false);

  useTimeoutFn(() => {
    setIsGreaterThanOneSecond(true);
  }, 1000);

  return (areAcknowledgmentsLoading || !arePermissionsLoaded) && isGreaterThanOneSecond;
};
