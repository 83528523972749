import { createElement, FC, forwardRef, useMemo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { usePermission } from '../../hooks/usePermission';
import { NavListItem } from './NavListItem';
import { NavbarListItemIcon } from './NavListItemIcon';
import { NavListItemText } from './NavListItemText';
import { NavProps, Variant } from './types';
import { getBackgroundColor, getListItemColor } from './utils';

const StyledNavLink = styled(NavLink)<{ variant: Variant }>`
  &.${props => props.activeClassName} {
    background-color: ${props => getBackgroundColor(true, props.variant, props.theme)};

    svg {
      color: ${props => getListItemColor({ ...props, isActive: true })};
    }

    .MuiListItemText-primary {
      color: ${props => getListItemColor({ ...props, isActive: true })};
    }
  }
`;

type NavItemProps = NavProps & {
  variant: Variant;
  path: string;
};

export const NavItem: FC<NavItemProps> = props => {
  const { icon, isActive, exact, path, permissions = [], text, variant } = props;

  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>((itemProps, ref) => (
        <StyledNavLink
          to={path}
          ref={ref}
          activeClassName="active-route"
          isActive={isActive}
          variant={variant}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...itemProps}
        />
      )),
    [isActive, path, variant],
  );

  const hasPermission = usePermission(permissions);
  // TODO: Clean this up with removal of environments
  const isEnvironmentAllowed = true;

  if (!hasPermission || !isEnvironmentAllowed) {
    return null;
  }

  const IconComponent = icon ? createElement(icon) : null;

  return (
    <NavListItem component={renderLink} exact={exact} variant={variant}>
      {variant === 'primary' && (
        <NavbarListItemIcon variant={variant} title={text}>
          {IconComponent}
        </NavbarListItemIcon>
      )}
      <NavListItemText primary={text} variant={variant} />
    </NavListItem>
  );
};
