import { DefaultError } from '@tanstack/react-query';
import { Permissions } from 'app/constants';
import { useSnackbar } from 'app/hooks';
import { useAuth0Mutation } from 'app/hooks/auth0/useAuth0Mutation';
import { handleResponseNotOk } from 'app/services';
import { Transaction } from 'app/types';
import { copyText } from '../internationalization';

const {
  transactions: {
    fintrans: {
      doNotReconcile: { successMessage, errorMessage },
    },
  },
} = copyText;

export type UseFintranDNROptions = {
  accountID: string;
  finTranIDs: [string];
};

const endpoint = new URL('/api/accounts', window.location.origin).href;

export const useFintranDNR = (closeModal: () => void) => {
  const { enqueueSnackbar } = useSnackbar();

  return useAuth0Mutation<Transaction, DefaultError, UseFintranDNROptions>({
    mutationFn:
      fetch =>
      async ({ accountID, finTranIDs }: UseFintranDNROptions): Promise<Transaction> => {
        const data = await fetch(`${endpoint}/${accountID}/transactions`, {
          method: 'PATCH',
          body: JSON.stringify({
            finTranIDs,
            doNotReconcile: true,
          }),
        });
        if (!data.ok) {
          handleResponseNotOk(data);
        }
        return data.json();
      },
    permission: Permissions.FINTRANS_DO_NOT_RECONCILE,
    onError: (error: DefaultError) => {
      const errorText = error.message && error.message.length > 0 ? error.message : errorMessage;
      enqueueSnackbar(errorText, { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar(successMessage, { variant: 'success' });
    },
    onSettled: () => {
      closeModal();
    },
  });
};
