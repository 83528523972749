import 'react-datepicker/dist/react-datepicker.css';
import './InteriorView.scss';

import { CircularProgress } from '@mui/material';
import React from 'react';
import { Switch } from 'react-router-dom';
import { featureTogglesQueries } from 'src/app/components/queries';
import { PermissionedRoute, SentryRoute } from 'src/app/components/Routes';
import { Permissions as TypescriptPermissions } from 'src/app/constants/permissions';
import { useAuth0Query, useFeatureToggle } from 'src/app/hooks';
import styled from 'styled-components';

import HomeView from '../Home/components/HomeView';
import { NotFound } from '../NotFound';
import { Unauthorized } from '../Unauthorized';

const {
  TAX_CENTER_UTIL_LINK_VIEW,
  INSTRUMENT_REQUEST_CREATE,
  REPORTS_GET,
  SEC_LENDING_CLIENT_DAILY_READ,
  SEC_LENDING_CLIENT_MTD_READ,
  SEC_LENDING_CLIENT_EOM_READ,
  SEC_LENDING_PARTNER_DAILY_READ,
  SEC_LENDING_PARTNER_MTD_READ,
  SEC_LENDING_SUPPLEMENTAL_DAILY_READ,
  SEC_LENDING_DW_MTD_READ,
  SEC_LENDING_COLLATERAL_READ,
  REPORTS_GET_INTELLICLEAR,
  REPORTS_GET_INTELLICLEAR_DOWNLOAD,
  END_OF_DAY_TRADING_REPORT_GET,
  REPORTS_GET_BESTEX,
  REPORTS_GET_TPLUSONE,
  REPORTS_GET_PDT,
  REPORTS_GET_RESTRICTED_ACCOUNTS,
  DEPOSITS_GET_BY_ID,
  REDEMPTIONS_GET_BY_ID,
  WLP_GET,
  WLP_CREATE,
  WLP_EDIT,
  MANAGE_EXCLUSIONS_READ,
  WLP_FINTRAN_TYPES_VIEW,
  CANCEL_REBILL_READ,
  ACAT_VIEW,
  MINOR_GRADUATION_VIEW,
  MARGIN_CALL_ALERT_GET,
} = TypescriptPermissions;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.dw.colors.corePrimaryBase};
  color: ${props => props.theme.dw.colors.white};
`;

const Batch = React.lazy(async () => {
  const BatchContainer = await import('../../routes/Batch/containers/BatchContainer');
  return BatchContainer;
});

const CreateUser = React.lazy(async () => {
  const CreateUserView = await import('../CreateUser/CreateUserView');
  return CreateUserView;
});

const InstrumentsList = React.lazy(async () => {
  const { InstrumentsList } = await import(
    '../../../app/components/Instruments/List/InstrumentsList'
  );
  return { default: InstrumentsList };
});

const InstrumentRequestsTable = React.lazy(async () => {
  const { InstrumentRequestsTable } = await import(
    '../../../app/components/Instruments/InstrumentRequests'
  );
  return { default: InstrumentRequestsTable };
});

const CreateInstrument = React.lazy(async () => {
  const { CreateInstrument } = await import(
    '../../../app/components/Instruments/create/CreateInstrument'
  );
  return { default: CreateInstrument };
});

const OptionDetailView = React.lazy(async () => {
  const { OptionDetailView } = await import('../../../app/components/Instruments/OptionDetailView');
  return { default: OptionDetailView };
});

const OptionsChain = React.lazy(async () => {
  const { OptionsChain } = await import('../../../app/components/Instruments/OptionsChain');
  return { default: OptionsChain };
});

const EditInstrumentContainer = React.lazy(async () => {
  const { EditInstrumentContainer } = await import(
    '../../../app/components/Instruments/edit/EditInstrumentContainer'
  );
  return { default: EditInstrumentContainer };
});

const InstrumentDetail = React.lazy(async () => {
  const { InstrumentDetail } = await import('../../../app/components/Instruments');
  return { default: InstrumentDetail };
});

const Instruments = React.lazy(async () => {
  const Instruments = await import('../Instruments/containers/InstrumentsContainer');
  return Instruments;
});

const MarginCall = React.lazy(async () => {
  const { MarginCall } = await import('../MarginCall');
  return { default: MarginCall };
});

const ManageTeam = React.lazy(async () => {
  const { ManageTeam } = await import('../../../app/components/ManageTeam');
  return { default: ManageTeam };
});

const ReportingSwitch = React.lazy(async () => {
  const { ReportingSwitch } = await import('../Reporting/containers/ReportingSwitch');
  return { default: ReportingSwitch };
});

const ReportsTable = React.lazy(async () => {
  const { ReportsTable } = await import('../../../app/components/Reports/ReportsTable');
  return { default: ReportsTable };
});

const User = React.lazy(async () => {
  const { User } = await import('../User/containers/UserContainer');
  return { default: User };
});

const Redemptions = React.lazy(async () => {
  const { Redemptions } = await import('../../pages/Redemptions/Redemptions');
  return { default: Redemptions };
});

const Deposits = React.lazy(async () => {
  const { Deposits } = await import('../../pages/Deposits/Deposits');
  return { default: Deposits };
});

const Subscriptions = React.lazy(async () => {
  const { Subscriptions } = await import('../../pages/Subscriptions/Subscriptions');
  return { default: Subscriptions };
});

const VirtualAccountsTable = React.lazy(async () => {
  const { VirtualAccountsTable } = await import(
    '../../../app/components/VirtualAccountsTable/VirtualAccountsTable'
  );
  return { default: VirtualAccountsTable };
});

const Settings = React.lazy(async () => {
  const { Settings } = await import('../Settings/SettingsView');
  return { default: Settings };
});

const SettlementsTableNew = React.lazy(async () => {
  const { SettlementsTableNew } = await import(
    '../../../app/components/Settlements/SettlementsTableNew'
  );
  return { default: SettlementsTableNew };
});

const SettlementsTable = React.lazy(async () => {
  const { SettlementsTable } = await import('../../../app/components/Settlements/SettlementsTable');
  return { default: SettlementsTable };
});

const CancelRebillTable = React.lazy(async () => {
  const { CancelRebillTable } = await import(
    '../../../app/components/CancelRebill/CancelRebillTable'
  );
  return { default: CancelRebillTable };
});

const AssetTransferPage = React.lazy(async () => {
  const { AssetTransferPage } = await import('../../../app/components/Acat/AssetTransferPage');
  return { default: AssetTransferPage };
});

const ManageExclusionsTable = React.lazy(async () => {
  const { ManageExclusionsTable } = await import('../../../app/components/ManageAccountExclusions');
  return { default: ManageExclusionsTable };
});

const RiaContainer = React.lazy(async () => {
  const { RiaContainer } = await import('../ManageRia');
  return { default: RiaContainer };
});

const ReconciliationsContainer = React.lazy(async () => {
  const { ReconciliationsContainer } = await import(
    '../../../app/components/Reconciliations/ReconciliationsContainer'
  );
  return { default: ReconciliationsContainer };
});

const InstrumentGroupContainer = React.lazy(async () => {
  const { InstrumentGroupContainer } = await import('../InstrumentGroups');
  return { default: InstrumentGroupContainer };
});

const WLPContainer = React.lazy(async () => {
  const { WLPContainer } = await import('../WLP');
  return { default: WLPContainer };
});

const WLPList = React.lazy(async () => {
  const { WLPList } = await import('../../../app/components/WLP');
  return { default: WLPList };
});

const CreateWLP = React.lazy(async () => {
  const { CreateWLP } = await import('../../../app/components/WLP');
  return { default: CreateWLP };
});

const EditWLP = React.lazy(async () => {
  const { EditWLP } = await import('../../../app/components/WLP');
  return { default: EditWLP };
});

const WLPDetailView = React.lazy(async () => {
  const { WLPDetailView } = await import('../../../app/components/WLP');
  return { default: WLPDetailView };
});

const FintransTableView = React.lazy(async () => {
  const { FintransTableView } = await import(
    '../../../app/components/Fintrans/components/FintransTableView'
  );
  return { default: FintransTableView };
});

const ManagePartners = React.lazy(async () => {
  const { ManagePartners } = await import('../../../app/components/ManagePartners');
  return { default: ManagePartners };
});

const ManagePartnersLegacy = React.lazy(async () => {
  const { ManagePartners } = await import('../../../../src/legacy/routes/ManagePartners');
  return { default: ManagePartners };
});

const ManageCustomers = React.lazy(async () => {
  const { ManageCustomers } = await import('../../../app/components/ManageCustomers');
  return { default: ManageCustomers };
});

const SecLending = React.lazy(async () => {
  const { SecLending } = await import('../../../app/components/SecLending');
  return { default: SecLending };
});

const Interior = props => {
  const OPTIONS = useFeatureToggle('OPTIONS');
  const CANCEL_REBILL = useFeatureToggle('CANCEL_REBILL');
  const MANAGE_PARTNERS_PAGE = useFeatureToggle('MANAGE_PARTNERS_PAGE');
  const WLP_REDESIGN = useFeatureToggle('WLP_REDESIGN');

  const { isLoading } = useAuth0Query(featureTogglesQueries.list());

  // the error returned in the comment below could be handled or passed to a logger as needed.

  return (
    <Container>
      <React.Suspense fallback={<CircularProgress />}>
        {isLoading ? null : (
          <Switch>
            <SentryRoute path="/batch" component={Batch} />
            <SentryRoute exact path="/" component={HomeView} />
            <SentryRoute path="/users/new" component={CreateUser} />
            <SentryRoute path="/instruments/list" component={InstrumentsList} />
            <PermissionedRoute
              path="/instruments/requests"
              component={InstrumentRequestsTable}
              permissions={INSTRUMENT_REQUEST_CREATE}
              environments={['LIVE', 'TEST']}
            />
            <PermissionedRoute
              permissions="INSTRUMENTS_CREATE"
              path="/instruments/new"
              component={CreateInstrument}
              environments={['LIVE', 'TEST']}
            />
            {OPTIONS && (
              <PermissionedRoute
                permissions="VIEW_OPTION_CHAIN"
                path="/instruments/:id/options/:optionID"
                component={OptionDetailView}
                environments={['LIVE', 'TEST']}
              />
            )}
            {OPTIONS && (
              <PermissionedRoute
                permissions="VIEW_OPTION_CHAIN"
                path="/instruments/:id/options"
                component={OptionsChain}
                environments={['LIVE', 'TEST']}
              />
            )}
            <SentryRoute path="/instruments/:id/edit" component={EditInstrumentContainer} />
            <SentryRoute path="/instruments/:id" component={InstrumentDetail} />
            <SentryRoute path="/instruments" component={Instruments} />
            <PermissionedRoute
              path="/margin-call/:accountID?"
              component={MarginCall}
              permissions={MARGIN_CALL_ALERT_GET}
              environments={['LIVE', 'TEST']}
            />
            <PermissionedRoute
              path="/manage-users/:partnerId?"
              permissions="MANAGE_TEAM_VIEW"
              component={ManageTeam}
              environments={['LIVE', 'TEST']}
            />
            <SentryRoute path="/reporting/*" component={ReportingSwitch} />

            <PermissionedRoute
              path="/reporting"
              component={ReportsTable}
              environments={['LIVE', 'TEST']}
              permissions={[
                REPORTS_GET,
                SEC_LENDING_CLIENT_DAILY_READ,
                SEC_LENDING_CLIENT_MTD_READ,
                SEC_LENDING_CLIENT_EOM_READ,
                SEC_LENDING_PARTNER_DAILY_READ,
                SEC_LENDING_PARTNER_MTD_READ,
                SEC_LENDING_SUPPLEMENTAL_DAILY_READ,
                SEC_LENDING_DW_MTD_READ,
                SEC_LENDING_COLLATERAL_READ,
                REPORTS_GET_INTELLICLEAR,
                REPORTS_GET_INTELLICLEAR_DOWNLOAD,
                END_OF_DAY_TRADING_REPORT_GET,
                REPORTS_GET_BESTEX,
                REPORTS_GET_TPLUSONE,
                REPORTS_GET_PDT,
                REPORTS_GET_RESTRICTED_ACCOUNTS,
                DEPOSITS_GET_BY_ID,
                REDEMPTIONS_GET_BY_ID,
              ]}
            />

            {/* Users is expecting to have the userID at the top level to handle fetching and then delegate down */}
            <SentryRoute path="/users/:userID/(accounts)/:accountID?" component={User} />
            <SentryRoute path="/users/:userID" component={User} />
            <SentryRoute path="/redemptions" component={Redemptions} />
            <SentryRoute path="/deposits" component={Deposits} />
            <SentryRoute path="/subscriptions" component={Subscriptions} />
            <SentryRoute path="/virtual-accounts" component={VirtualAccountsTable} />
            <SentryRoute path="/settings" component={Settings} />
            <PermissionedRoute
              path="/settlements-page"
              component={SettlementsTableNew}
              permissions={['SINGLE_SETTLEMENT_VIEW']}
              environments={['LIVE', 'TEST']}
            />

            <SentryRoute path="/settlements" component={SettlementsTable} />

            {CANCEL_REBILL && (
              <PermissionedRoute
                path="/cancel-rebill"
                component={CancelRebillTable}
                permissions={[CANCEL_REBILL_READ]}
                environments={['LIVE', 'TEST']}
              />
            )}

            <PermissionedRoute
              path="/asset-transfers"
              component={AssetTransferPage}
              permissions={[ACAT_VIEW, MINOR_GRADUATION_VIEW]}
              environments={['LIVE', 'TEST']}
            />

            <PermissionedRoute
              path="/manage-exclusions"
              component={ManageExclusionsTable}
              permissions={MANAGE_EXCLUSIONS_READ}
              environments={['LIVE', 'TEST']}
            />
            <SentryRoute path="/unauthorized" component={Unauthorized} />
            <SentryRoute path="/advisors" component={RiaContainer} />
            <SentryRoute path="/reconciliations" component={ReconciliationsContainer} />
            <SentryRoute
              path="/instrumentGroups/:instrumentGroupID"
              component={InstrumentGroupContainer}
            />
            <SentryRoute path="/instrumentGroups" component={InstrumentGroupContainer} />
            {WLP_REDESIGN && (
              <PermissionedRoute
                path="/partners/create"
                component={CreateWLP}
                permissions={WLP_CREATE}
                environments={['LIVE', 'TEST']}
              />
            )}
            {WLP_REDESIGN && (
              <PermissionedRoute
                path="/partners/:wlpID/edit"
                component={EditWLP}
                permissions={WLP_EDIT}
                environments={['LIVE', 'TEST']}
              />
            )}
            {WLP_REDESIGN ? (
              <PermissionedRoute
                path="/partners/:wlpID"
                component={WLPDetailView}
                permissions={WLP_GET}
                environments={['LIVE', 'TEST']}
              />
            ) : (
              <PermissionedRoute
                path="/partners/:wlpID"
                component={WLPContainer}
                permissions={WLP_GET}
                environments={['LIVE', 'TEST']}
              />
            )}
            {WLP_REDESIGN ? (
              <PermissionedRoute
                path="/partners"
                component={WLPList}
                permissions={WLP_GET}
                environments={['LIVE', 'TEST']}
              />
            ) : (
              <PermissionedRoute
                path="/partners"
                component={WLPContainer}
                permissions={WLP_GET}
                environments={['LIVE', 'TEST']}
              />
            )}
            <PermissionedRoute
              path="/transactions/types"
              component={FintransTableView}
              permissions={WLP_FINTRAN_TYPES_VIEW}
              environments={['LIVE', 'TEST']}
            />
            {MANAGE_PARTNERS_PAGE ? (
              <SentryRoute path="/manage-partners" component={ManagePartners} />
            ) : (
              <SentryRoute path="/manage-partners" component={ManagePartnersLegacy} />
            )}
            <PermissionedRoute
              path="/manage-customers"
              permissions={TAX_CENTER_UTIL_LINK_VIEW}
              component={ManageCustomers}
              environments={['LIVE', 'TEST']}
            />
            <SentryRoute path="/sec-lending" component={SecLending} />
            <SentryRoute path="*" component={NotFound} />
          </Switch>
        )}
      </React.Suspense>
    </Container>
  );
};
export default Interior;
