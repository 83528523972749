import { copyText } from 'src/app/internationalization';
import { ComplianceDocument } from 'src/app/types';
import { neverReached } from 'src/app/utils';

const {
  documents: {
    documentNames: {
      corporate,
      driverLicense,
      nationalIDCard,
      other,
      passport,
      photoID,
      proofOfAddress,
      photoIDAndProofOfAddress,
      residencePermit,
      riskAssessment,
      taxDocument,
      trustDocuments,
      workPermit,
      visa,
      voterID,
      acatsIn,
      acatsOut,
      transferOnDeath,
      onlineAccountApplication,
      foreignFindersFeeAcknowledgment,
      foreignAdvisor,
    },
  },
} = copyText;

export const getDocumentName = (type: ComplianceDocument): string => {
  switch (type) {
    case 'CORPORATE':
      return corporate;
    case 'DRIVER_LICENSE':
      return driverLicense;
    case 'NATIONAL_ID_CARD':
      return nationalIDCard;
    case 'OTHER':
      return other;
    case 'PASSPORT':
      return passport;
    case 'PICTURE_ID':
      return photoID;
    case 'PROOF_OF_ADDRESS':
      return proofOfAddress;
    case 'PICTURE_ID_PROOF_OF_ADDRESS':
      return photoIDAndProofOfAddress;
    case 'RESIDENCE_PERMIT':
      return residencePermit;
    case 'RISK_ASSESSMENT':
      return riskAssessment;
    case 'TAX':
      return taxDocument;
    case 'TRUST_DOCUMENTS':
      return trustDocuments;
    case 'WORK_PERMIT':
      return workPermit;
    case 'VISA':
      return visa;
    case 'VOTER_ID':
      return voterID;
    case 'ACATS_IN':
      return acatsIn;
    case 'ACATS_OUT':
      return acatsOut;
    case 'TRANSFER_ON_DEATH':
      return transferOnDeath;
    case 'ONLINE_ACCOUNT_APPLICATION':
      return onlineAccountApplication;
    case 'FOREIGN_FINDER_ACKNOWLEDGEMENT':
      return foreignFindersFeeAcknowledgment;
    case 'FOREIGN_ADVISOR':
        return foreignAdvisor;

    default:
      return neverReached(type);
  }
};
