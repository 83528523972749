//
import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

const initialValues = {
  active: false,
  wlpCompany: '',
  contactPhoneMobile: '',
  contactPhoneWork: '',
  countryID: '',
  marginDefault: '',
};

export const initialState = {
  searchQuery: '',
  editingWLP: false,
  wlpEditInitialValues: initialValues,
};

const handlers = {
  FILTER_WLP: (state, action) =>
    produce(state, draftState => {
      draftState.searchQuery = action.payload.searchQuery.toLowerCase();
    }),
  TOGGLE_EDITING_WLP: (state, action) =>
    produce(state, draftState => {
      draftState.editingWLP = !state.editingWLP;
    }),
  SET_INITIAL_VALUES: (state, action) =>
    produce(state, draftState => {
      draftState.wlpEditInitialValues = { ...initialValues, ...action.payload.initialValues };
    }),
};

export const wlpPageReducer = generateReducer(initialState, handlers);
