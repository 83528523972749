/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const orderAdd = {
  displayName: 'Order Add',
  permission: 'ORDER_ADD_CREATE_BATCH_CREATE',
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    accountno: {
      label: 'Account No',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountNo',
    },
    symbol: {
      label: 'Symbol',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'symbol',
    },
    side: {
      label: 'Side',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'side',
    },
    ordertype: {
      label: 'Order Type',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'orderType',
    },
    quantity: {
      label: 'Quantity',
      textAlign: 'right',
      canSort: false,
      required: false,
      dataType: 'number',
      serverKey: 'quantity',
    },
    amountcash: {
      label: 'Cash Amount',
      textAlign: 'right',
      canSort: false,
      required: false,
      dataType: 'money',
      serverKey: 'amountCash',
    },
    commission: {
      label: 'Commission',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'money',
      serverKey: 'commission',
    },
    riaorderoverride: {
      label: 'RIA Override',
      textAlign: 'right',
      canSort: false,
      required: false,
      dataType: 'boolean',
      serverKey: 'riaOrderOverride',
    },
    ordernotes: {
      label: 'Order Notes',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'orderNotes',
    },
  },
  groupPreviewBy: 'accountNo',
  doAccumulatingBalance: false,
  exampleData: [
    {
      accountNo: 'QWERTY12345',
      symbol: 'AAPL',
      side: 'buy',
      orderType: 'market',
      quantity: 100,
      amountCash: 5.25,
      commission: '123455',
      riaOrderOverride: 'true',
      orderNotes: 'some notes',
    },
  ],
};

export { orderAdd };
