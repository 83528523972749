/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const escheatment = {
  displayName: 'Escheatment',
  permission: 'ESCHEATMENT_BATCH_CREATE',
  csvInputs: {
    accountno: {
      label: 'Account No',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountNo',
    },
    escheatmentstatus: {
      label: 'Escheatmentment Status',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'escheatmentStatus',
    },
    escheatmentdate: {
      label: 'Escheatment Date',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'escheatmentDate',
    },
    lastactivitydate: {
      label: 'Last Activity Date',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'lastActivityDate',
    },
    reversaldate: {
      label: 'Reversal Date',
      textAlign: 'right',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'reversalDate',
    },
    reasonnotes: {
      label: 'Reversal Reason',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'reasonNotes',
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      accountNo: 'DWKX007237',
      escheatmentStatus: 'COMPLETED',
      escheatmentDate: '2025-02-25T16:00:02.029Z',
      reasonNotes: 'Test reason notes',
    },
  ],
};

export { escheatment };
