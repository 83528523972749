import { queryOptions } from 'app/hooks';
import { fetchDashboardAcknowledgments } from './services';

export const dashboardAcknowledgmentQueries = {
  all: () =>
    queryOptions({
      queryKey: ['dashboard-acknowledgments'] as const,
      queryFn: () => () => [] as const,
    }),
  lists: () =>
    queryOptions({
      queryKey: [...dashboardAcknowledgmentQueries.all().queryKey, 'list'] as const,
      queryFn: () => () => [] as const,
    }),
  list: () =>
    queryOptions({
      queryKey: [...dashboardAcknowledgmentQueries.lists().queryKey] as const,
      queryFn: fetchDashboardAcknowledgments,
    }),
};
