import { Permissions } from 'src/app/constants/permissions';
import request from './request';

/**
 * @class MoneyMovement
 * @description allow clients to deposit/withdraw money from their DriveWealth account.
 */
export default class MoneyMovement {
  static DIRECTIONS = {
    DEPOSITS: 'deposits',
    WITHDRAWALS: 'redemptions',
    SUBSCRIPTION_TRANS: 'subscriptions',
    VIRTUAL: 'virtual',
  };

  /**
   * Get money movement list based on status
   */
  static async getList(
    direction,
    status,
    type,
    range,
    options,
    parentIbId,
    accountNo,
    minAmount,
    maxAmount,
  ) {
    let endpoint;
    let scope;
    switch (direction) {
      case MoneyMovement.DIRECTIONS.VIRTUAL:
        endpoint =
          status === 'All'
            ? `/funding/deposits/suspense?`
            : `/funding/deposits/suspense?status=${status}`;
        scope = Permissions.DEPOSITS_GET_ALL;
        break;
      case MoneyMovement.DIRECTIONS.SUBSCRIPTION_TRANS:
        endpoint = `/subscriptions/transactions?status=${status.toUpperCase()}`;
        scope = Permissions.SUBSCRIPTIONS_GET;
        break;
      case MoneyMovement.DIRECTIONS.DEPOSITS:
        endpoint = `/funding/deposits?status=${status.toUpperCase()}`;
        scope = Permissions.DEPOSITS_GET_ALL;
        break;
      case MoneyMovement.DIRECTIONS.WITHDRAWALS:
        endpoint = `/funding/redemptions?status=${status.toUpperCase()}`;
        scope = Permissions.REDEMPTIONS_GET_ALL;
        break;
      default:
        endpoint = `/funding/${direction.toLowerCase()}?status=${status.toUpperCase()}`;
        break;
    }

    if (range) {
      const dateRange =
        direction === MoneyMovement.DIRECTIONS.VIRTUAL
          ? `&startDate=${range.startDate}&endDate=${range.endDate}`
          : `&from=${range.startDate}&to=${range.endDate}`;
      endpoint += dateRange;
    }

    if (minAmount && parentIbId) {
      endpoint += `&minAmount=${minAmount.length ? minAmount : 0}`;
    }

    if (maxAmount && parentIbId) {
      endpoint += `&maxAmount=${maxAmount || 10000000}`;
    }

    if (type && type.length) {
      endpoint += `&type=${(typeof type === 'string' ? type : type.join(',')).toUpperCase()}`;
    }

    if (parentIbId && parentIbId.length) {
      endpoint +=
        direction === MoneyMovement.DIRECTIONS.VIRTUAL
          ? `&subPartnerID=${parentIbId}`
          : `&parentIBID=${parentIbId}`;
    }

    if (accountNo) {
      endpoint += `&accountNo=${accountNo}`;
    }

    if (options) {
      for (const option in options) {
        endpoint += `&${option}=${options[option]}`;
      }
    }

    try {
      const data = await request({ endpoint, scope });
      return {
        data: data.body.data || data.body,
        _metadata: data.body._metadata, // only exists for virtual accounts at the moment
      };
    } catch (err) {
      return { data: [], _metadata: {} };
    }
  }

  /**
   * Get details about a deposit/redemption by its ID.
   */
  static get(id, direction) {
    /*
     * types: "deposits" | "redemptions"
     */
    let reqObj;
    switch (direction) {
      case MoneyMovement.DIRECTIONS.SUBSCRIPTION_TRANS:
        reqObj = {
          endpoint: `/subscriptions/transactions/${id}`,
          scope: Permissions.SUBSCRIPTIONS_GET,
        };
        break;
      case MoneyMovement.DIRECTIONS.DEPOSITS:
        reqObj = {
          endpoint: `/funding/deposits/${id}`,
          scope: Permissions.DEPOSITS_GET_BY_ID,
        };
        break;
      case MoneyMovement.DIRECTIONS.WITHDRAWALS:
        reqObj = {
          endpoint: `/funding/redemptions/${id}`,
          scope: Permissions.REDEMPTIONS_GET_BY_ID,
        };
        break;
      default:
        reqObj = {
          endpoint: `/funding/${direction.toLowerCase()}/${id}`,
        };
    }

    return request({
      ...reqObj,
    }).then(data => data.body);
  }

  /**
   * Edit details about a deposit/redemption by its ID.
   */
  static edit(id, details, direction) {
    let endpoint;
    let scope;

    switch (direction) {
      case MoneyMovement.DIRECTIONS.SUBSCRIPTION_TRANS:
        endpoint = `/subscriptions/transactions/${id}`;
        scope = Permissions.SUBSCRIPTIONS_EDIT;
        break;
      case MoneyMovement.DIRECTIONS.DEPOSITS:
        endpoint = `/funding/deposits/${id}`;
        scope = [
          Permissions.DEPOSITS_EDIT,
          Permissions.DEPOSITS_UPDATE_STATUS_RIA_ONLY,
          Permissions.DEPOSITS_UPDATE_STATUS_PENDING,
          Permissions.DEPOSITS_UPDATE_STATUS,
          Permissions.DEPOSITS_EDIT_AMOUNT,
          Permissions.MONEY_MOVEMENT_MOVE_TO_APPROVED,
          Permissions.MONEY_MOVEMENT_MOVE_TO_REJECTED,
        ];
        break;
      case MoneyMovement.DIRECTIONS.WITHDRAWALS:
        endpoint = `/funding/redemptions/${id}`;
        scope = [
          Permissions.REDEMPTIONS_UPDATE,
          Permissions.REDEMPTIONS_UPDATE_STATUS,
          Permissions.MONEY_MOVEMENT_MOVE_TO_APPROVED,
          Permissions.MONEY_MOVEMENT_MOVE_TO_REJECTED,
        ];
        break;
      default:
        endpoint = `/funding/${direction.toLowerCase()}/${id}`;
        break;
    }

    return request({
      method: 'PATCH',
      endpoint,
      scope,
      body: JSON.stringify(details),
    }).then(res => {
      return res;
    });
  }

  /**
   * Get data about deposits/redemptions
   */
  static downloadCSV(direction, status, type) {
    let endpoint;
    let scope;

    switch (direction) {
      case MoneyMovement.DIRECTIONS.SUBSCRIPTION_TRANS:
        endpoint = `/subscriptions/transactions?status=${status}`;
        scope = Permissions.SUBSCRIPTIONS_GET;
        break;
      case MoneyMovement.DIRECTIONS.DEPOSITS:
        endpoint = `/funding/deposits/${type}/download?status=${status}`;
        scope = Permissions.DEPOSITS_GET_ALL;
        break;
      case MoneyMovement.DIRECTIONS.WITHDRAWALS:
        endpoint = `/funding/redemptions/${type}/download?status=${status}`;
        scope = Permissions.REDEMPTIONS_GET_ALL;
        break;
      default:
        endpoint = `/funding/${direction.toLowerCase()}/${type}/download?status=${status}`;
        break;
    }

    return request({
      endpoint,
      scope,
      addlHeaders: {
        Accept: 'text/csv',
      },
    }).then(data => data.body);
  }

  static getRecurringDepositsForUser(userID) {
    const endpoint = `/users/${userID}/recurring-deposits`;
    return request({
      endpoint,
      scope: Permissions.DEPOSITS_GET_ALL,
    }).then(data => data.body);
  }

  static getDepositHistory(depositID) {
    const endpoint = `/funding/recurring-deposits/${depositID}`;
    return request({
      endpoint,
      scope: Permissions.DEPOSITS_GET_BY_ID,
    }).then(data => data.body);
  }

  static getPaymentHistoryDeposit(paymentID) {
    const endpoint = `/funding/deposits/${paymentID}`;
    return request({
      endpoint,
      scope: Permissions.DEPOSITS_GET_BY_ID,
    }).then(data => data.body);
  }

  static getPaymentHistoryWithdrawal(paymentID) {
    const endpoint = `/funding/redemptions/${paymentID}`;
    return request({
      endpoint,
      scope: Permissions.REDEMPTIONS_GET_BY_ID,
    }).then(data => data.body);
  }

  static updateRecurringDeposit(depositID, body) {
    const endpoint = `/funding/recurring-deposits/${depositID}`;
    return request({
      method: 'PATCH',
      endpoint,
      body: JSON.stringify(body),
      scope: Permissions.DEPOSITS_EDIT_BY_ID,
    }).then(data => data.body);
  }

  static deleteRecurringDeposit(depositID) {
    const endpoint = `/funding/recurring-deposits/${depositID}`;
    return request({
      method: 'DELETE',
      endpoint,
      scope: Permissions.DEPOSITS_DELETE_BY_ID,
    }).then(data => data);
  }
}
