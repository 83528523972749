import { add } from 'date-fns';
import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

import { shapeActions, upcomingSymbols } from './manipulations';

export const initialState = {
  byID: {},
  upcomingBySymbol: {},
  search: {
    symbols: [],
    startDate: new Date(),
    endDate: add(new Date(), { days: 1 }),
    results: [],
  },
};

const handlers = {
  GET_ACTIONS_SUCCESS: (state, action) =>
    produce(state, draft => {
      const shapedActions = shapeActions(action.payload.actions);
      draft.byID = { ...draft.byID, ...shapedActions.byID };
      draft.search.results = shapedActions.ids;
      draft.search.startDate = action.payload.startDate;
      draft.search.endDate = action.payload.endDate;
      draft.search.symbols = action.payload.symbols;
    }),
  GET_UPCOMING_ACTIONS_SUCCESS: (state, action) =>
    produce(state, draft => {
      const shapedActions = shapeActions(action.payload.actions);
      draft.byID = { ...draft.byID, ...shapedActions.byID };
      draft.upcomingBySymbol = upcomingSymbols(action.payload.actions);
    }),
  ACTIONS_GET_ERROR: null,
  ACTIONS_GET_START: null,
  UPCOMING_ACTIONS_GET_START: null,
  UPCOMING_ACTIONS_GET_ERROR: null,
};

export default generateReducer(initialState, handlers);
