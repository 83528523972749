import { Auth0Provider } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { EnvironmentVariable } from 'app/types';
import { PropsWithChildren } from 'react';
import { environmentVariableQueries } from '../queries/environmentVariables';

const selectAuth0Variables = (data: EnvironmentVariable[]) => {
  return data
    .filter(d => ['AUTH0_DOMAIN', 'AUTH0_CLIENT_ID'].includes(d.environmentID))
    .reduce(
      (prev, current) => ({
        ...prev,
        [current.environmentID]: current.value,
      }),
      { AUTH0_DOMAIN: undefined, AUTH0_CLIENT_ID: undefined } as {
        AUTH0_DOMAIN?: string;
        AUTH0_CLIENT_ID?: string;
      },
    );
};

/**
 * Provider component that wraps the application with Auth0 authentication.
 * Fetches required Auth0 configuration variables and initializes the Auth0Provider.
 *
 * @component
 * @param props - React children components to be wrapped with authentication
 * @returns Auth0Provider wrapper component or null if configuration is missing
 *
 * @example
 * ```tsx
 * <AuthenticationProvider>
 *   <App />
 * </AuthenticationProvider>
 * ```
 *
 * @remarks
 * The component fetches AUTH0_CLIENT_ID and AUTH0_DOMAIN from environment variables.
 * If either variable is missing, it returns null to prevent initialization with invalid config.
 * Uses window.location.origin as the redirect URI after authentication.
 */
export const AuthenticationProvider = ({ children }: PropsWithChildren) => {
  const { data } = useQuery({
    ...environmentVariableQueries.list(),
    select: selectAuth0Variables,
  });

  const { AUTH0_CLIENT_ID, AUTH0_DOMAIN } = data ?? {};

  if (!AUTH0_CLIENT_ID || !AUTH0_DOMAIN) {
    return null;
  }

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'https://dashboard.drivewealth.com/api',
      }}
    >
      {children}
    </Auth0Provider>
  );
};
