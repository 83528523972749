import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  wlpList: [],
  wlpDetails: {},
};

const handlers = {
  // Get wlp list actions
  GET_WLP_LIST_START: null,
  GET_WLP_LIST_ERROR: null,
  GET_WLP_LIST_SUCCESS: (state, action) =>
    produce(state, draftState => {
      draftState.wlpList = action.payload.wlpList;
    }),

  // Get single wlp details actions
  GET_WLP_START: null,
  GET_WLP_ERROR: null,
  GET_WLP_SUCCESS: (state, action) =>
    produce(state, draftState => {
      draftState.wlpDetails[action.payload.wlpID] = action.payload.wlpDetails;
    }),

  // Update wlp actions
  UPDATE_WLP_START: null,
  UPDATE_WLP_ERROR: null,
  UPDATE_WLP_SUCCESS: null,

  // Create wlp actions
  CREATE_WLP_START: null,
  CREATE_WLP_ERROR: null,
  CREATE_WLP_SUCCESS: null,
};

export default generateReducer(initialState, handlers);
