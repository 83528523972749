import { useQuery } from '@tanstack/react-query';

import { EnvironmentVariable, UseQueryOptions } from 'app/types';

export const useGetEnvironmentVariables = (options?: UseQueryOptions) => {
  return useQuery({
    queryKey: ['environments'],
    queryFn: async (): Promise<EnvironmentVariable[]> => {
      const endpoint = new URL(`/api/environments`, window.location.origin).href;

      const res = await fetch(endpoint);

      const environments = await res.json();
      return environments;
    },
    ...options,
  });
};
