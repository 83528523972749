//
import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  showDeleteModal: false,
  initialValues: {
    subscriptionID: '',
  },
};

const handlers = {
  TOGGLE_DELETE_SUBSCRIPTION_MODAL: (state, action) =>
    produce(state, draftState => {
      draftState.showDeleteModal = !state.showDeleteModal;
      draftState.initialValues.subscriptionID = action.payload.subscriptionID
        ? action.payload.subscriptionID
        : '';
    }),
};

export const subscriptionsPageReducer = generateReducer(initialState, handlers);
