import { Auth0QueryFn } from 'app/hooks';
import { handleResponseNotOk } from 'app/services';
import { z } from 'zod';

export const BankAccountSchema = z.object({
  id: z.string(),
  type: z.string(),
  status: z.string(),
  default: z.boolean(),
  created: z.string(),
  updated: z.string(),
  bankAccountDetails: z.object({
    bankAccountNickname: z.string(),
    bankAccountNumber: z.string(),
    bankRoutingNumber: z.string(),
    bankName: z.string().optional(),
  }),
});

export type BankAccount = z.infer<typeof BankAccountSchema>;

export const BankAccountsAPIReturnValueSchema = z.array(BankAccountSchema);

export type BankAccountsAPIReturnValue = z.infer<typeof BankAccountsAPIReturnValueSchema>;

/**
 * Fetches user's bank accounts.
 *
 * @param {string} userId - The unique identifier of the user.
 * @returns {Auth0QueryFn<BankAccountsAPIReturnValue>} A query function that resolves to bank accounts object.
 */
export const fetchBankAccounts = (userId: string): Auth0QueryFn<BankAccountsAPIReturnValue> =>
  fetch =>
  async ({ signal }) => {
    const endpoint = new URL(`/api/users/${userId}/bank-accounts`, window.location.origin).href;

    const response = await fetch(endpoint, { signal });
    if (!response.ok) {
      handleResponseNotOk(response);
    }
    
    const bankAccountsJson = await response.json();
    return BankAccountsAPIReturnValueSchema.parse(bankAccountsJson);
  };
