import { Auth0QueryFnWithFilters } from 'app/hooks';
import { SearchResults } from 'app/types';

export const fetchSearch: Auth0QueryFnWithFilters<string, SearchResults> = (searchTerm) => 
  (fetch) =>
  async ({ signal }) => {
    const endpoint = new URL(`/api/search`, window.location.origin);
    endpoint.searchParams.append('searchTerm', searchTerm);

    const data = await fetch(endpoint, { signal });
    return data.json();
  };