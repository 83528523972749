import { AppBar, Box, Drawer, List, Toolbar } from '@mui/material';
import { PropsWithChildren, useContext, useMemo } from 'react';
import { IfPermissioned } from 'src/app/components/IfPermissioned';
import { Permissions } from 'src/app/constants/permissions';
import { useAuth0Query } from 'src/app/hooks';
import styled from 'styled-components';

import { defaultFeatureToggle } from 'app/constants';
import { featureTogglesQueries } from '../queries';
import { GlobalNavbarDrawerContext } from './DrawerOpenProvider';
import { Header } from './Header';
import { Help } from './Help';
import { NavSearch } from './NavSearch';
import { PrimaryNav } from './PrimaryNav';
import { getRoutes } from './routes';
import { Settings } from './Settings';
import { User } from './User';

const Main = styled(Box)<{ $isOpen: boolean }>`
  margin-left: calc(
    ${props => (props.$isOpen ? props.theme.spacing(30) : props.theme.spacing(7))} + 1px
  );
  transition: ${props =>
    props.theme.transitions.create('margin', {
      easing: props.theme.transitions.easing.easeOut,
      duration: props.theme.transitions.duration.enteringScreen,
    })};

  // Move the main content down below the navbar based on built-in Material-UI responsive behaviors
  margin-top: 56px;
  height: calc(100% - 56px);

  @media (min-width: 0px) and (orientation: landscape) {
    margin-top: 48px;
    height: calc(100% - 48px);
  }

  @media (min-width: 600px) {
    margin-top: 64px;
    height: calc(100% - 64px);
  }
`;

const StyledAppBar = styled(AppBar)<{ $isOpen: boolean }>`
  background-color: ${props => props.theme.dw.colors.corePrimary1};
`;

const StyledDrawer = styled(Drawer)<{ $isOpen: boolean }>`
  .MuiPaper-root {
    // The navbar needs to be under the top navbar, but not drawers in general
    z-index: ${props => props.theme.zIndex.appBar - 1};
    width: calc(
      ${props => (props.$isOpen ? props.theme.spacing(30) : props.theme.spacing(7))} + 1px
    );
    overflow-x: hidden;
    transition: ${props =>
      props.$isOpen
        ? props.theme.transitions.create(['width'], {
            easing: props.theme.transitions.easing.sharp,
            duration: props.theme.transitions.duration.enteringScreen,
          })
        : props.theme.transitions.create(['width'], {
            easing: props.theme.transitions.easing.sharp,
            duration: props.theme.transitions.duration.leavingScreen,
          })};
  }
`;

const DrawerBox = styled(Box)`
  overflow-x: hidden;
  overflow-y: auto;
`;

const DrawerBackground = styled(Box)`
  background-color: ${props => props.theme.dw.colors.corePrimaryBase};
  overflow: hidden;
`;

const TopNavBar = styled(Box)`
  margin-left: ${props => props.theme.spacing(2)};
  justify-content: flex-end;
  flex-grow: 1;
  & > *:not(:last-child) {
    margin-right: ${props => props.theme.spacing(1.5)};
  }
`;

export const Navbar = (props: PropsWithChildren<{}>): JSX.Element => {
  const { children } = props;
  const { isDrawerOpen, toggleDrawer } = useContext(GlobalNavbarDrawerContext);
  const { data: featureToggles = defaultFeatureToggle } = useAuth0Query(
    featureTogglesQueries.list(),
  );

  const routes = useMemo(() => getRoutes(featureToggles), [featureToggles]);

  return (
    <>
      <StyledAppBar position="fixed" $isOpen={isDrawerOpen}>
        <Toolbar>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            width="1"
            justifyContent="space-between"
          >
            <Box flexShrink={0}>
              <Header onClick={toggleDrawer} isOpen={isDrawerOpen} />
            </Box>
            <TopNavBar display="flex" flexDirection="row" alignSelf="flex-end">
              <IfPermissioned permissions={Permissions.SEARCH_BAR_VIEW}>
                <NavSearch />
              </IfPermissioned>
              <Help />
              <User />
            </TopNavBar>
          </Box>
        </Toolbar>
      </StyledAppBar>
      <DrawerBackground height={1}>
        <StyledDrawer variant="permanent" $isOpen={isDrawerOpen}>
          <Toolbar />
          <DrawerBox flexShrink={1}>
            <List>
              {routes.map(config => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <PrimaryNav key={config.text} {...config} />
              ))}
            </List>
          </DrawerBox>
          <Settings />
        </StyledDrawer>
        <Main height={1} $isOpen={isDrawerOpen}>
          {children}
        </Main>
      </DrawerBackground>
    </>
  );
};
