import { queryOptions } from 'app/hooks';
import { fetchWhoAmI } from './services/employees';

export const employeeQueries = {
  all: () => queryOptions({ queryKey: ['employees'] as const, queryFn: () => () => [] as const }),
  lists: () =>
    queryOptions({
      queryKey: [...employeeQueries.all().queryKey, 'list'] as const,
      queryFn: () => () => [] as const,
    }),
  // This is a weird endpoint. It's on /employees but returns data completely different
  whoAmI: () =>
    queryOptions({
      queryKey: [...employeeQueries.all().queryKey, 'me'] as const,
      queryFn: fetchWhoAmI,
    }),
};
