import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  GraphQLClient,
  RawRequestOptions,
  RequestDocument,
  RequestOptions,
  Variables,
} from 'graphql-request';
import { Response, VariablesAndRequestHeadersArgs } from 'graphql-request/build/esm/types';
import { RequestInit } from 'graphql-request/build/esm/types.dom';
import { store } from 'src/store';

const generalEndpoint = new URL('/api/sec-lending/general/graphql', window.location.origin).href;
const reportingEndpoint = new URL('/api/sec-lending/reporting/graphql', window.location.origin)
  .href;
const storageEndpoint = new URL('/api/sec-lending/storage/graphql', window.location.origin).href;

type RawRequestMethodArgs<V extends Variables> =
  | [query: string, variables?: V, requestHeaders?: RequestInit['headers']]
  | [RawRequestOptions<V>];

/**
 * This class extends the base GraphQLClient to always check (and update if needed) the current Cognito session,
 * update headers, and finally send out the appropriate requests.
 */
export class DWGraphQLClient extends GraphQLClient {
  async setCurrentAuthHeaders(): Promise<void> {
    // TODO: Resolve this with Auth0

    // const session = await Promise.reject();
    // const accessToken = session.getAccessToken().getJwtToken();
    // const idToken = session.getIdToken().getJwtToken();
    const dataSrc = store.getState()?.settings?.ENV;

    this.setHeaders({
      authorization: `Bearer ${accessToken}`,
      'z-auth-token': idToken,
      'z-datasrc': dataSrc,
    });
  }

  rawRequest = async <T = never, V extends Variables = Variables>(
    ...args: RawRequestMethodArgs<V>
  ): Promise<Response<T>> => {
    await this.setCurrentAuthHeaders();

    const [query, variables, requestHeaders] = args;

    return super.rawRequest<T, V>(query as string, variables, requestHeaders);
  };

  async request<T, V extends Variables = Variables>(
    document: RequestDocument | TypedDocumentNode<T, V>,
    ...variablesAndRequestHeaders: VariablesAndRequestHeadersArgs<V>
  ): Promise<T>;
  async request<T, V extends Variables = Variables>(options: RequestOptions<V, T>): Promise<T>;
  async request<T, V extends Variables = Variables>(
    documentOrOptions: RequestDocument | TypedDocumentNode<T, V> | RequestOptions<V>,
    ...variablesAndRequestHeaders: VariablesAndRequestHeadersArgs<V>
  ): Promise<T> {
    await this.setCurrentAuthHeaders();

    // Tired of dealing with this. We're just overriding the base class to add in this auth header bit
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return super.request<T, V>(documentOrOptions, ...variablesAndRequestHeaders);
  }
}

export const generalSecLendingGraphQLClient = new DWGraphQLClient(generalEndpoint);
export const reportingSecLendingGraphQLClient = new DWGraphQLClient(reportingEndpoint);
export const storageSecLendingGraphQLClient = new DWGraphQLClient(storageEndpoint);
