import { Permissions } from 'app/constants';
import { queryOptions } from 'app/hooks';
import { CancelRebillFilter } from 'app/types';
import { fetchCancelRebills } from './services/fetchCancelRebills';

export const cancelRebillQueries = { 
  all: () =>
    queryOptions({
      queryKey: ['asset-transfer', 'cancel-rebills'] as const,
      queryFn: () => () => [] as const,
    }),
  lists: () =>
    queryOptions({
      queryKey: [...cancelRebillQueries.all().queryKey, 'list'] as const,
      queryFn: () => () => [] as const,
    }),
  list: (filters: CancelRebillFilter[]) =>
    queryOptions({
      queryKey: [...cancelRebillQueries.lists().queryKey, filters] as const,
      queryFn: fetchCancelRebills(filters),
      scope: Permissions.CANCEL_REBILL_READ,
    }),
};