import { get } from 'lodash';
import { setTimeoutP } from 'src/app/utils';

import { DriveWealthError } from './Error';
import request from './request';

/**
 * @module
 * @example import { Order } from "services";
 */
export default class Order {
  /**
   * @constant
   * @property {string} MARKET
   * @property {string} LIMIT
   * @property {string} STOP
   */
  static TYPES = {
    MARKET: 'MARKET',
    LIMIT: 'LIMIT',
    STOP: 'STOP',
  };

  /**
   * @constant
   * @property {string} BUY
   * @property {string} SELL
   */
  static SIDES = {
    BUY: 'BUY',
    SELL: 'SELL',
  };

  /**
   * Create a manual entry order for a particular account.
   */
  static async create(account, orderData) {
    const data = await request({
      method: 'POST',
      endpoint: '/orders',
      body: {
        accountNo: account.accountNo,
        ...orderData,
      },
    });
    const order = data.body;

    let checkOrderRetriesLeft = 10;
    let orderDetails;
    while (!orderDetails) {
      const foundOrder = await this.getByOrderID(order.orderID);
      const isRejected = foundOrder.status === 'REJECTED';
      const isFilled = foundOrder.status === 'FILLED' || order.status === 'PARTIAL_FILL';
      const notMarket = foundOrder.type !== 'MARKET';

      if (isRejected) {
        let errorCode = '-1';
        let errorMessage = 'Unknown rejection reason';

        if ('statusMessage' in foundOrder) {
          errorCode = get(foundOrder, 'statusMessage.errorCode');
          errorMessage = get(foundOrder, 'statusMessage.message');
        }

        throw new DriveWealthError(
          errorMessage,
          data.body,
          data.statusCode,
          data.headers,
          errorCode,
        );
      }

      if (isFilled || notMarket) {
        orderDetails = {
          ...order,
          ...foundOrder,
        };
        delete orderDetails.orderId;
        break;
      }

      checkOrderRetriesLeft--;
      if (checkOrderRetriesLeft === 0) {
        throw new DriveWealthError(
          `No details found for Order No: ${order.orderNo}`,
          data.body,
          data.statusCode,
          data.headers,
          '-1',
        );
      }

      await setTimeoutP(1000);
    }

    return orderDetails;
  }

  /**
   * Get order by Order Number
   */
  static getByOrderNo(orderNo) {
    return request({
      endpoint: `/orders/byOrderNo/${orderNo}`,
    }).then(data => data.body);
  }

  /**
   * Get order by Order ID
   */
  static getByOrderID(orderID) {
    return request({
      endpoint: `/orders/${orderID}`,
    }).then(data => data.body);
  }
}
