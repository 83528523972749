import { queryOptions } from '@tanstack/react-query';
import { fetchEnvironmentVariables } from './services';

export const environmentVariableQueries = {
  all: () =>
    queryOptions({
      queryKey: ['environments'] as const,
      queryFn: () => [] as const,
    }),
  lists: () =>
    queryOptions({
      queryKey: [...environmentVariableQueries.all().queryKey, 'list'] as const,
      queryFn: () => [] as const,
    }),
  list: () =>
    queryOptions({
      queryKey: [...environmentVariableQueries.lists().queryKey] as const,
      queryFn: fetchEnvironmentVariables,
    }),
};
