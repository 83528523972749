import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

import { shapeUserObj } from './manipulations';

export const initialState = {
  byID: {},
};

const handlers = {
  SUCCESSFUL_USERS_SEARCH: (state, action) =>
    produce(state, draft => {
      draft.byID = { ...shapeUserObj(action.payload.results), ...draft.byID };
    }),
  GET_USER_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.byID[action.payload.user.id] = {
        ...draft.byID[action.payload.user.id],
        ...action.payload.user,
      };
    }),
  INCREMENT_USER_NOTE_QTY: (state, action) => {
    const { userId } = action.payload;

    return produce(state, draft => {
      draft.byID[userId].userNoteQty += 1;
    });
  },
  USER_GET_ERROR: null,
  USER_GET_START: null,
};

export default generateReducer(initialState, handlers);
