import { isArray } from 'lodash';

import { Permission } from '../constants/permissions';
import { useWhoAmI } from './useWhoAmI';

/**
 * Utility hook to verify if the current user possesses the specified permissions.
 *
 * @param {Permission | null | Array<Permission | null>} permissions - A single permission or an array of permissions to validate against the user's granted permissions.
 * @returns {boolean} - Returns true if the user has any of the specified permissions, otherwise false.
 */
export const usePermission = (
  permissions: Permission | null | Array<Permission | null>,
): boolean => {
  const { data, isSuccess } = useWhoAmI();

  // Return true if permissions is null
  if (permissions === null) {
    return true;
  }

  // Loading, will catch up quickly and re-render. We don't want to kick the user out irreversibly while loading.
  if (!isSuccess) {
    return true;
  }

  let permissionsArray = permissions;
  if (!isArray(permissions)) {
    permissionsArray = [permissions];
  }

  // Un-permissioned item
  if (permissionsArray?.length === 0) {
    return true;
  }

  const grantedPermissions = data?.permissions;

  // Throw an error if grantedPermissions is not an array
  if (!isArray(grantedPermissions)) {
    throw new Error('Invalid permissions format');
  }

  return grantedPermissions.some(permission => permissionsArray?.includes(permission));
};
