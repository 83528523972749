import { Auth0QueryFn } from 'app/hooks';
import { Holiday } from 'app/types';

const endpoint = new URL(`/api/calendars`, window.location.origin).href;

/**
 * Fetches the list of holidays.
 *
 * @param {typeof global.fetch} fetch - The fetch function to use for the request.
 * @returns {Promise<Holiday[]>} A promise that resolves to an array of holidays.
 */
export const fetchHolidays: Auth0QueryFn<Holiday[]> =
  fetch =>
  async ({ signal }) => {
    const data = await fetch(endpoint, { signal });
    const json = await data.json();

    return json;
  };
