import * as Sentry from '@sentry/react';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = (initialState = {}) => {
  const middleware = [thunk];

  const enhancers = [sentryReduxEnhancer];
  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );
  store.asyncReducers = {};

  return store;
};

export default store;
