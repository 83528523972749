import { Auth0QueryFnWithFilters } from 'app/hooks';
import { handleResponseNotOk } from 'app/services';
import { CancelRebill, CancelRebillFilter } from 'app/types';
import { formatDateOnly, neverReached, parseYYYYMMDD } from 'app/utils';
import { addDays } from 'date-fns';

/**
 * Fetches the list of cancel rebills.
 *
 * @param {typeof global.fetch} fetch - The fetch function to use for the request.
 * @returns {Promise<CancelRebill[]>} A promise that resolves to an array of cancel rebills.
 */

export const fetchCancelRebills: Auth0QueryFnWithFilters<CancelRebillFilter[], CancelRebill[]> =
  filters =>
  (fetch: typeof global.fetch) =>
  async ({ signal }: { signal: AbortSignal | null | undefined }) => {

    const url = new URL('/api/asset-transfers/cancel-rebills', window.location.href);

    filters.forEach((filter: CancelRebillFilter) => {
      switch (filter.field) {
        case 'status':
          if (filter.value.length > 0) {
            url.searchParams.append('status', filter.value as string);
          }
          break;
  
        case 'partner':
          url.searchParams.append('partnerID', filter.value as string);
          break;
  
        case 'created':
          const toDate = parseYYYYMMDD(filter.value[1] as string);
          const nextDay = formatDateOnly(addDays(toDate, 1));
          url.searchParams.append('from', filter.value[0] as string);
          url.searchParams.append('to', nextDay);
          break;
  
        case 'type':
          url.searchParams.append('type', filter.value as string);
          break;
  
        // Fall through expected and we shouldn't filter on this
        case 'amount':
        case 'accountNo':
        case 'date':
        case 'id':
        case 'orderNo':
          break;
  
        default:
          return neverReached(filter.field);
      }
      if (!filters.some(el => el.id === 'status')) {
        url.searchParams.append('status', 'PENDING');
      }
    });

    const data = await fetch(url.href, { signal });
    
    if (!data.ok) {
      handleResponseNotOk(data);
    }

    return data.json();
  };
