import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  byAccountNumber: {},
};

const handlers = {
  START_GET_ACCOUNT_INFO: null,
  ERROR_GET_ACCOUNT_INFO: null,
  CLEAR_BATCH_PREVIEW: (state, action) =>
    produce(state, draft => {
      draft.byAccountNumber = {};
    }),
  GET_ACCOUNT_INFO_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.byAccountNumber[action.payload.accountInfo.accountNo] = action.payload.accountInfo;
    }),
};

export default generateReducer(initialState, handlers);
