import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

import { shapeListByUserid, shapeMap, shapeOrdersMap } from './manipulations';

export const initialState = {
  productsMap: {},
  productsIDs: {},
  allocationsMap: {},
  allocationIDs: {},
  ordersMap: {},
  ordersIDs: {},
  fundsMap: {},
  fundsIDs: {},
  portfoliosMap: {},
  portfoliosIDs: {},
  autopilotSettings: {},
};

const handlers = {
  PRODUCTS_GET_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.productsMap = shapeMap(action.payload.products);
      draft.productsIDs = shapeListByUserid(action.payload.products);
    }),
  PRODUCTS_EDIT_END: (state, action) =>
    produce(state, draft => {
      draft.productsMap[action.payload.product.id] = action.payload.product;
      if (!draft.productsIDs[action.payload.userID].includes(action.payload.product.id)) {
        draft.productsIDs[action.payload.userID].push(action.payload.product.id);
      }
    }),
  ADVISORS_ALLOCATIONS_GET_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.allocationsMap = shapeMap(action.payload.allocations);
      draft.allocationIDs = shapeListByUserid(action.payload.allocations, action.payload.userID);
    }),
  ORDERS_GET_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.ordersMap = shapeOrdersMap(action.payload.orders);
      draft.ordersIDs = shapeListByUserid(action.payload.orders, action.payload.userID);
    }),
  ALL_ORDERS_GET_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.ordersMap = shapeOrdersMap(action.payload.orders);
      draft.ordersIDs = shapeListByUserid(action.payload.orders, action.payload.userID);
    }),
  FUNDS_GET_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.fundsMap = shapeMap(action.payload.funds);
      draft.fundsIDs = shapeListByUserid(action.payload.funds);
    }),
  GET_SINGLE_FUND_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.fundsMap[action.payload.fund.id] = action.payload.fund;
    }),
  UPDATE_SINGLE_FUND_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.fundsMap[action.payload.fund.id] = action.payload.fund;
    }),
  FINISH_GET_PORTFOLIOS: (state, action) =>
    produce(state, draft => {
      draft.portfoliosMap = { ...shapeMap(action.payload.portfolios), ...state.portfoliosMap };
      draft.portfoliosIDs = shapeListByUserid(action.payload.portfolios, action.payload.userID);
    }),
  GET_SINGLE_PORTFOLIO_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.portfoliosMap[action.payload.portfolio.id] = action.payload.portfolio;
    }),
  SINGLE_ALLOCATION_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.allocationsMap[action.payload.allocation.id] = action.payload.allocation;
    }),
  PRODUCTS_GET_START: null,
  PRODUCTS_GET_ERROR: null,
  PRODUCTS_EDIT_START: null,
  PRODUCT_EDIT_ERROR: null,
  ADVISORS_ALLOCATIONS_GET_ERROR: null,
  ADVISOR_ALLOCATIONS_GET_START: null,
  ADVISORS_ORDERS_GET_START: null,
  ORDERS_GET_ERROR: null,
  FUNDS_GET_ERROR: null,
  FUNDS_GET_START: null,
  GET_SINGLE_FUND_ERROR: null,
  GET_SINGLE_FUND_START: null,
  SINGLE_ALLOCATION_START: null,
  ALL_ORDERS_ERROR: null,
  ALL_ALLOCATIONS_GET_ERROR: null,
  START_GET_PORTFOLIOS: null,
  ERROR_GET_PORTFOLIOS: null,
  GET_SINGLE_PORTFOLIO_START: null,
  GET_SINGLE_PORTFOLIO_ERROR: null,
  GET_AUTOPILOT_SETTINGS_START: null,
  GET_AUTOPILOT_SETTINGS_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.autopilotSettings[action.payload.advisorID] = action.payload.settings;
    }),
  GET_AUTOPILOT_SETTINGS_ERROR: null,
};

export default generateReducer(initialState, handlers);
