import { useMutation, useQueryClient } from '@tanstack/react-query';

import { KycStatusAPIResponse } from 'app/components/queries/services/fetchUserKycStatus';
import { fetch } from '../../services';
import { KycStatusResponse } from '../../types';
import { transformKycStatus } from './utils';

type KycStatusRequest = {
  kycDoc: boolean;
  kycNonDoc: boolean;
  documentIDs?: object | null;
  userID: string;
  watchList: boolean;
};

const endpoint = new URL('/api/kyc/verification', window.location.origin).href;

export const useVerification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: KycStatusRequest): Promise<KycStatusResponse> => {
      const res = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(variables),
        headers: {
          'Content-type': 'application/json',
        },
      });

      const data = (await res.json()) as KycStatusAPIResponse;

      return transformKycStatus(data);
    },

    onSuccess: (data, variables) => {
      if (!data) return;
      queryClient.setQueryData(['users', variables.userID, 'kyc-status'], data);
    },
  });
};
