import { STATUS_CHANGE_REASON } from 'src/legacy/common/constants/batch';
/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const accountClose = {
  displayName: 'Account Close',
  permission: 'ACCOUNTS_CLOSE_CREATE_BATCH',
  batchAs: 'ACCOUNT_EDIT',
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    accountno: {
      label: 'Account No',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountNo',
    },
    accountstatus: {
      label: 'Account Status',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountStatus',
    },
    nickname: {
      label: 'Account Nickname',
      textAlign: 'right',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'nickname',
    },
    commissionid: {
      label: 'CommissionID',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'commissionID',
    },
    tradingtype: {
      label: 'Trading Type',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'tradingType',
    },
    leverage: {
      label: 'Leverage',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'number',
      serverKey: 'leverage',
    },
    bodcashavailablefortrading: {
      label: 'BOD Buying Power',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'money',
      serverKey: 'bodCashAvailableForTrading',
    },
    bodcashavailableforwithdrawal: {
      label: 'BOD Cash Available For Withdrawal',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'money',
      serverKey: 'bodCashAvailableForWithdrawal',
    },
    bodcashbalance: {
      label: 'BOD Cash Balance',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'money',
      serverKey: 'bodCashBalance',
    },
    interestfree: {
      label: 'Interest Free',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'boolean',
      serverKey: 'interestFree',
    },
    restricted: {
      label: 'Restrict Account',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'boolean',
      serverKey: 'restricted',
    },
    sweepind: {
      label: 'Sweep Account Into MM',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'boolean',
      serverKey: 'sweepInd',
    },
    gfvpdtexempt: {
      label: 'GFV/PDT Exempt',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'boolean',
      serverKey: 'gfvPdtExempt',
    },
    buyingpoweroverride: {
      label: 'Allowed To Go Negative',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'boolean',
      serverKey: 'buyingPowerOverride',
    },
    specialorderenabled: {
      label: 'Allowed To Trade Special Order Types',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'boolean',
      serverKey: 'specialOrderEnabled',
    },
    statuschangereason: {
      label: 'Status Change Reason',
      textAlign: 'center',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'statusChangeReason',
    },
    statuscomment: {
      label: 'Status Comment',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusComment',
    },
  },
  checks: {
    columns: {
      statusChangeReason: {
        includes: STATUS_CHANGE_REASON,
      },
      accountStatus: {
        value: 'CLOSED',
      },
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      accountNo: 'DWZR000123',
      accountStatus: 'CLOSED',
      nickname: 'Test',
      commissionID: '316a1154-00bf-4743-b944-e87bc35df8a2',
      tradingType: 'MARGIN',
      leverage: 1,
      bodCashAvailableForTrading: 100.45,
      bodCashAvailableForWithdrawal: 98.45,
      bodCashBalance: 100.45,
      interestFree: 'false',
      restricted: 'false',
      sweepInd: 'true',
      gfvPdtExempt: 'false',
      buyingPowerOverride: 'false',
      specialOrderEnabled: 'false',
      statusChangeReason: 'DUP_ACCOUNT',
      statusComment: 'Duplicate account',
    },
  ],
};

export { accountClose };
