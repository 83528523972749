import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

import { shapeCommissions, shapelistsByID } from './manipulations';

export const initialState = {
  byID: {},
  listsByID: {},
};

const handlers = {
  GET_COMMISSIONS_SUCCESS: (state, action) =>
    produce(state, draft => {
      const shapedCommissions = shapeCommissions(action.payload.commissions);
      draft.byID = shapedCommissions;
      draft.listsByID = shapelistsByID(action.payload.commissions);
    }),
  COMMISSIONS_GET_START: null,
  COMMISSIONS_GET_ERROR: null,
};

export default generateReducer(initialState, handlers);
