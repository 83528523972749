import { noop } from 'lodash';
import { createContext, FC, ReactNode } from 'react';

import { useToggle } from '../../hooks';

export const GlobalNavbarDrawerContext = createContext<{
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  setDrawerOpen: (val: boolean) => void;
}>({
  isDrawerOpen: false,
  toggleDrawer: noop,
  setDrawerOpen: noop,
});

interface Props {
  children: ReactNode;
}

export const GlobalNavbarDrawerContextProvider: FC<Props> = ({ children }) => {
  const [isDrawerOpen, toggleDrawer, setDrawerOpen] = useToggle(false);

  return (
    <GlobalNavbarDrawerContext.Provider value={{ isDrawerOpen, toggleDrawer, setDrawerOpen }}>
      {children}
    </GlobalNavbarDrawerContext.Provider>
  );
};
