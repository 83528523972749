import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { fetch } from 'app/services';
import { UseQueryOptions } from 'app/types';

export const WLPListSchema = z.object({
  company: z.string(),
  wlpID: z.string(),
  active: z.boolean(),
});

export type WLPList = z.infer<typeof WLPListSchema>;

export const WLPAPIReturnValueSchema = z.array(WLPListSchema);

export type WLPAPIReturnValue = z.infer<typeof WLPAPIReturnValueSchema>;

const endpoint = new URL('/api/wlp', window.location.origin).href;

export const useGetWLPs = (options?: UseQueryOptions) => {
  return useQuery({
    queryKey: ['wlp'],
    queryFn: async ({ signal }): Promise<WLPAPIReturnValue> => {
      const wlps = await fetch(endpoint, { signal });

      const wlpsJson = await wlps.json();

      return WLPAPIReturnValueSchema.parse(wlpsJson);
    },

    ...options,
  });
};
