import { Redirect, RouteProps } from 'react-router-dom';
import { Permission } from 'src/app/constants';
import { Environments, usePermission } from 'src/app/hooks';

import { SentryRoute } from './SentryRoute';

export interface PermissionedRouteProps extends RouteProps {
  permissions: Permission | Permission[];
  /** @deprecated No more multi-env support */
  environments?: Environments[];
}

export const PermissionedRoute = ({
  component: Component,
  permissions,
  render,
  ...rest
}: PermissionedRouteProps): JSX.Element | null => {
  const hasPermission = usePermission(permissions);

  // This component accepts two ways of routing, through a Component that is passed some props,
  // or a function which can handle some additional logic
  if (Component === undefined && render === undefined) {
    return null;
  }

  return (
    <SentryRoute
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={props => {
        if (hasPermission) {
          if (Component) {
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <Component {...props} />;
          }

          if (render) {
            return render(props);
          }
        }

        return <Redirect to={{ pathname: '/unauthorized', state: { from: props.location } }} />;
      }}
    />
  );
};