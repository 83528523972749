import { Auth0QueryFn } from 'app/hooks';
import { handleResponseNotOk } from 'app/services';
import { ReconciliationDetail } from 'app/types';

const endpoint = new URL('/api/funding/reconciliations/', window.location.origin).href;

/**
 * Fetches reconciliation details.
 *
 * @param {string} id - The unique identifier of the reconciliation report.
 * @returns {Auth0QueryFn<ReconciliationDetail>} A query function that resolves to reconciliation details object.
 */
export const fetchReconciliationDetails = (id: string): Auth0QueryFn<ReconciliationDetail> =>
  fetch =>
  async ({ signal }) => {
    const response = await fetch(`${endpoint}${id}`, { signal });
    if (!response.ok) {
      handleResponseNotOk(response);
    }
    return response.json();
  };
