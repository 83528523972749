/*
  displayName - batch name to be used for labels etc.
  permission - permissions needed to create this batch type
  csvInputs - has all the columns used for the Preview and the Details
  csvInputs.COLUMNNAME - the column name MUST be all lowercase
  csvInputs.COLUMNNAME.label - label for the column
  csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
  csvInputs.COLUMNNAME.canSort - should this column be sortable
  csvInputs.COLUMNNAME.required - block upload if this column is missing
  csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
  csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
  groupPreviewBy - either a empty string or the name of the column by which to group all the rows
  exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const shareTransfer = {
  displayName: 'Share Transfer',
  permission: 'SHARE_TRANSFER_CREATE_BATCH_CREATE',
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    accountfrom: {
      label: 'Account From',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountFrom',
    },
    accountto: {
      label: 'Account To',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountTo',
    },
    symbol: {
      label: 'Symbol',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'symbol',
    },
    averageprice: {
      label: 'Average Price',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'price',
      serverKey: 'averagePrice',
    },
    quantity: {
      label: 'Quantity',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'number',
      serverKey: 'quantity',
    },
    transferprice: {
      label: 'Transfer Price',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'price',
      serverKey: 'transferPrice',
    },
    executionstrategy: {
      label: 'Accounting Method',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'executionStrategy',
    },
    comment: {
      label: 'Comment',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'comment',
    },
    dnb: {
      label: 'Do Not Batch',
      textAlign: 'center',
      canSort: false,
      required: true,
      dataType: 'boolean',
      serverKey: 'dnb',
    },
    donotreconcile: {
      label: 'Do Not Reconcile',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'boolean',
      serverKey: 'doNotReconcile',
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      accountFrom: 'QWERTYY12345',
      accountTo: 'BIRDY12345',
      symbol: 'AAPL',
      averagePrice: 100,
      quantity: 10,
      transferPrice: 50,
      executionStrategy: 'LIFO',
      comment: 'Some comment',
      dnb: 'true',
      doNotReconcile: 'false',
    },
  ],
};

export { shareTransfer };
