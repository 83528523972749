import { queryOptions, useQuery, UseQueryResult } from '@tanstack/react-query';

import { fetch } from 'app/services';
import { UseQueryOptions, User, UserType } from 'app/types';

export type UserSearchResult = Pick<
  User,
  'approvedBy' | 'approvedWhen' | 'countryID' | 'firstName' | 'lastName' | 'parentIB' | 'username'
> & {
  company: string;
  emailAddress: string;
  userID: string;
  phone: string;
};

export type UserSearchReturnValue = {
  count: number;
  error?: {
    errorCode: string;
    message: string;
  };
  resultSet: UserSearchResult[];
};

type UseUserSearchOptions = {
  company?: string;
  userType?: UserType;
};

const endpoint = new URL('/api/users/search', window.location.origin);

export const userSearchQuery = (params: UseUserSearchOptions) =>
  queryOptions({
    queryKey: ['users', 'search', params],
    queryFn: async (): Promise<UserSearchReturnValue> => {
      Object.entries(params).forEach(([key, value]) => {
        endpoint.searchParams.append(key, value);
      });

      const data = await fetch(endpoint.href);

      return data.json();
    },
  });

export const useUserSearch = (
  params: UseUserSearchOptions,
  options?: UseQueryOptions,
): UseQueryResult => useQuery({ ...userSearchQuery(params), ...options });
