import { Auth0QueryFn } from 'app/hooks';
import { Instrument } from 'app/types';

const endpoint = new URL('/api/instruments', window.location.origin).href;

/**
 * Fetches the list of instruments.
 *
 * @param {typeof global.fetch} fetch - The fetch function to use for the request.
 * @returns {Promise<Instrument[]>} A promise that resolves to an array of instruments.
 */
export const fetchInstruments: Auth0QueryFn<Instrument[]> =
  fetch =>
  async ({ signal }) => {
    const data = await fetch(endpoint, { signal });
    return data.json();
  };
