import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import {
  DefaultError,
  MutationFunction,
  QueryClient,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { useMemo } from 'react';

import { isUndefined } from 'lodash';
import { Permission } from '../../constants/permissions';
import { generateFetch } from '../../services/http';
import { getAuthorizationParamsWithScope } from './utils';

/**
 * Function type for Auth0-authenticated mutation operations
 * @template TData - Type of data returned by the mutation
 * @template TVariables - Type of variables accepted by mutation
 */
export type Auth0MutationFn<TData = unknown, TVariables = unknown> = (
  fetch: typeof global.fetch,
) => MutationFunction<TData, TVariables>;

/**
 * Configuration options for Auth0 mutation hook
 * @template TData - Type of data returned by the mutation
 * @template TError - Type of error that may occur
 * @template TVariables - Type of variables accepted by mutation
 * @template TContext - Type of context data for the mutation
 */
export type UseAuth0MutationOptions<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
> = Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'> & {
  mutationFn?: Auth0MutationFn<TData, TVariables>;
  permission?: Permission | Permission[];
  auth0Options?: GetTokenSilentlyOptions;
};

export type UseAuth0MutationOptionsOverrides<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
> = Partial<UseAuth0MutationOptions<TData, TError, TVariables, TContext>>;

/**
 * Hook that provides authenticated mutations with Auth0
 *
 * This hook wraps React Query's useMutation with Auth0 authentication handling.
 * It automatically manages token fetching and injection for API calls.
 *
 * @template TData - Type of data returned by the mutation
 * @template TError - Type of error that may occur
 * @template TVariables - Type of variables accepted by mutation
 * @template TContext - Type of context data for the mutation
 *
 * @param options - Configuration for the mutation
 * @param options.auth0Options - Optional Auth0-specific settings
 * @param options.permission - Required permission for the API call
 * @param options.mutationFn - Mutation function to execute
 * @param queryClient - Optional React Query client instance
 *
 * @returns {@link UseMutationResult} object containing mutation state and controls
 *
 * @example
 * ```typescript
 * const mutation = useAuth0Mutation({
 *   permission: Permissions.READ_DATA,
 *   mutationFn: (fetch) => async (variables) => {
 *     const response = await fetch('/api/data', {
 *       method: 'POST',
 *       body: JSON.stringify(variables)
 *     });
 *     return response.json();
 *   }
 * });
 * ```
 */
export const useAuth0Mutation = <
  TData = unknown,
  TError = Error,
  TVariables = void,
  TContext = unknown,
>(
  options: UseAuth0MutationOptions<TData, TError, TVariables, TContext>,
  queryClient?: QueryClient,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const { auth0Options, permission, ...rest } = options ?? {};
  const { getAccessTokenSilently } = useAuth0();

  const initialMutationFn = rest?.mutationFn;

  // Do the thing here
  const mutationFn = useMemo(() => {
    if (isUndefined(initialMutationFn)) {
      return undefined;
    }

    const wrappedFetch = generateFetch({
      getAccessToken: () => {
        if (auth0Options) {
          return getAccessTokenSilently(auth0Options);
        }

        return getAccessTokenSilently(getAuthorizationParamsWithScope(permission));
      },
    });

    return initialMutationFn(wrappedFetch);
  }, [auth0Options, getAccessTokenSilently, initialMutationFn, permission]);

  return useMutation({ ...rest, mutationFn }, queryClient);
};
