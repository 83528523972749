import { MenuItem } from '@drivehub/ui-components';
import { Avatar, Box, Typography } from '@mui/material';
import styled from 'styled-components';

import { useAuth0 } from '@auth0/auth0-react';
import { copyText } from '../../internationalization';
import { TopNavBarDropdownMenu } from './TopNavBarDropdownMenu';

const StyledAvatar = styled(Avatar)`
  margin-right: ${props => props.theme.spacing(1)};
  height: 32px;
  width: 32px;
  font-size: ${props => props.theme.typography.body2.fontSize}px;
  background-color: ${props => props.theme.dw.colors.white};
  color: ${props => props.theme.dw.colors.corePrimary1};
`;

export const User = (): JSX.Element => {
  const { user, logout } = useAuth0();
  // fallback to nickname if preferred_username is undefined
  const userName = user?.preferred_username ?? user?.nickname;
  const companyName = 'TODO: GET PARTNER';

  const {
    navbar: {
      avatar: { logout: logoutCopyText },
    },
  } = copyText;

  // Possibly naive to split this way? Unsure how to initialize non <FIRST_NAME LAST_NAME> names
  const initials = `${user?.given_name}${user?.family_name}`;

  const menuItems = [
    <MenuItem
      key="log-out"
      onClick={() => logout({ logoutParams: { returnTo: `${window.location.origin}/logout` } })}
    >
      {logoutCopyText}
    </MenuItem>,
  ];

  const userNameWithCompany = `${userName}, ${companyName}`;

  return (
    <TopNavBarDropdownMenu menuItems={menuItems} testId="user-menu">
      <StyledAvatar>{initials}</StyledAvatar>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1">{userNameWithCompany}</Typography>
      </Box>
    </TopNavBarDropdownMenu>
  );
};
