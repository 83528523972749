import { FeatureToggle } from 'app/constants';
import { Auth0QueryFn } from 'app/hooks';
import { handleResponseNotOk } from 'app/services';

const url = new URL('/api/feature-toggles', window.location.origin);

export const fetchFeatureToggles: Auth0QueryFn<FeatureToggle> =
  fetch =>
  async ({ signal }) => {
    const response = await fetch(url.href, { signal });
    if (!response.ok) {
      handleResponseNotOk(response);
    }
    return response.json();
  };