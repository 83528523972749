import { defaultFeatureToggle } from 'app/constants';
import { queryOptions } from 'app/hooks';
import { fetchFeatureToggles } from './services';

export const featureTogglesQueries = {
  all: () =>
    queryOptions({
      queryKey: ['feature-toggles'] as const,
      queryFn: () => () => [] as const,
    }),
  lists: () =>
    queryOptions({
      queryKey: [...featureTogglesQueries.all().queryKey, 'list'] as const,
      queryFn: () => () => [] as const,
    }),
  list: () =>
    queryOptions({
      queryKey: [...featureTogglesQueries.lists().queryKey] as const,
      queryFn: fetchFeatureToggles,
      initialData: defaultFeatureToggle,
    }),
};
