import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { fetch } from 'app/services';
import { UseQueryOptions } from 'app/types';

export const WLPDetailSchema = z.object({
  company: z.string(),
  wlpID: z.string(),
  active: z.boolean(),
  clearingCode: z.string(),
  contactPhoneMobile: z.string(),
  contactPhoneWork: z.string(),
  countryID: z.string(),
  currencies: z.array(z.string()),
  commissionGroupID: z.string(),
  contraSubAccount: z.string(),
  instrumentGroupID: z.string().nullable(),
  inteliclearReports: z.boolean(),
  languageID: z.string(),
  marginDefault: z.number(),
  marginMin: z.number(),
  prefix: z.string(),
  prefixPractice: z.string(),
  defaultIb: z.string(),
});

export type WLP = z.infer<typeof WLPDetailSchema>;

export const useGetWLP = (wlpID: string, options?: UseQueryOptions) => {
  return useQuery({
    queryKey: ['wlp', wlpID],
    queryFn: async ({ signal }): Promise<WLP> => {
      const endpoint = new URL(`/api/wlp/${wlpID}`, window.location.origin).href;
      const wlp = await fetch(endpoint, { signal });

      const wlpJson = await wlp.json();

      return WLPDetailSchema.parse(wlpJson);
    },
    ...options,
  });
};
