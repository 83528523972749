import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import {
  DefaultError,
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useMemo } from 'react';

import { isUndefined } from 'lodash';
import { Permission } from '../../constants/permissions';
import { generateFetch } from '../../services/http';
import { getAuthorizationParamsWithScope } from './index';

export type Auth0QueryFn<
  TQueryFnData = unknown,
  _TError = DefaultError,
  _TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = (fetch: typeof global.fetch) => QueryFunction<TQueryFnData, TQueryKey, never>;

export type Auth0QueryFnWithFilters<
  T = unknown,
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = (filters: T) => Auth0QueryFn<TQueryFnData, TError, TData, TQueryKey>;

export type UseAuth0QueryOptions<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = never,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryFn'> & {
  queryFn?:
    | Exclude<
        UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>['queryFn'],
        QueryFunction<TQueryFnData, TQueryKey, TPageParam>
      >
    | Auth0QueryFn<TQueryFnData, TError, TData, TQueryKey>;
  scope?: Permission;
  auth0Options?: GetTokenSilentlyOptions;
};

export const useAuth0Query = <
  TQueryFnData = unknown,
  TError = Error,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseAuth0QueryOptions<TQueryFnData, TError, TData, TQueryKey>,
) => {
  const { auth0Options, scope, ...rest } = options ?? {};
  const { getAccessTokenSilently } = useAuth0();

  const initialQueryFn = rest?.queryFn;

  // Do the thing here
  const queryFn = useMemo(() => {
    if (isUndefined(initialQueryFn)) {
      return undefined;
    }

    // skipToken
    if (typeof initialQueryFn !== 'function') return initialQueryFn;

    const wrappedFetch = generateFetch({
      getAccessToken: getAccessToken({ scope, getAccessTokenSilently, auth0Options }),
    });

    return initialQueryFn(wrappedFetch);
  }, [auth0Options, getAccessTokenSilently, initialQueryFn, scope]);

  return useQuery({ ...rest, queryFn });
};

export const getAccessToken =
  ({
    auth0Options,
    getAccessTokenSilently,
    scope,
  }: {
    scope?: Permission;
    auth0Options?: GetTokenSilentlyOptions;
    getAccessTokenSilently: ReturnType<typeof useAuth0>['getAccessTokenSilently'];
  }) =>
  () => {
    if (auth0Options) {
      return getAccessTokenSilently(auth0Options);
    }

    return getAccessTokenSilently(getAuthorizationParamsWithScope(scope));
  };
