import { AllBatchPermissions } from 'src/app/constants';

/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const promotionCashTransfer = {
  displayName: 'Promotion Cash Transfer',
  permission: AllBatchPermissions.PROMOTION_CASH_TRANSFER_CREATE_BATCH,
  overrides: {
    dnb: false,
  },
  checks: {
    timeCheck: {
      permissionBypass: AllBatchPermissions.PROMOTION_CASH_TRANSFER_BYPASS_TIME_CHECK_CREATE,
      tz: 'America/New_York',
      start: {
        hours: 8,
        minutes: 0,
      },
      end: {
        hours: 13,
        minutes: 30,
      },
    },
    columns: {
      accountFrom: {
        accountType: {
          value: 'CASH_PROMOTION_ACCOUNT',
          permissionBypass:
          AllBatchPermissions.PROMOTION_CASH_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_CREATE,
        },
        hasTotalFunds: {
          sumColumn: 'cash',
        },
      },
      accountTo: {
        accountStatus: {
          value: 'OPEN',
        },
      },
      cash: {
        transactionLimit: {
          permissionBypass:
          AllBatchPermissions.PROMOTION_CASH_TRANSFER_BYPASS_TRANSACTION_LIMIT_CHECK_CREATE,
          value: 15000, // this is in USD cents
        },
        positiveOnly: true,
      },
    },
  },
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    accountfrom: {
      label: 'Account From',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountFrom',
    },
    accountto: {
      label: 'Account To',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountTo',
    },
    cash: {
      label: 'Cash',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'money',
      serverKey: 'cash',
    },
    comment: {
      label: 'Comment',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'comment',
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      accountFrom: 'QWERTY12345',
      accountTo: 'BIRDY123456',
      cash: 5,
      comment: 'Some comment',
    },
  ],
};

export { promotionCashTransfer };
