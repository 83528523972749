export enum AllBatchPermissions {
  ACCOUNTS_EDIT_CREATE_BATCH = 'create:accounts-edit-batch',
  ACCOUNTS_CLOSE_CREATE_BATCH = 'create:accounts-close-batch',
  CASH_TRANSFER_CREATE_BATCH_CREATE = 'create:cash-transfer-batch',
  ORDER_ADD_CREATE_BATCH_CREATE = 'create:order-add-batch',
  ORDER_CANCEL_CREATE_BATCH_CREATE = 'create:cancel-and-correct-batch',
  SHARE_TRANSFER_CREATE_BATCH_CREATE = 'create:share-transfer-batch',
  PROMOTION_CASH_TRANSFER_CREATE_BATCH = 'create:promotion-cash-transfer-batch',
  PROMOTION_CASH_TRANSFER_BYPASS_TIME_CHECK_CREATE = 'create:promotion-cash-transfer-bypass-time-check',
  PROMOTION_CASH_TRANSFER_BYPASS_TRANSACTION_LIMIT_CHECK_CREATE = 'create:promotion-cash-transfer-bypass-transaction-limit-check',
  PROMOTION_CASH_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_CREATE = 'create:promotion-cash-transfer-bypass-promotion-account-purpose',
  PROMOTION_SHARE_TRANSFER_CREATE_BATCH = 'create:promotion-share-transfer-batch',
  PROMOTION_SHARE_TRANSFER_BYPASS_TIME_CHECK = 'create:promotion-share-transfer-bypass-time-check',
  PROMOTION_SHARE_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE = 'create:promotion-share-transfer-bypass-promotion-account-purpose',
  PRESIGNED_S3_LINK_GET = 'create:presigned-s3-link',
  CANCEL_REBILL_CREATE_BATCH = 'create:cancel-rebill-batch',
  CANCEL_REBILL_CREATE_BATCH_VIEW = 'read:cancel-rebill-batch',
  RECON_ACCOUNT_EXCLUSION_CREATE_BATCH = 'create:recon-account-exclusion-batch',
  DEPOSITS_CREATE_BATCH_CREATE = 'create:deposits-batch',
  EXECUTED_AWAY_ORDER_CREATE_BATCH = 'create:executed-away-order-batch',
  FINTRANS_CREATE_BATCH = 'create:fintrans-batch',
  INSTRUMENTS_ADD_CREATE_BATCH = 'create:instruments-add-batch',
  INSTRUMENTS_EDIT_CREATE_BATCH = 'create:instruments-edit-batch',
  IRA_CONTRIBUTION_TYPE_UPDATE_BATCH_CREATE = 'create:ira-contribution-type-update-batch',
  LOCK_UNLOCK_CREATE_BATCH = 'create:lock-unlock-batch',
  LOCK_UNLOCK_CREATE_BATCH_BYPASS = 'create:lock-unlock-batch-bypass',
  ORDER_CANCEL_CREATE_BATCH = 'create:cancel-create-batch',
  ORDER_STATUS_CREATE_BATCH = 'create:order-status-batch',
  POSITION_ADJUST_CREATE_BATCH = 'create:position-adjust-batch',
  ESCHEATMENT_BATCH_CREATE = 'create:escheatment-batch',
}

export enum AllViewBatchPermissions {
  FINTRANS_CREATE_VIEW = 'read:fintrans-batch',
  ORDER_ADD_CREATE_BATCH_VIEW = 'read:order-add-batch',
  ORDER_CANCEL_CREATE_BATCH_VIEW = 'read:cancel-create-batch',
  SHARE_TRANSFER_CREATE_BATCH_VIEW = 'read:share-transfer-batch',
  CASH_TRANSFER_CREATE_BATCH_VIEW = 'read:cash-transfer-batch',
  POSITION_ADJUST_CREATE_BATCH_VIEW = 'read:position-adjust-batches',
  EXECUTED_AWAY_ORDER_CREATE_BATCH_VIEW = 'read:executed-away-order-batch',
  ACCOUNTS_EDIT_CREATE_BATCH_VIEW = 'read:accounts-edit-batch',
  ACCOUNTS_CLOSE_CREATE_BATCH_VIEW = 'create:accounts-close-batch',
  INSTRUMENTS_EDIT_CREATE_BATCH_VIEW = 'read:instruments-edit-batch',
  RECON_ACCOUNT_EXCLUSION_CREATE_BATCH = 'read:recon-account-exclusion-batch',
  INSTRUMENTS_ADD_CREATE_BATCH_VIEW = 'read:instruments-add-batch',
  DEPOSITS_CREATE_BATCH_VIEW = 'read:deposits-batch',
  ORDER_STATUS_CREATE_BATCH_VIEW = 'read:order-status-batch',
  PROMOTION_CASH_TRANSFER_CREATE_BATCH_VIEW = 'read:promotion-cash-transfer-batch',
  PROMOTION_CASH_TRANSFER_BYPASS_TIME_CHECK_VIEW = 'read:promotion-cash-transfer-time-check-bypass',
  PROMOTION_CASH_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_VIEW = 'read:promotion-cash-transfer-account-purpose',
  PROMOTION_CASH_TRANSFER_BYPASS_TRANSACTION_LIMIT_CHECK_VIEW = 'read:promotion-cash-transfer-transaction-limit-check-bypass',
  PROMOTION_SHARE_TRANSFER_CREATE_BATCH_VIEW = 'read:promotion-share-transfer-batch',
  PROMOTION_SHARE_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_VIEW = 'read:promotion-share-transfer-bypass-promotion-account-purpose',
  PROMOTION_SHARE_TRANSFER_BYPASS_TIME_CHECK_VIEW = 'read:promotion-share-transfer-bypass-time-check',
  BATCH_USER_DROPDOWN_VIEW = 'read:batch-user-dropdown',
  LOCK_UNLOCK_VIEW = 'LOCK_UNLOCK_VIEW',
  ESCHEATMENT_BATCH_VIEW = 'read:escheatment-batch',
}

export enum RestPermissions {
  ACCOUNTS_CREATE_LIVE = 'create:live-accounts',
  ACCOUNTS_CREATE_MANAGED = 'create:managed-accounts',
  ACCOUNTS_CREATE_MARGIN = 'create:margin-accounts',
  ACCOUNTS_CREATE_PRACTICE = 'create:practice-accounts',
  ACCOUNTS_CREATE_RIA_MANAGED = 'create:ria-managed-account',
  ACCOUNTS_CREATE_HSA_SELF = 'create:hsa-self-accounts',
  ACCOUNTS_CREATE_HSA_ADVISORY = 'create:hsa-advisory-account',
  ACCOUNTS_CREATE_HSA_RIA_MANAGED = 'create:hsa-ria-managed-account',
  ACCOUNTS_CREATE_GFV_PDT_EXEMPT = 'create:accounts-gfv-pdt-exempt',
  ACCOUNTS_CREATE_IGNORE_MARKET_HOURS = 'create:accounts-ignore-market-hours',
  ACCOUNTS_CREATE_INSTANT_MONEY_MOVEMENT = 'create:accounts-instant-money-movement',
  ACCOUNTS_EDIT = 'edit:accounts',
  ACCOUNTS_EDIT_BOD = 'edit:account-bod',
  ACCOUNTS_EDIT_LEVERAGE = 'edit:account-leverage',
  BANK_ACCOUNTS_GET = 'read:bank-accounts',
  BANK_ACCOUNTS_DELETE = 'delete:bank-account',
  BO_PARTNERS_VIEW = 'read:partner-profiles',
  BO_PASSWORD_UPDATE = 'BO_PASSWORD_UPDATE',
  COMMISSIONS_EDIT = 'edit:accounts-commission',
  COMMISSIONS_VIEW = 'read:commissions',
  COUNTRIES_VIEW = 'read:countries',
  DASHBOARD_VIEW = 'DASHBOARD_VIEW',
  DASHBOARD_PARTNER_DROPDOWN_VIEW = 'DASHBOARD_PARTNER_DROPDOWN_VIEW',
  DEPOSITS_GET_ALL = 'read:all-deposits',
  DOCUMENTS_STATUS_EDIT = 'DOCUMENTS_STATUS_EDIT',
  DOCUMENTS_GET = 'DOCUMENTS_GET',
  DOCUMENTS_DELETE = 'DOCUMENTS_DELETE',
  EMPLOYEES_CREATE = 'EMPLOYEES_CREATE',
  EMPLOYEES_GET = 'EMPLOYEES_GET',
  EMPLOYEES_EDIT = 'EMPLOYEES_EDIT',
  EMPLOYEES_DELETE = 'EMPLOYEES_DELETE',
  INSTRUMENTS_CREATE = 'INSTRUMENTS_CREATE',
  INSTRUMENTS_EDIT = 'INSTRUMENTS_EDIT',
  INSTRUMENTS_EDIT_OPTIONS = 'INSTRUMENTS_EDIT_OPTIONS',
  INSTRUMENTS_EDIT_LEVERAGE = 'INSTRUMENTS_EDIT_LEVERAGE',
  INSTRUMENT_LISTING_VIEW = 'INSTRUMENT_LISTING_VIEW',
  INSTRUMENT_GROUP_CREATE = 'create:instrument-group',
  INSTRUMENT_GROUP_EDIT = 'edit:instrument-group',
  INSTRUMENT_GROUP_READ = 'read:instrument-group',
  INSTRUMENTS_ISIN_VIEW = 'INSTRUMENTS_ISIN_VIEW',
  INSTRUMENTS_VIEW = 'read:instruments',
  CALENDARS_VIEW = 'read:calendar',
  VIRTUAL_ACCOUNTS_VIEW = 'read:all-virtual-deposits',
  VIRTUAL_ACCOUNTS_EDIT = 'edit:virtual-accounts',
  BULK_SETTLEMENTS_VIEW = 'read:net-settlements',
  BULK_SETTLEMENTS_EDIT = 'edit:net-settlements',
  DEPOSITS_EDIT = 'edit:deposits',
  DEPOSITS_CREATE = 'create:deposits',
  DEPOSITS_UPDATE_STATUS_RIA_ONLY = 'edit:deposit-status-ria-only',
  DEPOSITS_UPDATE_STATUS_PENDING = 'update:deposits-status-pending',
  DEPOSITS_UPDATE_STATUS = 'edit:deposit-status',
  DEPOSITS_EDIT_AMOUNT = 'edit:deposit-amount',
  WLP_FINTRAN_TYPES_VIEW = 'read:wlp-fintran-types',
  WLP_FINTRAN_TYPES_EDIT = 'edit:wlp-fintran-types',
  WLP_FINTRAN_TYPES_CREATE = 'create:wlp-fintran-types',
  PARTNER_PROFILE_VIEW = 'read:partner-profile-details',
  USER_SUBSCRIPTIONS_VIEW = 'read:user-subscriptions',
  USER_SUBSCRIPTIONS_DELETE = 'delete:user-subscriptions',
  TRANSFER_CREATE = 'create:transfer',
  INSTRUMENTS_ISIN_EDIT = 'INSTRUMENTS_ISIN_EDIT',
  INSTRUMENTS_CUSIP_VIEW = 'INSTRUMENTS_CUSIP_VIEW',
  INSTRUMENTS_CUSIP_EDIT = 'INSTRUMENTS_CUSIP_EDIT',
  INSTRUMENTS_SEDOL_VIEW = 'INSTRUMENTS_SEDOL_VIEW',
  INSTRUMENTS_SEDOL_EDIT = 'INSTRUMENTS_SEDOL_EDIT',
  INSTRUMENTS_RIC_VIEW = 'INSTRUMENTS_RIC_VIEW',
  INSTRUMENTS_RIC_EDIT = 'INSTRUMENTS_RIC_EDIT',
  INVESTING_PROFILE_INFO_HIDE = 'INVESTING_PROFILE_INFO_HIDE',
  KYC_STATUS_UPDATE = 'KYC_STATUS_UPDATE',
  MANAGED_CREATE_ORDER_OVERRIDE = 'read:managed-create-order-override',
  MANAGED_GET_ALLOCATIONS_INTERNAL = 'MANAGED_GET_ALLOCATIONS_INTERNAL',
  MARGIN_CALL_ALERT_GET = 'read:margin-call-alert',
  REDEMPTIONS_UPDATE = 'edit:redemption',
  REDEMPTIONS_UPDATE_STATUS = 'edit:redemption-status',
  // Any user that wants to use any of the two following permissions
  // must also have REDEMPTIONS_UPDATE and REDEMPTIONS_UPDATE_STATUS
  // in order to update a redemption.
  // To update deposits they must only have the DEPOSITS_UPDATE_STATUS permission.
  MONEY_MOVEMENT_MOVE_TO_APPROVED = 'edit:money-movement-move-to-approved',
  MONEY_MOVEMENT_MOVE_TO_REJECTED = 'edit:money-movement-move-to-rejected',
  SINGLE_SETTLEMENT_VIEW = 'SINGLE_SETTLEMENT_VIEW',
  SINGLE_SETTLEMENT_EDIT = 'SINGLE_SETTLEMENT_EDIT',
  NOTES_CREATE = 'create:notes',
  NOTES_GET = 'read:notes',
  ONBOARD_PARTNER = 'ONBOARD_PARTNER',
  ORDERS_CREATE = 'create:orders',
  ORDERS_CREATE_CUSTOM_COMMISSION = 'create:orders-custom-commission',
  ORDERS_CANCEL = 'edit:orders-cancel',
  ORDERS_UPDATE = 'edit:orders',
  ORDERS_PHONE_CREATE = 'create:orders-phone',
  PARTNER_CREATE = 'PARTNER_CREATE',
  PARTNER_SETTING_VIEW = 'PARTNER_SETTING_VIEW',
  PARTNER_SETTING_EDIT = 'PARTNER_SETTING_EDIT',
  PARTNER_EDIT = 'PARTNER_EDIT',
  PARTNER_GET = 'PARTNER_GET',
  PARTNER_SETTINGS_GET = 'PARTNER_SETTINGS_GET',
  PURPOSE_ACCOUNT_CREATE = 'PURPOSE_ACCOUNT_CREATE',
  RECONCILIATIONS_GET_ALL = 'RECONCILIATIONS_GET_ALL',
  RECONCILIATIONS_EDIT = 'edit:reconciliations',
  RECONCILIATIONS_VIEW = 'read:reconciliations',
  REDEMPTIONS_GET_ALL = 'read:all-redemptions',
  REPORTS_GET = 'read:reports',
  REPORTS_GET_BESTEX = 'REPORTS_GET_BESTEX',
  REPORTS_GET_TPLUSONE = 'REPORTS_GET_TPLUSONE',
  REPORTS_GET_INTELLICLEAR = 'REPORTS_GET_INTELLICLEAR',
  REPORTS_GET_INTELLICLEAR_DOWNLOAD = 'REPORTS_GET_INTELLICLEAR_DOWNLOAD',
  REPORTS_GET_RESTRICTED_ACCOUNTS = 'REPORTS_GET_RESTRICTED_ACCOUNTS',
  REPORTS_GET_PDT = 'REPORTS_GET_PDT',
  REPORTS_GET_871M = 'REPORTS_GET_871M',
  END_OF_DAY_TRADING_REPORT_GET = 'END_OF_DAY_TRADING_REPORT_GET',
  SUBSCRIPTIONS_GET = 'read:subscription-transactions',
  SUBSCRIPTIONS_EDIT = 'edit:subscriptions-transaction',
  USERS_CREATE_ADVISOR = 'USERS_CREATE_ADVISOR',
  USERS_EDIT = 'edit:users',
  USERS_EDIT_IB = 'edit:user-partner-ib',
  USERS_EDIT_KYC_STATUS = 'USERS_EDIT_KYC_STATUS',
  USERS_VIEW_KYC_STATUS = 'read:kyc-status',
  USERS_EDIT_PII = 'USERS_EDIT_PII',
  USERS_EDIT_PRINCIPAL_STATUS = 'edit:user-principal-status',
  USERS_PENDING_COUNTS_READ = 'read:user-pending-counts',
  USERS_READ = 'read:user',
  USER_NEXT_PENDING_APPROVAL_GET = 'read:user-next-pending-approval',
  USERS_REVIEW_CREATE = 'create:users-review',
  USER_ACKNOWLEDGMENTS_READ = 'read:user-acknowledgments',
  VIEW_PARTNER_DROPDOWN = 'VIEW_PARTNER_DROPDOWN',
  MANAGE_TEAM_VIEW = 'MANAGE_TEAM_VIEW',
  MANAGE_TEAM_EDIT = 'MANAGE_TEAM_EDIT',
  MANAGE_TEAM_GET = 'MANAGE_TEAM_GET',
  MANAGED_CREATE_ADVISOR_AUTOPILOT = 'create:users-advisor',
  TAX_CENTER_UTIL_LINK_VIEW = 'TAX_CENTER_UTIL_LINK_VIEW',
  VIEW_RECENT_BATCHES = 'read:recent-batches',
  VIEW_RIA_ONLY = 'VIEW_RIA_ONLY',
  VIEW_VERSION = 'VIEW_VERSION',
  VIRTUAL_ACCOUNT_AMOUNT_INPUT_VIEW = 'VIRTUAL_ACCOUNT_AMOUNT_INPUT_VIEW',
  WLP_GET = 'read:wlp',
  SEARCH_BAR_VIEW = 'read:search-bar',
  WLP_CREATE = 'create:wlp',
  WLP_EDIT = 'edit:wlp',
  DEPOSITS_GET_BY_ID = 'read:deposits-by-id',
  DEPOSITS_EDIT_BY_ID = 'edit:deposits-by-id',
  DEPOSITS_DELETE_BY_ID = 'delete:deposits-by-id',
  REDEMPTIONS_GET_BY_ID = 'read:redemption-by-id',
  EQUITIES_VIEW = 'read:equities',
  MUTUAL_FUND_VIEW = 'MUTUAL_FUND_VIEW',
  FIXED_INCOME_VIEW = 'FIXED_INCOME_VIEW',
  EQUITIES_WRITE = 'create:equities',
  ACCOUNTS_EDIT_TRADING_FUNC = 'edit:accounts-trading-functions',
  ACCOUNTS_LOCK_UNLOCK_POSITIONS = 'edit:account-positions-lock-unlock',
  MANAGED_GET_PORTFOLIO_STATUS = 'read:account-portfolio',
  FINTRANS_GET = 'read:fintrans',
  FINTRANS_CREATE = 'create:fintrans',
  FINTRANS_DO_NOT_RECONCILE = 'edit:fintrans',
  ACCOUNTS_BENEFICIARIES_GET = 'read:account-beneficiaries',
  ACCOUNTS_BENEFICIARIES_ADD = 'create:account-beneficiaries',
  ACCOUNTS_BENEFICIARIES_DELETE = 'delete:account-beneficiaries',
  ACCOUNTS_TRUSTED_CONTACT_GET = 'read:trusted-contact-account',
  ACCOUNTS_TRUSTED_CONTACT_ADD = 'create:accounts-trusted-contacts',
  FPSL_VIEW = 'FPSL_VIEW',
  RULE_14B_EDIT = 'RULE_14B_EDIT',
  DOCUMENTS_CREATE = 'DOCUMENTS_CREATE',
  MANAGE_EXCLUSIONS_CREATE = 'create:manage-exclusions',
  MANAGE_EXCLUSIONS_DELETE = 'delete:manage-exclusions',
  MANAGE_EXCLUSIONS_EDIT = 'edit:manage-exclusions',
  MANAGE_EXCLUSIONS_READ = 'read:manage-exclusions',
  EXCHANGE_CREATE = 'create:exchanges',
  EXCHANGE_EDIT = 'edit:exchanges',
  EXCHANGE_READ = 'read:exchanges',
  TAX_PROFILE_WRITE = 'create:tax-profile',
  DEVELOPER_TOOLS_VIEW = 'DEVELOPER_TOOLS_VIEW',
  OVERRIDE_PDT_RESTRICTIONS = 'edit:pdt-restriction-override',
  CANCEL_REBILL_READ = 'read:cancel-rebill',
  CANCEL_REBILL_CREATE = 'create:cancel-rebill',
  CANCEL_REBILL_EDIT = 'edit:cancel-rebill-request',
  VIEW_OPTIONS_SUITABILITY = 'VIEW_OPTIONS_SUITABILITY',
  EDIT_OPTIONS_SUITABILITY = 'EDIT_OPTIONS_SUITABILITY',
  VIEW_ACCOUNT_OPTIONS_ENROLLED = 'VIEW_ACCOUNT_OPTIONS_ENROLLED',
  EDIT_ACCOUNT_OPTIONS_ENROLLED = 'EDIT_ACCOUNT_OPTIONS_ENROLLED',
  VIEW_INSTRUMENT_OPTIONS_ENABLED = 'VIEW_INSTRUMENT_OPTIONS_ENABLED',
  EDIT_INSTRUMENT_OPTIONS_ENABLED = 'EDIT_INSTRUMENT_OPTIONS_ENABLED',
  VIEW_OPTION_CHAIN = 'VIEW_OPTION_CHAIN',
  VIEW_EXPIRATION_DAY_REPORT = 'VIEW_EXPIRATION_DAY_REPORT',
  ACAT_VIEW = 'read:acat',
  ACAT_CREATE = 'create:acat',
  ACAT_UPDATE = 'edit:acat',
  MINOR_GRADUATION_VIEW = 'read:minor-graduation',
  MINOR_GRADUATION_CREATE = 'create:minor-graduation-request',
  MINOR_GRADUATION_UPDATE = 'edit:minor-graduation',
  ACCOUNT_STATUS_EDIT = 'edit:account-status',
  ACCOUNT_VIOLATION_EDIT = 'edit:account-violations',
  ACCOUNT_FREE_TRADE_BALANCE_EDIT = 'edit:account-free-trade-balance',
  ACCOUNT_MONEY_MARKET_SWEEP_EDIT = 'edit:account-money-market-sweep',
  ACCOUNT_INTEREST_FREE_EDIT = 'edit:account-interest-free',
  ACCOUNT_DIVIDEND_REINVEST_EDIT = 'ACCOUNT_DIVIDEND_REINVEST_EDIT',
  ACCOUNT_CAPITAL_GAIN_REINVEST_EDIT = 'ACCOUNT_CAPITAL_GAIN_REINVEST_EDIT',
  ACCOUNTS_READ = 'read:accounts',
  ACCOUNT_EXCHANGES_READ = 'read:account-exchanges',
  ACCOUNT_MARGIN_READ = 'read:account-margin',
  ACCOUNT_MONEY_READ = 'read:account-money',
  ACCOUNT_ORDERS_READ = 'read:account-orders',
  ACCOUNT_PERFORMANCE_READ = 'read:account-performance',
  ACCOUNT_POSITIONS_READ = 'read:account-positions',
  ACCOUNT_VIOLATIONS_READ = 'read:account-violations',
  ACCOUNT_SUMMARY_READ = 'read:account-summary',
  ACCOUNT_FUNDING_DEPOSITS_VIEW = 'read:account-funding-deposits',
  ACCOUNT_FUNDING_REDEMPTIONS_VIEW = 'read:account-funding-redemptions',
  ACCOUNT_POSITIONS_EDIT = 'edit:account-positions',
  VIOLATION_CREATE = 'create:violations',
  VIOLATION_READ = 'read:violations',
  VIOLATION_DELETE = 'delete:violations',
  MUTUAL_FUND_EDIT = 'MUTUAL_FUND_EDIT',
  AUTO_LIQUIDATION_EDIT = 'AUTO_LIQUIDATION_EDIT',
  AUTO_LIQUIDATION_GET = 'AUTO_LIQUIDATION_GET',
  LIQUIDATIONS_CREATE = 'LIQUIDATIONS_CREATE',
  FIXED_INCOME_EDIT = 'FIXED_INCOME_EDIT',
  ENTITIES_VIEW = 'read:entities',
  QUOTES_VIEW = 'read:quotes',
  ESCHEATMENTS_READ = 'read:escheatments',
}

export enum SecLendingPermissions {
  BROKER_DAILY_READ = 'BROKER_DAILY_READ',
  BROKER_DAILY_WRITE = 'BROKER_DAILY_WRITE',
  POSITION_READ = 'POSITION_READ',
  POSITION_WRITE = 'POSITION_WRITE',
  DAILY_CALC_READ = 'DAILY_CALC_READ',
  DAILY_CALC_WRITE = 'DAILY_CALC_WRITE',
  NOTES_READ = 'NOTES_READ',
  NOTES_WRITE = 'NOTES_WRITE',
  NOTES_DELETE = 'NOTES_DELETE',
  RECON_READ = 'RECON_READ',
  RECON_WRITE = 'RECON_WRITE',
  RECON_HISTORY_READ = 'RECON_HISTORY_READ',
  RECON_HISTORY_WRITE = 'RECON_HISTORY_WRITE',
  OMNI_VIEW = 'OMNI_VIEW',
  UPLOAD_ERRORS = 'UPLOAD_ERRORS',
  DAILY_LENDABLE = 'DAILY_LENDABLE',
}

export enum SecLendingReportsPermissions {
  SEC_LENDING_CLIENT_DAILY_READ = 'SEC_LENDING_CLIENT_DAILY_READ',
  SEC_LENDING_CLIENT_MTD_READ = 'SEC_LENDING_CLIENT_MTD_READ',
  SEC_LENDING_CLIENT_EOM_READ = 'SEC_LENDING_CLIENT_EOM_READ',
  SEC_LENDING_PARTNER_DAILY_READ = 'SEC_LENDING_PARTNER_DAILY_READ',
  SEC_LENDING_PARTNER_MTD_READ = 'SEC_LENDING_PARTNER_MTD_READ',
  SEC_LENDING_SUPPLEMENTAL_DAILY_READ = 'SEC_LENDING_SUPPLEMENTAL_DAILY_READ',
  SEC_LENDING_DW_MTD_READ = 'SEC_LENDING_DW_MTD_READ',
  SEC_LENDING_COLLATERAL_READ = 'SEC_LENDING_COLLATERAL_READ',
  SEC_LENDING_REPORTS_VIEW = 'read:sec-lending-reports',
}

export enum InstrumentRequestPermissions {
  INSTRUMENT_REQUEST_CREATE = 'INSTRUMENT_REQUEST_CREATE',
  INSTRUMENT_REQUEST_APPROVE = 'INSTRUMENT_REQUEST_APPROVE',
}

export enum ManagedOmnibusAccountPermissions {
  MANAGED_OMNIBUS_ALLOCATIONS_CREATE = 'MANAGED_OMNIBUS_ALLOCATIONS_CREATE',
}

export const Permissions = {
  ...RestPermissions,
  ...AllBatchPermissions,
  ...AllViewBatchPermissions,
  ...SecLendingPermissions,
  ...SecLendingReportsPermissions,
  ...InstrumentRequestPermissions,
  ...ManagedOmnibusAccountPermissions,
};

export type {
  InstrumentRequestPermissions,
  ManagedOmnibusAccountPermissions,
  RestPermissions,
  SecLendingPermissions,
  SecLendingReportsPermissions,
};

export type Permission = (typeof Permissions)[keyof typeof Permissions];
