/* eslint-disable import/no-extraneous-dependencies */
import 'regenerator-runtime/runtime';
import 'semantic-ui-less/semantic.less';

import { useAuth0 } from '@auth0/auth0-react';
import { theme } from '@drivehub/ui-components';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as MuiSystemThemeProvider } from '@mui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Big from 'big.js';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { App } from 'src/app/App';
import {
  createGlobalStyle,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import toformat from 'toformat';

import { AuthenticationProvider } from './app/components/providers';
import GlobalSnackbar from './app/components/GlobalSnackbar';
import { GlobalNavbarDrawerContextProvider } from './app/components/Navbar/DrawerOpenProvider';
import { findDeployedEnvironment, history } from './app/utils';
import { SemanticStyle } from './semanticStyle';
import { store } from './store';

Sentry.init({
  dsn: 'https://15488c8051ce03603a05d15ce8462c6a@o4507142978273280.ingest.us.sentry.io/4507142982270976',
  environment: findDeployedEnvironment(window.location.host),
  release: `dashboard-${__APP_VERSION__}`,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration(),
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://*.drivewealth.tech', 'https://*.drivewealth.com', /^\/api\//],
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== 'development' && !window.Cypress,
  profilesSampleRate: 1.0,
});

toformat(Big);
Big.RM = 1;
Big.format.decimalSeparator = '.';
Big.format.thousandsSeparator = ',';

window.gitHash = import.meta.env.REACT_APP_GIT_HASH;
const GlobalStyle = createGlobalStyle`
  // Overriding the white background stripe inside the autofill input fields:
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px ${props => props.theme.dw.colors.corePrimary2} inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  body {
    overscroll-behavior-y: none;
    // Semantic-UI setting this to white with more specificity. We can drop the important after removing semantic-ui
    background-color: ${props => props.theme.dw.colors.corePrimaryBase} !important;
  }

  * {
    font-family: "Space Grotesk";
  }

  #root {
    height: 100%;
  }

  /* wasn't able to set the theme any other way. seems to be issue with notistack and mui version maybe */
  div.SnackbarItem-variantError.SnackbarContent-root.SnackbarItem-contentRoot {
    background-color: ${props => props.theme.dw.colors.negative1};
  }
  div.SnackbarItem-variantSuccess.SnackbarContent-root.SnackbarItem-contentRoot {
    background-color: ${props => props.theme.dw.colors.positive1};
  }
  div.SnackbarItem-variantWarning.SnackbarContent-root.SnackbarItem-contentRoot {
    color: ${props => props.theme.dw.colors.warningBase};
  }
  div.SnackbarItem-variantInfo.SnackbarContent-root.SnackbarItem-contentRoot {
    background-color: ${props => props.theme.dw.colors.info1};
  }
  div.SnackbarContent-root.SnackbarItem-contentRoot {
    padding: 0 8px;
    font-size: 11;
    line-height: 16px;
    letter-spacing: 0.1px;
    box-shadow: 0px 8px 16px 0px rgba(9, 12, 15, 0.4);
    background-color: ${props => props.theme.dw.colors.corePrimary1};
    color: ${props => props.theme.dw.colors.white};
  }
  div.SnackbarContent-root.SnackbarItem-contentRoot svg {
    color: ${props => props.theme.dw.colors.white};
    height: 16px !important;
    width: 16px !important;
  }

  // Long term with i18n these hard-coded left positions may not work
  div.snack-bar-top-left-container.SnackbarContainer-top.SnackbarContainer-left.SnackbarContainer-root {
    top: 80px;
    left: 72px;
  }

  div.snack-bar-top-left-container-open.SnackbarContainer-top.SnackbarContainer-left.SnackbarContainer-root {
    top: 80px;
    left: 256px;
  }


  code {
    font-family: Fira Code !important;
    font-size: 14px !important;
  }
`;

const CalendarStyle = createGlobalStyle`
.react-datepicker {
  && {
    background-color: ${props => props.theme.dw.colors.corePrimary3};
    border: 0;
  }
}

.react-datepicker__header {
  && {
    border: 0;
    background-color: ${props => props.theme.dw.colors.corePrimary3};
  }
}

.react-datepicker__current-month {
  && {
    color: ${props => props.theme.dw.colors.white};
    opacity: 0.9;
    margin-bottom: ${props => props.theme.spacing(1)};
  }
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  && {
    border-radius: ${props => props.theme.shape.borderRadius}px;
    background-color: ${props => props.theme.dw.colors.primaryBase};
    color: ${props => props.theme.dw.colors.white};
  }
}

.react-datepicker__day-names {
  && {
    background-color: ${props => props.theme.dw.colors.corePrimary2};
  }
}

.react-datepicker__day-name {
  && {
    color: ${props => props.theme.dw.colors.white};
    opacity: 0.6;
  }
}


.react-datepicker__day {
  && {
    color: ${props => props.theme.dw.colors.white};
    opacity: 0.9;
  }
}

.react-datepicker__day:hover {
  && {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
  }
}

.react-datepicker__day--disabled {
  && {
    color: rgba(0, 0, 0, 0.38);
    pointer-events: none;
  }
}
`;

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // Leaving comment for reference on catching metadata for errors if we choose to do so.
    // onError: (_error, query) => {
    //   if (query?.meta?.errorMessage) {
    //      snackbar message
    //   }
    // },
  }),
});

const SentryRoute = Sentry.withSentryRouting(Route);

// Temporary Solution to faciliate moving forward. We will remove this after working with design
const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <button onClick={() => loginWithRedirect()}>Log in</button>;
  }

  return <App />;
};

const Main = () => {
  return (
    <StrictMode>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <MuiSystemThemeProvider theme={theme}>
            <StyledComponentsThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <QueryClientProvider client={queryClient}>
                  <GlobalStyle />
                  <CalendarStyle />
                  <SemanticStyle />
                  <AuthenticationProvider>
                    <Router history={history}>
                      <CompatRouter>
                        <Switch>
                          <SentryRoute>
                            <Provider store={store}>
                              <GlobalNavbarDrawerContextProvider>
                                <GlobalSnackbar>
                                  <LoginButton />
                                </GlobalSnackbar>
                              </GlobalNavbarDrawerContextProvider>
                            </Provider>
                          </SentryRoute>
                        </Switch>
                      </CompatRouter>
                    </Router>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </AuthenticationProvider>
                </QueryClientProvider>
              </LocalizationProvider>
            </StyledComponentsThemeProvider>
          </MuiSystemThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </StrictMode>
  );
};

const ProfiledApp = Sentry.withProfiler(Main);

const container = document.getElementById('root') || document.createElement('div');
const root = createRoot(container);

root.render(<ProfiledApp />);

export { store };
