import { QueryFunction } from '@tanstack/react-query';
import { handleResponseNotOk } from 'app/services';
import { EnvironmentVariable } from 'app/types';

const url = new URL('/api/environments', window.location.origin);

export const fetchEnvironmentVariables: QueryFunction<EnvironmentVariable[]> = async ({
  signal,
}) => {
  // Ensure this is the native fetch. This is accessed unauthenticated
  const res = await fetch(url.href, { signal });

  if (!res.ok) {
    handleResponseNotOk(res);
  }

  return res.json();
};
