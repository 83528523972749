import { Auth0QueryFn } from 'app/hooks';
import { TransactionType } from "app/types";

export type WlpFinTranTypes = {
  wlpID: string;
  wlpName: string;
  types: TransactionType[];
};
const endpoint = new URL('/api/transactions/types', window.location.origin).href;

/**
 * Fetches the list of WLP financial transaction types.
 *
 * @param {typeof global.fetch} fetch - The fetch function to use for the request.
 * @returns {Promise<WlpFinTranTypes[]>} A promise that resolves to an array of WLP financial transaction types.
 */

export const fetchWlpFinTranTypes: Auth0QueryFn<WlpFinTranTypes[]> =
  fetch =>
  async ({ signal }) => {
    const data = await fetch(endpoint, { signal });
    return data.json();
  };
