import { Box, Menu } from '@drivehub/ui-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { cloneElement, PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';

import { useMenu } from '../../hooks';

const CenteredDropdownIcon = styled(ArrowDropDownIcon)`
  margin-top: auto;
  margin-bottom: auto;
  width: 16px;
  height: 16px;
  margin-left: ${props => props.theme.spacing(0.5)};
`;

export interface TopNavBarDropdownMenuProps {
  testId?: string;
  menuItems?: ReactElement[];
  children?: React.ReactNode;
}

export const TopNavBarDropdownMenu = ({
  children,
  menuItems,
  testId,
}: PropsWithChildren<TopNavBarDropdownMenuProps>): JSX.Element => {
  const { anchorEl, isMenuOpen, toggleMenuOpen } = useMenu<HTMLElement>();

  return (
    <>
      <Box
        ref={anchorEl}
        display="flex"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={toggleMenuOpen}
        height={32}
        flexShrink={0}
        data-testid={testId}
      >
        {children}
        <CenteredDropdownIcon />
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl.current}
        keepMounted
        open={isMenuOpen}
        onClose={toggleMenuOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuItems?.map(element =>
          cloneElement(element, {
            onClick: (...args: unknown[]) => {
              toggleMenuOpen();
              element.props.onClick?.(...args);
            },
          }),
        )}
      </Menu>
    </>
  );
};
