import { DefaultError, QueryClient, useQueryClient } from '@tanstack/react-query';
import { Permissions } from 'app/constants';
import {
  Auth0MutationFn,
  useAuth0Mutation,
  UseAuth0MutationOptionsOverrides,
} from 'app/hooks/auth0/useAuth0Mutation';
import { CancelRebill } from 'app/types';
import { produce } from 'immer';

import { cancelRebillQueries } from 'app/components/queries';
import { handleResponseNotOk } from 'app/services';

type CancelRebillPayload = {
  partnerID?: string;
  id?: string;
  status: string;
  comment?: string;
};

export type UpdateCancelRebillVariables = {
  cancelRebillList: CancelRebillPayload[];
};

const endpoint = new URL('/api/asset-transfers/cancel-rebills', window.location.origin).href;

export const useUpdateCancelRebill = (
  options?: UseAuth0MutationOptionsOverrides<
    CancelRebill,
    DefaultError,
    UpdateCancelRebillVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useAuth0Mutation<CancelRebill, DefaultError, UpdateCancelRebillVariables>({
    mutationFn: updateCancelRebill,
    onSuccess: handleUpdateCancelRebillSuccess(queryClient),
    permission: Permissions.CANCEL_REBILL_EDIT,
    ...options,
  });
};

const updateCancelRebill: Auth0MutationFn<CancelRebill, UpdateCancelRebillVariables> =
  fetch => async variables => {
    const data = await fetch(endpoint, {
      method: 'PATCH',
      body: JSON.stringify(variables),
    });

    const dataOrError = await data.json();
    if (!data.ok) {
      handleResponseNotOk(dataOrError, dataOrError.message);
    }

    return dataOrError;
  };

const handleUpdateCancelRebillSuccess =
  (queryClient: QueryClient) => async (data: CancelRebill) => {
    queryClient.setQueryData(cancelRebillQueries.list([]).queryKey, prev =>
      produce(prev ?? { data: [] }, draft => {
        draft.data.push(data);
      }),
    );
  };
