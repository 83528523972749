import { FormikConfig, useFormik } from 'formik';
import { ClientError } from 'graphql-request';
import { useCallback, useEffect, useState } from 'react';
import { downloadReport } from 'src/app/components/BestExReports/utils';
import { useIsInternalUser, useSecLendingReports, useSnackbar, useToggle } from 'src/app/hooks';
import { copyText } from 'src/app/internationalization';
import { ReportParameters } from 'src/app/types/reports';
import { formatDateMonDayYear, getGQLErrors } from 'src/app/utils';

import { getErrorText, getReportHeaderText, getReportParameters } from 'src/app/components/SecLendingReports/utils';
import { SecLendingReport, SecLendingReportCode } from 'src/app/types';

type UseReportOptions = {
  getDefaultReportParameters: (partnerCode?: string | null) => ReportParameters;
  validationSchema: FormikConfig<unknown>['validationSchema'];
  reportCode: SecLendingReportCode;
};

type UseReportReturnType = {
  downloadURL?: string;
  headerText: string;
  error: ClientError | null;
  status: 'error' | 'pending' | 'success';
  formik: ReturnType<typeof useFormik>;
  isInternalUser: boolean;
  isGeneratingReport: boolean;
};

const {
  reports: { successMessage },
  bestEx: { unknownErrorGeneratingReport },
} = copyText;

export const useReport = ({
  getDefaultReportParameters,
  validationSchema,
  reportCode,
}: UseReportOptions): UseReportReturnType => {
  // TODO: Get this through Auth0
  const partnerCode = '';
  const { isInternal: isInternalUser } = useIsInternalUser();
  const defaultPartner = isInternalUser ? '' : partnerCode;
  const defaultReportParameters = getDefaultReportParameters(defaultPartner);

  const [isActive, toggleIsActive] = useToggle();
  const [reportParameters, setReportParameters] = useState<SecLendingReport | undefined>();
  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });
  const [date, setDate] = useState<Date | null>();

  const generateReport = useCallback(
    (newReportParameters: ReportParameters) => {
      setDate(newReportParameters.date);
      // Here's where we do the translation from FE to GQL
      const newParams = getReportParameters(reportCode, newReportParameters);
      setReportParameters(newParams);

      toggleIsActive();
    },
    [toggleIsActive, reportCode],
  );

  const {
    error,
    isError,
    data: downloadURL,
    status,
    isLoading: isGeneratingReport,
  } = useSecLendingReports(reportParameters, { enabled: isActive });

  if (downloadURL) {
    enqueueSnackbar(successMessage, { variant: 'success' });
    downloadReport(downloadURL);
    toggleIsActive();
  }

  if (isError) {
    if (!date) {
      throw new Error('Date must be set!');
    }

    const errorText = getErrorText(getGQLErrors(error as ClientError), date);
    const errorMessage = unknownErrorGeneratingReport.replace(
      '%FIELD%',
      formatDateMonDayYear(date),
    );

    enqueueSnackbar(errorText && errorText.length > 0 ? errorText : errorMessage, {
      variant: 'error',
    });
    toggleIsActive();
  }

  const formik = useFormik<ReportParameters>({
    initialValues: defaultReportParameters,
    validationSchema,
    onSubmit: values => {
      generateReport(values);
    },
    // Unfortunately, no good return value provided by the Formik lib and I don't want to try to type out 30 of their internal fields
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;

  // TODO: Revisit with Auth0
  // useAuthSession is initially empty string on a fresh reload and the partner code must match the user's partner code
  useEffect(() => {
    const partner = isInternalUser ? '' : partnerCode;

    if (partner !== formik.values.partnerCode && !isInternalUser) {
      formik.setFieldValue('partnerCode', partner);
    }
  }, [formik, isInternalUser, partnerCode]);

  const headerText = getReportHeaderText(reportCode);

  return {
    headerText,
    error: error as ClientError,
    downloadURL,
    status,
    formik,
    isInternalUser,
    isGeneratingReport,
  };
};
