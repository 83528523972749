import { Backdrop, Box, InputBase } from '@mui/material';
import { useAuth0Query, useDebounce, useSnackbar } from 'app/hooks';
import styled from 'styled-components';
import { useEffect } from 'react';

import SearchSuggestions from '../../../legacy/routes/Interior/NavSearch/SearchSuggestions';
import { copyText } from '../../internationalization';
import { useNavSearch } from './hooks/useNavSearch';
import { searchQueries } from '../queries';

const {
  common: { search: searchPlaceholder },
  errors: { anErrorHasOccurred },
} = copyText;

const SEARCH_DEBOUNCE_TIMEOUT = 250;

const SearchBarContainer = styled('div')<{
  query: string;
}>`
  flex-grow: 1;
  max-width: ${props => props.theme.spacing(46)};
  z-index: 2;
  height: 32px;
  position: relative;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border-bottom-left-radius: ${props =>
    !props.query.length
      ? props.theme.shape.borderRadius
      : '0'}px; // Make this flush with the suggestions menu
  border-bottom-right-radius: ${props =>
    !props.query.length
      ? props.theme.shape.borderRadius
      : '0'}px; // Make this flush with the suggestions menu
  background-color: ${props => props.theme.dw.colors.corePrimary2};
  transition: ${props =>
    props.theme.transitions.create(['all'], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.enteringScreen,
    })};

  input {
    height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${props => props.theme.spacing(1)};
    padding-right: ${props => props.theme.spacing(2)};
    opacity: 1;
    transition: ${props =>
      props.theme.transitions.create(['opacity', 'width', 'padding'], {
        easing: props.theme.transitions.easing.sharp,
        duration: props.theme.transitions.duration.enteringScreen,
      })};
  }

  .MuiInputBase-root {
    height: 32px;
    color: inherit;
    width: 100%;
    cursor: 'inherit';
  }
`;

const SearchBarBackdrop = styled(Backdrop)`
  &&& {
    z-index: 1;
    margin-right: 0; // Inheriting the navbar item spacing by default
  }
`;

export const NavSearch = (): JSX.Element => {
  const { query, inputRef, handleChange, handleClose, isOpen, setIsOpen, unfocusSeachBar } =
    useNavSearch();
  const debouncedSearch = useDebounce(query, SEARCH_DEBOUNCE_TIMEOUT);

  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });
  const {
    data: searchData,
    isFetching,
    isLoading,
    isError,
    error,
  } = useAuth0Query(searchQueries.list(debouncedSearch));

  useEffect(() => {
    if (isError) {
      const { message, errorCode } = JSON.parse(error.message);
      enqueueSnackbar(message ? `${errorCode} ${message}` : anErrorHasOccurred, {
        variant: 'error',
        persist: true,
        preventDuplicate: true,
      });
      handleClose();
    }
  }, [isError, error, handleClose, enqueueSnackbar]);
  

  const onSearchBarClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <SearchBarContainer query={query}>
        <InputBase
          value={query}
          placeholder={searchPlaceholder}
          inputProps={{ 'aria-label': 'search', maxLength: '80' }}
          inputRef={inputRef}
          onChange={handleChange}
          onClick={onSearchBarClick}
          onKeyDown={e => e.key === 'Escape' && unfocusSeachBar()}
          autoFocus
          data-testid="nav-searchbar"
        />
        {isOpen && (
          <Box onClick={handleClose}>
            <SearchSuggestions
              isActive
              results={searchData}
              isFetching={isFetching}
              isLoading={isLoading}
              query={query}
            />
          </Box>
        )}
      </SearchBarContainer>
      <SearchBarBackdrop open={isOpen} onClick={handleClose} />
    </>
  );
};
