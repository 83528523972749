import { startOfMonth } from 'date-fns';
import { GraphQLError } from 'graphql-request/build/esm/types';
import { flatten, isUndefined } from 'lodash';
import {
  DriveWealthDailyReportParameters,
  ReportParameters,
  SecLendingDailyReportParameters,
  SecLendingMonthlyReportParameters,
  SupplementalDailyReportParameters,
} from 'src/app/types/reports';
import {
  formatDateMonDayYear,
  formatDateOnly,
  formatYYYYMMDD,
  isRequiredField,
  neverReached,
} from 'src/app/utils';

import { copyText } from '../../internationalization';
import {
  ClientEndOfMonthReport,
  DrivewealthMonthlyReport,
  EndOfMonthReportParameter,
  Report,
  ReportParameter as GQLReportParameter,
  SecLendingReport,
  SecLendingReportCode,
  SupplementalDailyReport,
  SupplementalDailyReportParameter,
} from '../../types/secLending';

const {
  common: { date: dateLabel, month },
  partner: { partner: partnerLabel },
  secLending: {
    reportTypes: {
      clientDaily,
      clientMTD,
      clientEOM,
      partnerDaily,
      partnerMTD,
      dwMTD,
      supplemental,
      collateralReport,
    },
    errors: { cannotBeNull, parameterNotFound, invalidDateFormat },
  },
  bestEx: { notFoundError, unknownErrorGeneratingReport },
} = copyText;

export const getReportHeaderText = (reportType: SecLendingReportCode): string => {
  switch (reportType) {
    case 'client_daily':
      return clientDaily;
    case 'client_mtd':
      return clientMTD;
    case 'partner_daily':
      return partnerDaily;
    case 'partner_mtd':
      return partnerMTD;
    case 'dw_mtd':
      return dwMTD;
    case 'client_eom':
      return clientEOM;
    case 'supplemental_daily':
      return supplemental;
    case 'collateral_report':
      return collateralReport;
    default:
      return neverReached(reportType);
  }
};

const getErrorCopyText = (
  { error, field }: { error: string; field: string },
  date: Date,
): string => {
  if (error.includes('is not available.')) {
    return notFoundError.replace('%FIELD%', formatDateMonDayYear(date));
  }

  // The following errors should never occur from the FE
  if (error.includes('Invalid Date Format')) {
    return invalidDateFormat;
  }

  if (error.includes('One or more Report Parameter/s found empty.')) {
    return parameterNotFound;
  }

  if (error.includes('Cannot be null')) {
    return cannotBeNull.replace('%FIELD%', field);
  }

  return unknownErrorGeneratingReport.replace('%FIELD%', formatDateMonDayYear(date));
};

export const getErrorText = (errors: GraphQLError[], date: Date): string[] => {
  return flatten(
    errors.map(error =>
      (error.extensions.error as { error: string; field: string }[]).map((e: { error: string; field: string }) => {
        return e;
      }),
    ),
  ).map(error => getErrorCopyText(error, date));
};

export const dateRequired = isRequiredField(dateLabel);
export const monthRequired = isRequiredField(month);
export const partnerRequired = isRequiredField(partnerLabel);

const doesRangeHaveValue = (range: [number | Date | null, number | Date | null]): boolean => {
  return range && (range?.[0] !== null || range?.[1] !== null);
};
const getNumberRangeWithDefaults = (range: [number | null, number | null]): [number, number] => {
  // -1 is treated as the open-ended operator for the sec lending reports
  return [range?.[0] ?? -1, range?.[1] ?? -1];
};

const getDailyReport = (
  reportCode: 'client_daily' | 'partner_daily' | 'client_mtd' | 'partner_mtd',
  options: SecLendingDailyReportParameters,
): Report => {
  const { date, partnerCode, accountNo, symbol, quantity, accruedInterest } = options;

  if (isUndefined(partnerCode)) {
    throw new Error('Partner cannot be undefined');
  }

  if (!date || !partnerCode) {
    throw new Error('Date or partner code null');
  }

  const reportParameters: GQLReportParameter[] = [
    { parameterName: 'reportDate', parameterValue: [formatDateOnly(date)] },
    { parameterName: 'partnerCode', parameterValue: [partnerCode] },
  ];

  if (accountNo) {
    reportParameters.push({ parameterName: 'accountNo', parameterValue: [accountNo] });
  }

  if (symbol) {
    reportParameters.push({ parameterName: 'symbol', parameterValue: [symbol.symbol] });
  }

  if (doesRangeHaveValue(quantity)) {
    reportParameters.push({
      parameterName: 'quantity',
      parameterValue: getNumberRangeWithDefaults(quantity),
    });
  }

  if (doesRangeHaveValue(accruedInterest)) {
    reportParameters.push({
      parameterName: 'accruedInterest',
      parameterValue: getNumberRangeWithDefaults(accruedInterest),
    });
  }

  return {
    reportCode,
    reportParameters,
  } as Report;
};

const getMonthlyReport = (
  reportCode: 'client_eom',
  options: SecLendingMonthlyReportParameters,
): ClientEndOfMonthReport => {
  const { date, partnerCode, accountNo, symbol, accruedInterest } = options;

  if (isUndefined(partnerCode)) {
    throw new Error('Partner cannot be undefined');
  }

  if (!date || !partnerCode) {
    throw new Error('Date or partner code null');
  }

  const reportParameters: EndOfMonthReportParameter[] = [
    {
      parameterName: 'reportMonthYear',
      parameterValue: [formatDateOnly(startOfMonth(date))],
    },
    { parameterName: 'partnerCode', parameterValue: [partnerCode] },
  ];

  if (accountNo) {
    reportParameters.push({ parameterName: 'accountNo', parameterValue: [accountNo] });
  }

  if (symbol) {
    reportParameters.push({ parameterName: 'symbol', parameterValue: [symbol.symbol] });
  }

  if (doesRangeHaveValue(accruedInterest)) {
    reportParameters.push({
      parameterName: 'accruedInterest',
      parameterValue: getNumberRangeWithDefaults(accruedInterest),
    });
  }

  return {
    reportCode,
    reportParameters,
  };
};

const getDrivewealthDailyReport = (
  reportCode: 'dw_mtd',
  options: DriveWealthDailyReportParameters,
): DrivewealthMonthlyReport => {
  const { date } = options;

  if (!date) {
    throw new Error('Date or partner code null');
  }

  return {
    reportCode,
    reportParameters: [{ parameterName: 'reportDate', parameterValue: [formatDateOnly(date)] }],
  };
};

const getSupplementalDailyReport = (
  reportCode: 'supplemental_daily',
  options: SupplementalDailyReportParameters,
): SupplementalDailyReport => {
  const {
    date,
    partnerCode,
    loanDate,
    collateral,
    office,
    symbol,
    cusip,
    quantity,
    accountNumber,
  } = options;

  if (!date || !partnerCode) {
    throw new Error('Date or partner code null');
  }

  const reportParameters: SupplementalDailyReportParameter[] = [
    {
      parameterName: 'reportDate',
      parameterValue: [formatDateOnly(date)],
    },
  ];

  if (partnerCode) {
    reportParameters.push({
      parameterName: 'partnerCode',
      parameterValue: [partnerCode],
    });
  }

  if (doesRangeHaveValue(loanDate)) {
    reportParameters.push({
      parameterName: 'loanDate',
      // TS requiring length two and can't assert on length
      parameterValue: [
        loanDate[0] ? formatDateOnly(loanDate[0]) : null,
        loanDate[1] ? formatDateOnly(loanDate[1]) : null,
      ],
    });
  }

  if (office) {
    reportParameters.push({
      parameterName: 'office',
      parameterValue: [office],
    });
  }

  if (symbol) {
    reportParameters.push({
      parameterName: 'symbol',
      parameterValue: [symbol.symbol],
    });
  }

  if (cusip) {
    reportParameters.push({
      parameterName: 'cusip',
      parameterValue: [cusip],
    });
  }

  if (doesRangeHaveValue(quantity)) {
    reportParameters.push({
      parameterName: 'quantity',
      parameterValue: getNumberRangeWithDefaults(quantity),
    });
  }

  if (accountNumber) {
    reportParameters.push({
      parameterName: 'accountNumber',
      parameterValue: [accountNumber],
    });
  }

  if (collateral) {
    reportParameters.push({
      parameterName: 'collateral',
      parameterValue: collateral,
    });
  }

  return {
    reportCode,
    reportParameters,
  };
};

export const getReportParameters = (
  reportCode: SecLendingReportCode,
  options: ReportParameters,
): SecLendingReport => {
  switch (reportCode) {
    // Fall-through expected
    case 'client_daily':
    case 'partner_daily':
    case 'client_mtd':
    case 'partner_mtd': {
      return getDailyReport(reportCode, options as SecLendingDailyReportParameters);
    }
    case 'client_eom': {
      return getMonthlyReport(reportCode, options as SecLendingMonthlyReportParameters);
    }
    case 'dw_mtd': {
      return getDrivewealthDailyReport(reportCode, options as DriveWealthDailyReportParameters);
    }
    case 'collateral_report': {
      const { date } = options as DriveWealthDailyReportParameters;

      if (!date) {
        throw new Error('Date or partner code null');
      }

      return {
        reportCode,
        reportParameters: [{ parameterName: 'reportDate', parameterValue: [formatYYYYMMDD(date)] }],
      };
    }
    case 'supplemental_daily': {
      return getSupplementalDailyReport(reportCode, options as SupplementalDailyReportParameters);
    }
    default:
      return neverReached(reportCode);
  }
};

export const downloadDocument = (documentText: string, fileName: string): void => {
  if (!documentText || !fileName) return;
  const url = URL.createObjectURL(new Blob([documentText]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
  URL.revokeObjectURL(url);
};
