import { queryOptions } from 'app/hooks';
import { fetchVersion } from './services/fetchCheckVersion';

export const versionQueries = {
  all: () =>
    queryOptions({
      queryKey: ['version'] as const,
      queryFn: () => () => [] as const,
    }),
  lists: () =>
    queryOptions({
      queryKey: [...versionQueries.all().queryKey, 'list'] as const,
      queryFn: () => () => [] as const,
    }),
  list: () =>
    queryOptions({
      queryKey: [...versionQueries.lists().queryKey] as const,
      queryFn: fetchVersion,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
    }),
};
