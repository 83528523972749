import { DefaultError, QueryClient, useQueryClient } from '@tanstack/react-query';
import {
  Auth0MutationFn,
  useAuth0Mutation,
  UseAuth0MutationOptionsOverrides,
} from 'app/hooks/auth0/useAuth0Mutation';
import { produce } from 'immer';

import { dashboardAcknowledgmentQueries } from 'app/components/queries';
import { Acknowledgment } from 'app/types';

const endpoint = new URL('/api/dashboard/acknowledgments', window.location.origin).href;

export type AddAcknowledgmentVariables = Pick<Acknowledgment, 'disclosureID' | 'version'>;

/**
 * Custom hook for adding an acknowledgment using Auth0 mutation.
 *
 * @param options - Optional configuration options for the Auth0 mutation
 * @returns A mutation object that handles the acknowledgment creation and updates the query cache
 *
 * @example
 * ```tsx
 * const { mutate, isLoading } = useAddAcknowledgment();
 *
 * const handleSubmit = () => {
 *   mutate({ acknowledgmentData });
 * };
 * ```
 *
 * @remarks
 * This hook:
 * - Sends a POST request to add a new acknowledgment
 * - Automatically updates the dashboard acknowledgment list in the query cache
 * - Supports custom mutation options through the options parameter
 */
export const useAddAcknowledgment = (
  options?: UseAuth0MutationOptionsOverrides<
    Acknowledgment,
    DefaultError,
    AddAcknowledgmentVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useAuth0Mutation<Acknowledgment, DefaultError, AddAcknowledgmentVariables>({
    mutationFn: createAcknowlegment,
    onSuccess: handleSuccess(queryClient),
    ...options,
  });
};

const createAcknowlegment: Auth0MutationFn<Acknowledgment, AddAcknowledgmentVariables> =
  fetch => async variables => {
    const data = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(variables),
      headers: {
        'Content-type': 'application/json',
      },
    });

    return data.json();
  };

/**
 * Creates a success handler function for adding a new acknowledgment.
 *
 * @param queryClient - The React Query client instance used for managing cache
 * @returns An async function that updates the query cache with the new acknowledgment data
 * @remarks
 * This function produces a new handler that:
 * - Takes an Acknowledgment object as parameter
 * - Updates the cached list of acknowledgments by appending the new acknowledgment
 * - Creates a new data array if none exists
 *
 * @example
 * ```typescript
 * const onSuccess = handleSuccess(queryClient);
 * await onSuccess(newAcknowledgment);
 * ```
 */
export const handleSuccess = (queryClient: QueryClient) => async (data: Acknowledgment) => {
  queryClient.setQueryData(dashboardAcknowledgmentQueries.list().queryKey, prev =>
    produce(prev ?? { data: [] }, draft => {
      draft.data.push(data);
    }),
  );
};
