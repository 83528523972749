import { Auth0QueryFn } from 'app/hooks';
import { handleResponseNotOk } from 'app/services';

const url = new URL('/api/sec-lending/enrolled-status', window.location.origin);

export const fetchSecLendingEnrolledStatus: Auth0QueryFn<boolean> =
  fetch =>
  async ({ signal }) => {
    const res = await fetch(url.href, { signal });

    if (!res.ok) {
      handleResponseNotOk(res);
    }

    return res.json();
  };
