import { useAuth0 } from '@auth0/auth0-react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useAuth0Query, useSnackbar } from 'app/hooks';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { Switch } from 'react-router-dom';
import { copyText } from 'src/app/internationalization/copyText';
import AmountProvider from 'src/legacy/components/Amount/Provider';
import InteriorView from 'src/legacy/routes/Interior/InteriorView';
import { ApiTermsOfUse } from './components/APITermsOfUse/APITermsOfUse';
import { AuthorizationLoader } from './components/AuthorizationLoader';
import { Navbar } from './components/Navbar';
import { versionQueries } from './components/queries/version';
import { SentryRoute } from './components/Routes';
import { useStartUpLoader } from './hooks/useStartUpLoader';

const {
  common: { whiteScreenError },
  generics: { refreshVersionSnackback },
} = copyText;

const ErrorFallback = () => {
  return null;
};

export const App = (): JSX.Element => {
  const { data: checkVersionData } = useAuth0Query({ ...versionQueries.list() });
  const isStartUpLoading = useStartUpLoader();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });

  const currentVersion = checkVersionData?.version;

  useEffect(() => {
    if (currentVersion && currentVersion !== __APP_VERSION__) {
      enqueueSnackbar(refreshVersionSnackback, {
        variant: 'info',
        persist: true,
        preventDuplicate: true,
      });
    }
  }, [currentVersion]);

  useEffect(() => {
    // Not a fan of this, but this is targeted to be removed this year
    dispatch({
      type: 'SET_ACCESS_TOKEN_FUNCTION',
      payload: { accessTokenFunction: getAccessTokenSilently },
    });
  }, [dispatch, getAccessTokenSilently]);

  return (
    <>
      <Backdrop open={isStartUpLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <ApiTermsOfUse />
      <AmountProvider>
        <AuthorizationLoader>
          <Navbar>
            <Switch>
              <SentryRoute path="/">
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onError={(err, stack) => {
                    enqueueSnackbar(<p>{whiteScreenError}</p>, {
                      variant: 'error',
                      persist: true,
                      preventDuplicate: true,
                    });
                    // eslint-disable-next-line no-console
                    console.error(err);
                    // eslint-disable-next-line no-console
                    console.info(stack);
                  }}
                >
                  <InteriorView />
                </ErrorBoundary>
              </SentryRoute>
            </Switch>
          </Navbar>
        </AuthorizationLoader>
      </AmountProvider>
    </>
  );
};
