import { useAuth0 } from '@auth0/auth0-react';
import type { PropsWithChildren } from 'react';
import { useWhoAmI } from 'src/app/hooks';

export const AuthorizationLoader = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  // These two pieces constitute the core of our auth, Auth0 and WhoAmI
  const { isAuthenticated } = useAuth0();
  const { data: whoAmIData, isSuccess } = useWhoAmI();
  const isAuthLoaded = Boolean(isAuthenticated && isSuccess && whoAmIData);

  return <>{isAuthLoaded && <>{children}</>}</>;
};
