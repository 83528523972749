import { AllBatchPermissions } from 'src/app/constants';

/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const lockUnlockBatches = {
  displayName: 'Lock/Unlock',
  permission: AllBatchPermissions.LOCK_UNLOCK_CREATE_BATCH,
  checks: {
    timeCheck: {
      permissionBypass: AllBatchPermissions.LOCK_UNLOCK_CREATE_BATCH_BYPASS,
      tz: 'America/New_York',
      start: {
        hours: 8,
        minutes: 0,
      },
      end: {
        hours: 13,
        minutes: 30,
      },
    },
    columns: {
      reason: {
        lockReason: true,
      },
    },
  },
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    accountno: {
      label: 'Account No',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountNo',
    },
    symbol: {
      label: 'Symbol',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'symbol',
    },
    lock: {
      label: 'Lock',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'boolean',
      serverKey: 'lock',
    },
    reason: {
      label: 'Reason',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'reason',
    },
    note: {
      label: 'Note',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'note',
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      accountNo: 'QWERTY12345',
      symbol: 'ACBI',
      lock: 'true',
      reason: 'ACAT',
      note: 'A note about this item',
    },
  ],
};

export { lockUnlockBatches };
