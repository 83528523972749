import { useAuth0 } from '@auth0/auth0-react';
import { IconButton } from '@drivehub/ui-components';
import { Check, Close, InfoOutlined, WarningAmber } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { createRef, ReactNode, useContext } from 'react';

import { GlobalNavbarDrawerContext } from '../Navbar/DrawerOpenProvider';

// TODO: This probably doesn't matter anymore. We're replacing our login with the Auth0 universal, so we should always be auth'ed by the point
const getSnackbarOrigin = (isAuthenticated: boolean, isDrawerOpen: boolean): string | undefined => {
  if (isAuthenticated) {
    return isDrawerOpen ? 'snack-bar-top-left-container-open' : 'snack-bar-top-left-container';
  }

  // If we aren't yet logged in, use the default behavior so we aren't offset
  return undefined;
};

const GlobalSnackbar = ({ children }: { children: ReactNode }): JSX.Element => {
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => notistackRef.current?.closeSnackbar(key);
  const { isDrawerOpen } = useContext(GlobalNavbarDrawerContext);
  const { isAuthenticated } = useAuth0();

  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      classes={{
        containerAnchorOriginTopLeft: getSnackbarOrigin(isAuthenticated, isDrawerOpen),
      }}
      iconVariant={{
        success: <Check style={{ marginRight: 8 }} />,
        error: <ErrorOutlineIcon style={{ marginRight: 8 }} />,
        warning: <WarningAmber style={{ marginRight: 8 }} />,
        info: <InfoOutlined style={{ marginRight: 8 }} />,
        default: <InfoOutlined style={{ marginRight: 8 }} />,
      }}
      ref={notistackRef}
      action={key => (
        <IconButton onClick={onClickDismiss(key)}>
          <Close />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default GlobalSnackbar;
