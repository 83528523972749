import { copyText } from 'src/app/internationalization';
import { UserDocumentStatusName } from 'src/app/types';
import { neverReached } from 'src/app/utils';

const {
  documents: {
    statuses: { pending, approved, rejected, notSubmitted },
  },
} = copyText;

export const getStatusName = (name: UserDocumentStatusName): string => {
  switch (name) {
    case 'NOT_SUBMITTED':
      return notSubmitted;
    case 'APPROVED':
      return approved;
    case 'PENDING':
      return pending;
    case 'REJECTED':
      return rejected;

    default:
      return neverReached(name);
  }
};
