import { Auth0QueryFn } from 'app/hooks';
import { AcknowledgmentAPIResponse } from '../../../types';

const endpoint = new URL(`/api/dashboard/acknowledgments`, window.location.origin).href;

/**
 * Fetches the dashboard acknowledgments.
 *
 * @param {typeof global.fetch} fetch - The fetch function to use for the request.
 * @returns {Promise<AcknowledgmentAPIResponse>} A promise that resolves to the dashboard acknowledgments.
 */
export const fetchDashboardAcknowledgments: Auth0QueryFn<AcknowledgmentAPIResponse> =
  fetch =>
  async ({ signal }) => {
    const data = await fetch(endpoint, { signal });
    const json = await data.json();

    return json;
  };
