import { useCallback, useState } from 'react';

/**
 * Custom hook that manages a boolean toggle state with utility functions to update it.
 * Provides a memoized toggle function to prevent unnecessary re-renders in child components.
 *
 * @param {boolean} initialState - The initial state of the toggle
 * @returns {[boolean, () => void, (bool: boolean) => void]} A tuple containing:
 *   - state: Current boolean state
 *   - toggle: Function to toggle the state
 *   - setState: Function to set the state to a specific boolean value
 *
 * @example
 * ```tsx
 * const [isOpen, toggle, setIsOpen] = useToggle(false);
 *
 * return (
 *   <>
 *     <button onClick={toggle}>Toggle</button>
 *     <button onClick={() => setIsOpen(true)}>Open</button>
 *     {isOpen && <div>Content</div>}
 *   </>
 * );
 * ```
 */
export const useToggle = (initialState = false): [boolean, () => void, (bool: boolean) => void] => {
  // Initialize the state
  const [state, setState] = useState<boolean>(initialState);
  // Define and memoize toggler function in case we pass down the component,
  // This function changes the boolean value to its opposite value
  const toggle = useCallback((): void => setState(sta => !sta), []);
  return [state, toggle, setState];
};
