import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import { reportingSecLendingGraphQLClient } from 'app/services/graphqlClient';
import { SecLendingReport, SecLendingReportCode, UseQueryOptions } from 'app/types';

export type SecLendingReportParams = {
  reportCode: SecLendingReportCode;
};

type SecLendingReportReturnValue = string;

export const useSecLendingReports = (params?: SecLendingReport, options?: UseQueryOptions) => {
  return useQuery({
    queryKey: ['sec-lending', params],
    queryFn: async (): Promise<SecLendingReportReturnValue> => {
      try {
        const data = await reportingSecLendingGraphQLClient.request<{
          getGeneratedReport: {
            urlList: [string];
          };
        }>(
          gql`
            query GenerateReport($req: UrlGenerationInput) {
              getGeneratedReport(reportInput: $req) {
                urlList
              }
            }
          `,
          { req: params },
        );

        const {
          getGeneratedReport: {
            urlList: [reportURL],
          },
        } = data;

        return reportURL;
      } catch (error) {
        throw new Error(String(error));
      }
    },
    ...options,
  });
};
