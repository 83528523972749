import { DefaultError } from '@tanstack/react-query';
import { Permissions } from 'app/constants';
import { Auth0MutationFn, useAuth0Mutation, UseAuth0MutationOptionsOverrides } from 'app/hooks/auth0/useAuth0Mutation';
import { CancelRebillReturnValue } from 'app/types';

export type UseCancelRebillOrderVariables = {
  orderNo?: string;
  type: string;
  reason: string;
  approvedBy?: string;
  comment?: string;
  adjustedPrice?: number;
  profitAndLoss?: number;
  adjustedCommission?: number;
  errorAccountNo?: string;
};

const endpoint = new URL('/api/asset-transfers/cancel-rebills', window.location.origin).href;

export const useCancelRebillRequest = (options?: UseAuth0MutationOptionsOverrides<CancelRebillReturnValue, DefaultError, UseCancelRebillOrderVariables>) => {

  return useAuth0Mutation<CancelRebillReturnValue, DefaultError, UseCancelRebillOrderVariables>({
    mutationFn: createCancelRebill,
    permission: Permissions.CANCEL_REBILL_CREATE,
    ...options
  });
};

const createCancelRebill: Auth0MutationFn<CancelRebillReturnValue, UseCancelRebillOrderVariables> = fetch => async (variables) => {
  const data = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(variables),
  });

  return data.json();
};
