import { useAuth0Query, useEnvironment } from 'app/hooks';
import { Acknowledgment } from 'app/types';
import { dashboardAcknowledgmentQueries, disclosureQueries } from '../queries';
import { APITermsOfUseModal } from './APITermsOfUseModal';
import { getActiveDisclosures } from './utils';

export const ApiTermsOfUse = (): JSX.Element => {
  const environment = useEnvironment();

  const { data: acknowledgmentsData, isSuccess: isAckDataSuccess } = useAuth0Query(
    dashboardAcknowledgmentQueries.list(),
  );

  // Filter down the disclosures to get the proper DH type for this view
  const { data: activeDisclosure, isSuccess: isDisclosuresSuccess } = useAuth0Query({
    ...disclosureQueries.list(),
    select: getActiveDisclosures,
  });

  const hasUnsignedAcknowledgment = acknowledgmentsData?.data.some(
    (el: Acknowledgment) =>
      el.version === activeDisclosure?.version &&
      el.disclosureID === activeDisclosure?.disclosureID &&
      el.accepted === true &&
      el.environment === environment.value,
  );

  return (
    <APITermsOfUseModal
      open={
        isAckDataSuccess && isDisclosuresSuccess && !!activeDisclosure && !hasUnsignedAcknowledgment
      }
    />
  );
};
