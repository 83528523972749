import { Permission } from '../../constants/permissions';

/**
 * Generates authorization parameters with an optional scope for authentication.
 *
 * @param scope - Optional permission scope to be included in the authorization parameters
 * @returns Authorization parameters object containing audience and optional scope
 */
export const getAuthorizationParamsWithScope = (scope?: Permission | Permission[]) => ({
  authorizationParams: {
    audience: 'https://dashboard.drivewealth.com/api',
    scope: Array.isArray(scope) ? scope.join(' ') : scope,
  },
});
