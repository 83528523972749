import { Auth0QueryFn } from 'app/hooks';
import { handleResponseNotOk } from 'app/services';
import { BOPartner } from 'app/types';

const endpoint = new URL('/api/partners/partner-profiles', window.location.origin).href;

/**
 * Fetches the list of BO partners.
 *
 * @param {typeof global.fetch} fetch - The fetch function to use for the request.
 * @returns {Promise<BOPartner[]>} A promise that resolves to an array of BO partners.
 */
export const fetchBoPartners: Auth0QueryFn<BOPartner[]> =
  fetch =>
  async ({ signal }) => {
    const response = await fetch(endpoint, { signal });

    if (!response.ok) {
      handleResponseNotOk(response);
    }

    return response.json();
  };
