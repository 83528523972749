import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

import {
  shapeAccountNumberToAccountID,
  shapeAccountsObj,
  shapeListByUserid,
} from './manipulations';

export const initialState = {
  byID: {},
  listsByUserID: {},
  accountNumberToID: {},
  blotters: {},
  beneficiaries: {},
  violations: {},
  taxLots: {},
  portfolioStatuses: {},
};

const handlers = {
  GET_ACCOUNTS_FOR_USER_SUCCESS: (state, action) =>
    produce(state, draft => {
      const accountsObj = shapeAccountsObj(action.payload);
      draft.byID = { ...accountsObj, ...draft.byID };
      draft.byID = { ...draft.byID, ...accountsObj };
      const listObj = shapeListByUserid(action.payload);
      Object.keys(listObj).forEach(key => {
        if (!draft.listsByUserID[key]) {
          draft.listsByUserID[key] = [];
        }
        listObj[key].forEach(id => {
          if (!draft.listsByUserID[key].includes(id)) {
            draft.listsByUserID[key].push(id);
          }
        });
      });
      draft.accountNumberToID = shapeAccountNumberToAccountID(action.payload);
    }),
  CREATE_ACCOUNT_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.byID[action.payload.account.id] = action.payload.account;
      if (!draft.listsByUserID[action.payload.account.userID]) {
        draft.listsByUserID[action.payload.account.userID] = [action.payload.account.id];
      } else {
        draft.listsByUserID[action.payload.account.userID].push(action.payload.account.id);
      }
    }),
  UPDATE_ACCOUNT_COMMISSION: (state, action) =>
    produce(state, draft => {
      draft.byID[action.payload.account.id] = action.payload.account;
    }),
  BLOTTER_GET_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.blotters[action.payload.summary.accountID] = action.payload.summary;
    }),
  VIOLATION_GET_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.violations[action.payload.accountID] = action.payload.violations;
    }),
  TAXLOT_GET_SUCCESS: (state, action) => {
    const report = action.payload.report;
    return produce(state, draft => {
      draft.taxLots[report.accountID] = report.positions.reduce((acc, current) => {
        acc[current.symbol] = current.legs;
        return acc;
      }, {});
    });
  },
  ACCOUNT_INFO_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.byID[action.payload.account.id] = action.payload.account;
    }),
  PORTFOLIO_INFO_SUCCESS: (state, action) => {
    return {
      ...state,
      portfolioStatuses: {
        ...state.portfolioStatuses,
        [action.payload.accountID]: action.payload.portfolio,
      },
    };
  },
  GET_ACCOUNTS_FOR_USER_ERROR: null,
  GET_ACCOUNTS_FOR_USER_START: null,
  BLOTTER_GET_ERROR: null,
  BLOTTER_GET_START: null,
  TAXLOTS_GET_START: null,
  TAXLOT_GET_ERROR: null,
  VIOLATIONS_GET_START: null,
  VIOLATIONS_GET_ERROR: null,
  ACCOUNT_GET_ERROR: null,
  ACCOUNT_GET_START: null,
  PORTFOLIO_GET_START: null,
  PORTFOLIO_GET_ERROR: null,
  CHANGE_PORTFOLIO_ERROR: null,
  CHANGE_PORTFOLIO_START: null,
  CHANGE_PORTFOLIO_SUCCESS: null,

  // get account beneficiaries actions
  GET_BENEFICIARIES_START: null,
  GET_BENEFICIARIES_ERROR: null,
  GET_BENEFICIARIES_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.beneficiaries[action.payload.accountID] = action.payload.beneficiaries;
    }),

  // delete account beneficiaries actions
  DELETE_BENEFICIARIES_START: null,
  DELETE_BENEFICIARIES_ERROR: null,
  DELETE_BENEFICIARIES_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.beneficiaries = {};
    }),

  // create beneficiaries actions
  CREATE_BENEFICIARIES_START: null,
  CREATE_BENEFICIARIES_ERROR: null,
  CREATE_BENEFICIARIES_SUCCESS: (state, action) =>
    produce(state, draft => {
      // draft.beneficiaries[action.payload.accountID] = action.payload.beneficiaries;
    }),
};

export default generateReducer(initialState, handlers);
