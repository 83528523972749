import { Auth0QueryFn } from 'app/hooks';
import { handleResponseNotOk } from 'app/services';
import { Account } from 'app/types';

const endpoint = new URL('/api/accounts/', window.location.origin).href;

/**
 * Fetches account details.
 *
 * @param {string} accountId - The unique identifier of the account.
 * @returns {Auth0QueryFn<Account>} A query function that resolves to account object.
 */
export const fetchAccount =
  (accountId: string): Auth0QueryFn<Account> =>
  fetch =>
  async ({ signal }) => {
    const response = await fetch(`${endpoint}${accountId}`, { signal });
    if (!response.ok) {
      handleResponseNotOk(response);
    }
    return response.json() as Promise<Account>;
  };
