import { format } from 'date-fns';
import { isUndefined } from 'lodash';
import {
  MonthlyReportParameters,
  QuarterlyReportParameters,
  ReportParameters,
} from 'src/app/types/reports';
import { isRequiredField, neverReached } from 'src/app/utils';

import { copyText } from '../../internationalization';
import { Report, ReportParameter } from '../../types/bestEx';

const {
  common: { quarter: quarterLabel, month: monthLabel, quarterly, monthly },
  partner: { partner: partnerLabel },
  bestEx: { quarter1, quarter2, quarter3, quarter4 },
} = copyText;

const isMonthlyRequest = (codes: string[]) => {
  return codes.find(code => {
    if (code.includes('monthly')) {
      return true;
    }
    return false;
  });
};

export const getReportHeaderText = (reportCodes: Array<string>): string => {
  const headerText = isMonthlyRequest(reportCodes) ? monthly : quarterly;
  return headerText;
};

export const monthRequired = isRequiredField(monthLabel);
export const quarterRequired = isRequiredField(quarterLabel);
export const partnerRequired = isRequiredField(partnerLabel);

const getQuarterlyReport = (
  reportCode: 'bestex_quarterly' | 'exceptions_quarterly',
  options: QuarterlyReportParameters,
) => {
  const { date, partnerCode, quarterCode } = options;

  if (isUndefined(partnerCode)) {
    throw new Error('Partner cannot be undefined');
  }

  if (isUndefined(quarterCode)) {
    throw new Error('Quarter cannot be undefined');
  }

  if (!date || !partnerCode) {
    throw new Error('Date or partner code null');
  }

  const reportParameters: ReportParameter[] = [
    { parameterName: 'partnerCode', parameterValue: [partnerCode] },
  ];

  if (quarterCode) {
    reportParameters.push({ parameterName: 'quarterCode', parameterValue: [quarterCode] });
  }

  if (date) {
    reportParameters.push({
      parameterName: 'reportYear',
      parameterValue: format(date, 'yyyy'),
    });
  }

  return {
    reportCode,
    reportParameters,
  };
};

const getMonthlyReport = (
  reportCode: 'bestex_monthly' | 'exceptions_monthly',
  options: MonthlyReportParameters,
) => {
  const { date, partnerCode } = options;

  if (isUndefined(partnerCode)) {
    throw new Error('Partner cannot be undefined');
  }

  if (!date || !partnerCode) {
    throw new Error('Date or partner code null');
  }

  const reportParameters: ReportParameter[] = [
    { parameterName: 'partnerCode', parameterValue: [partnerCode] },
  ];

  if (date) {
    reportParameters.push(
      {
        parameterName: 'reportMonth',
        parameterValue: format(date, 'MM'),
      },
      {
        parameterName: 'reportYear',
        parameterValue: format(date, 'yyyy'),
      },
    );
  }

  return {
    reportCode,
    reportParameters,
  };
};

const getReportParameter = (code: string, options: ReportParameters) => {
  switch (code) {
    case 'bestex_monthly': {
      return getMonthlyReport(code, options as MonthlyReportParameters);
    }
    case 'bestex_quarterly': {
      return getQuarterlyReport(code, options as QuarterlyReportParameters);
    }
    case 'exceptions_monthly': {
      return getMonthlyReport(code, options as MonthlyReportParameters);
    }
    case 'exceptions_quarterly': {
      return getQuarterlyReport(code, options as QuarterlyReportParameters);
    }
    default:
      return getMonthlyReport('bestex_monthly', options as MonthlyReportParameters);
  }
};
export const getReportParameters = (
  reportCodes: Array<string>,
  options: ReportParameters,
): Array<Report> => {
  return reportCodes.map((code: string) => {
    return getReportParameter(code, options);
  });
};

export const downloadDocument = (documentText: string, fileName: string): void => {
  if (!documentText || !fileName) return;
  const url = URL.createObjectURL(new Blob([documentText]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
  URL.revokeObjectURL(url);
};

export const getQuarterToReportQuarter = (quarter: 1 | 2 | 3 | 4): string => {
  switch (quarter) {
    case 1:
      return 'Q1';
    case 2:
      return 'Q2';
    case 3:
      return 'Q3';
    case 4:
      return 'Q4';
    default:
      neverReached(quarter);
  }
};

export const getMonthToReportMonth = (month: number): string => {
  return `${month + 1}`.length < 2 ? `0${month + 1}` : `${month + 1}`;
};

export const downloadReport = (url: string): void => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getCopyTextQuarter = (quarter: 1 | 2 | 3 | 4): string => {
  switch (quarter) {
    case 1:
      return quarter1;
    case 2:
      return quarter2;
    case 3:
      return quarter3;
    case 4:
      return quarter4;
    default:
      neverReached(quarter);
  }
};
