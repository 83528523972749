import { Menu, MenuItem } from '@drivehub/ui-components';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from '@mui/material';
import { findEnvVar } from 'app/utils';
import { FC } from 'react';
import { useGetEnvironmentVariables } from 'src/app/hooks';
import styled from 'styled-components';

import { useMenu } from '../../hooks/useMenu';
import { copyText } from '../../internationalization';
import { NavbarIconButton } from './NavbarIconButton';

const LinkMenuItemComp: FC<{ onClick: () => void; href: string; children: React.ReactNode }> = ({
  children,
  href,
  onClick,
}) => (
  <MenuItem href={href} onClick={onClick} component={Link} target="_blank">
    {children}
  </MenuItem>
);

// Issues with hover color globally from semantic
const LinkMenuItem = styled(LinkMenuItemComp)`
  &:hover {
    color: ${props => props.theme.dw.colors.white};
  }
`;

export const Help: FC = () => {
  const { anchorEl, isMenuOpen, toggleMenuOpen } = useMenu<HTMLButtonElement>();

  const { data: envVarData } = useGetEnvironmentVariables();
  const snowURL = findEnvVar(envVarData, 'REACT_APP_SNOW_URL')?.value || null;
  const docsURL = findEnvVar(envVarData, 'REACT_APP_API_DOCS_URL')?.value || null;
  const integrationURL = findEnvVar(envVarData, 'REACT_APP_INTEGRATION_URL')?.value || null;
  const changelogURL = findEnvVar(envVarData, 'REACT_APP_CHANGE_LOG_URL')?.value || null;
  const termsNonProductionURL =
    findEnvVar(envVarData, 'REACT_APP_TERMS_NON_PRODUCTION_URL')?.value || null;

  const {
    navbar: {
      help: { openASupportTicket, apiReference, partnerPlaybook, updatesAndChanges, apiTermsOfUse },
      iconNames: { help },
    },
  } = copyText;

  return (
    <>
      <NavbarIconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={toggleMenuOpen}
        ref={anchorEl}
        title={help}
        data-testid="help-anchor"
      >
        <HelpOutlineIcon />
      </NavbarIconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl.current}
        keepMounted
        open={isMenuOpen}
        onClose={toggleMenuOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <LinkMenuItem onClick={toggleMenuOpen} href={snowURL as string}>
          {openASupportTicket}
        </LinkMenuItem>

        <LinkMenuItem onClick={toggleMenuOpen} href={docsURL as string}>
          {apiReference}
        </LinkMenuItem>
        <LinkMenuItem onClick={toggleMenuOpen} href={integrationURL as string}>
          {partnerPlaybook}
        </LinkMenuItem>
        <LinkMenuItem onClick={toggleMenuOpen} href={changelogURL as string}>
          {updatesAndChanges}
        </LinkMenuItem>

        <LinkMenuItem onClick={toggleMenuOpen} href={termsNonProductionURL as string}>
          {apiTermsOfUse}
        </LinkMenuItem>
      </Menu>
    </>
  );
};
