import { featureTogglesQueries } from 'app/components/queries';
import { FeatureToggle, FeatureToggleKey } from 'app/constants';
import { DefaultError, useAuth0Query } from './auth0';

/**
 * A custom hook that checks if a specific feature toggle is enabled.
 *
 * @param featureToggle - The key of the feature toggle to check
 * @returns A boolean indicating whether the feature is enabled (true) or disabled (false)
 *
 * @example
 * ```tsx
 * const isFeatureEnabled = useFeatureToggle('MY_FEATURE');
 *
 * if (isFeatureEnabled) {
 *   // Feature-specific code
 * }
 * ```
 */
export const useFeatureToggle = (featureToggle: FeatureToggleKey): boolean => {
  const { data: isEnabled } = useAuth0Query<FeatureToggle, DefaultError, boolean>({
    ...featureTogglesQueries.list(),
    select: data => Boolean(data[featureToggle]),
  });

  return Boolean(isEnabled);
};
