import { queryOptions } from 'app/hooks';
import { fetchDisclosures } from './services';

export const disclosureQueries = {
  all: () => queryOptions({ queryKey: ['disclosures'] as const, queryFn: () => () => [] as const }),
  lists: () =>
    queryOptions({
      queryKey: [...disclosureQueries.all().queryKey, 'list'] as const,
      queryFn: () => () => [] as const,
    }),
  list: () =>
    queryOptions({
      queryKey: [...disclosureQueries.lists().queryKey] as const,
      queryFn: fetchDisclosures,
    }),
};
