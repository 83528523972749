//
import { produce } from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  runID: '',
};

const handlers = {
  AUTOPILOT_RUN_SUCCESS: (state, action) =>
    produce(state, draft => {
      draft.runID = action.payload.runID;
    }),
  CLEAR_AUTOPILOT_RUN: (state, action) =>
    produce(state, draft => {
      draft.runID = '';
    }),
  AUTOPILOT_RUN_START: null,
  AUTOPILOT_RUN_ERROR: null,
};

export const portfolioPageReducer = generateReducer(initialState, handlers);
