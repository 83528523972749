//
import generateReducer from 'modules/generateReducer';

const initialState = {
  accessTokenFunction: null,
};

const handlers = {
  SET_ACCESS_TOKEN_FUNCTION: (state, action) => ({
    ...state,
    ...action.payload,
  }),
};

export default generateReducer(initialState, handlers);
