import { copyText } from 'app/internationalization';
import { store } from 'src/store';

const { http } = copyText;

export type HttpErrorOptions = {
  status: number;
  statusText: string;
  message?: string;
};

export class HttpError extends Error {
  public status: number;

  public statusText: string;

  constructor(options: HttpErrorOptions) {
    super(options.message);

    this.status = options.status;
    this.statusText = options.statusText;
  }
}

/**
 * Handles HTTP responses that are not OK (status code != 200) by throwing appropriate HttpError
 * @param r - The Response object from the fetch API
 * @param defaultMessage - Optional custom message to use for 400 Bad Request errors
 * @throws {HttpError} An error with status code, status text and appropriate message
 *
 * @example
 * try {
 *   const response = await fetch('/api/data');
 *   if (!response.ok) {
 *     handleResponseNotOk(response);
 *   }
 * } catch (error) {
 *   // Handle HttpError
 * }
 */
export const handleResponseNotOk = (r: Response, defaultMessage?: string) => {
  const options: HttpErrorOptions = {
    status: r.status,
    statusText: r.statusText,
  };

  switch (r.status) {
    case 400:
      options.message = defaultMessage || http.BAD_REQUEST;
      break;

    case 401:
      options.message = http.UNAUTHORIZED;
      break;

    case 403:
      options.message = http.FORBIDDEN;
      break;

    case 404:
      options.message = http.NOT_FOUND;
      break;

    default:
      options.message = http.INTERNAL_SERVER_ERROR;
      break;
  }

  throw new HttpError(options);
};

export const generateFetch = ({
  getAccessToken,
}: {
  getAccessToken: () => Promise<string>;
}): typeof window.fetch => {
  const wrappedFetch = async (
    input: RequestInfo | URL,
    init: RequestInit = {},
  ): Promise<Response> => {
    const { headers: initHeaders, ...rest } = init;

    const accessToken = await getAccessToken();
    const headers = new Headers(initHeaders);

    // Allow for extensibility
    if (!headers.has('authorization')) {
      headers.append('authorization', `Bearer ${accessToken}`);
    }

    return window.fetch(input, { ...rest, headers });
  };

  return wrappedFetch;
};

/**
 * fetch should generally be used instead of this call.
 * This call does not have the datasrc set.
 * @param input
 * @param init
 * @returns
 * @deprecated
 */
export const initialFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  const headers = new Headers(init?.headers);
  headers.append('z-auth-token', idToken);
  headers.append('authorization', `Bearer ${accessToken}`);

  const data = await window.fetch(input, { ...init, headers });

  if (!data.ok) {
    const message = await data.text();
    throw new HttpError({ status: data.status, statusText: data.statusText, message });
  }

  return data;
};

/**
 * May need to restore this for legacy accesses (Redux and whatnot)
 * @deprecated
 * @param input
 * @param init
 * @returns
 */
export const fetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  // Get the current env, otherwise we need to sub and wait
  const currentEnv = await new Promise<string>(res => {
    const currentState = store.getState();

    if (currentState.settings?.ENV) {
      res(currentState.settings.ENV);

      return;
    }

    const unsub = store.subscribe(() => {
      const current = store.getState().settings.ENV;

      if (current) {
        unsub();
        res(current as string);
      }
    });
  });
  const headers = new Headers(init?.headers);
  headers.append('z-datasrc', currentEnv);

  const requestInit: RequestInit = { ...init, headers };

  return initialFetch(input, requestInit);
};
