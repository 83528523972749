import { queryOptions } from 'app/hooks';
import { fetchSearch } from './services';
import { Permissions } from 'app/constants';
import { skipToken } from '@tanstack/react-query';

export const searchQueries = {
  all: () =>
    queryOptions({
      queryKey: ['searches'] as const,
      queryFn: () => () => [] as const,
    }),
  lists: () =>
    queryOptions({
      queryKey: [...searchQueries.all().queryKey, 'search'] as const,
      queryFn: () => () => [] as const,
    }),
  list: (searchTerm: string) =>
    queryOptions({
      queryKey: [...searchQueries.lists().queryKey, searchTerm] as const,
      queryFn: searchTerm.length > 0 ? fetchSearch(searchTerm) : skipToken,
      scope: Permissions.SEARCH_BAR_VIEW,
    })
};
