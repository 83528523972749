/**
 * Makes an HTTP request using the Fetch API.
 *
 * @deprecated
 * @param {string} method - The HTTP method (GET, POST, PUT, DELETE, etc.).
 * @param {string} endpoint - The URL endpoint for the request.
 * @param {Object} headers - An object containing request headers.
 * @param {string|Object} body - The request body, if applicable.
 * @param {function} cb - A callback function to handle the response.
 *                          The callback receives three arguments:
 *                          - {number} status: The HTTP status code of the response.
 *                          - {Object} resHeaders: An object containing response headers.
 *                          - {string|Blob} responseData: The response data, either as text or a Blob.
 */
export default function httpFetch(method, endpoint, headers, body, cb) {
  fetch(endpoint, {
    method,
    headers,
    body,
  }).then(
    response => {
      const resHeaders = {};
      for (const pair of response.headers.entries()) {
        resHeaders[pair[0]] = pair[1];
      }

      const contentType = resHeaders['Content-Type'] || resHeaders['content-type'] || '';

      if (contentType === 'application/octet-stream') {
        response.blob().then(responseData => {
          cb(response.status, resHeaders, responseData);
        });
      } else {
        response.text().then(responseData => {
          cb(response.status, resHeaders, responseData);
        });
      }
    },
    error => {
      cb(-1, {}, error.toString());
    },
  );
}
