import { employeeQueries } from 'app/components/queries/employees';
import { WhoAmI } from 'app/types';
import { UseAuth0QueryOptions, useAuth0Query } from './auth0';

// This should be removed, but too noisy in this pass. We can do this later in a focused ticket
/**
 * Custom hook to fetch the current authenticated user's information.
 *
 * This hook uses the `useAuth0Query` to send a query to the `whoAmI` endpoint
 * and returns the result. It accepts optional query options to customize the
 * behavior of the query.
 *
 * @param {UseAuth0QueryOptions} [options] - Optional query options to customize the query.
 * @returns {QueryResult} The result of the `whoAmI` query.
 */
export const useWhoAmI = (options?: Partial<UseAuth0QueryOptions<WhoAmI>>) => {
  return useAuth0Query({ ...employeeQueries.whoAmI(), ...options });
};
