import { flatten } from 'lodash';

import { Permission } from '../../../constants/permissions';
import { usePermission } from '../../../hooks/usePermission';
import { RouterConfig } from '../types';

const getPermissions = (configs: RouterConfig[]): Array<Permission | null> => {
  return flatten(
    configs.map(config => {
      // Consider only
      if (config?.subRoutes) {
        return getPermissions(config.subRoutes);
      }

      // Leaf node
      return config.permissions.length > 0 ? config.permissions : [null];
    }),
  );
};

/**
 * Custom hook to recursively check permissions based on the provided router configuration.
 *
 * @param {RouterConfig} config - The router configuration object to check permissions for.
 * @returns {boolean} - Returns `true` if the user has the required permissions or if an empty permission object is encountered, otherwise returns `false`.
 */
export const useRecursivePermissions = (config: RouterConfig): boolean => {
  const allowedPermissions: Array<Permission | null> = getPermissions([config]);
  const hasPermission = usePermission(allowedPermissions);

  // Null is returned from getPermissions to signify an empty permission object
  if (allowedPermissions.includes(null)) {
    return true;
  }

  return hasPermission;
};
