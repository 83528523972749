/*
	displayName - batch name to be used for labels etc.
	permission - permissions needed to create this batch type
	csvInputs - has all the columns used for the Preview and the Details
	csvInputs.COLUMNNAME - the column name MUST be all lowercase
	csvInputs.COLUMNNAME.label - label for the column
	csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
	csvInputs.COLUMNNAME.canSort - should this column be sortable
	csvInputs.COLUMNNAME.required - block upload if this column is missing
	csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
	csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
	groupPreviewBy - either a empty string or the name of the column by which to group all the rows
	exampleData - array of objects to populate the example data table (Create Batch screen)
*/
const finTran = {
  displayName: 'Financial Transaction',
  permission: 'FINTRANS_CREATE_BATCH',
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    accountno: {
      label: 'Account No',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountNo',
    },
    transactiontype: {
      label: 'Transaction Type',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'transactionType',
    },
    batchcode: {
      label: 'Batch Code',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'batchCode',
    },
    amount: {
      label: 'Amount',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'money',
      serverKey: 'amount',
    },
    comment: {
      label: 'Comment',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'comment',
    },
    notesubject: {
      label: 'Note Subject',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'noteSubject',
    },
    note: {
      label: 'Note',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'note',
    },
  },
  checks: {
    columns: {
      transactionType: ['CASH', 'MARGIN'],
    },
  },
  groupPreviewBy: 'accountNo',
  doAccumulatingBalance: true,
  exampleData: [
    {
      accountNo: 'QWERT12345',
      transactionType: 'CSD',
      batchCode: 'ACHFEE',
      amount: 100,
      comment: 'Some comment',
      noteSubject: 'The subject of a note on a user',
      note: 'A note on a user',
    },
  ],
};

export { finTran };
