import { Auth0QueryFn } from 'app/hooks';
import { handleResponseNotOk } from 'app/services';

export type VersionInformation = {
  version: string;
  gitHash: string;
  buildTime: string;
};

export const fetchVersion: Auth0QueryFn<VersionInformation> =
  fetch =>
  async ({ signal }) => {
    const endpoint = new URL('/api/version', window.location.origin).href;
    const data = await fetch(endpoint, { signal });

    if (!data.ok) {
      handleResponseNotOk(data);
    }
    return data.json();
  };
