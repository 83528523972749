import { UnregisterCallback } from 'history';
import { ProviderContext, SnackbarKey, useSnackbar as useNotiSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Options for configuring the useSnackbar hook
 * @property {boolean} clearOnNavigate - When true, all active snackbars will be cleared on route change
 */
export type UseSnackbarOptions = {
  clearOnNavigate?: boolean;
};

type EnqueueSnackbarOptions = Parameters<ProviderContext['enqueueSnackbar']>;

/**
 * Custom hook that extends notistack's useSnackbar with additional functionality.
 * Provides the ability to automatically clear snackbars on route changes and tracks
 * enqueued snackbar items.
 *
 * @param {UseSnackbarOptions} options - Configuration options for the hook
 * @returns {ProviderContext} The enhanced snackbar context with all notistack functionality
 *
 * @example
 * ```tsx
 * const { enqueueSnackbar, closeSnackbar } = useSnackbar({ clearOnNavigate: true });
 *
 * // Show a success message that will be cleared on navigation
 * enqueueSnackbar('Operation successful', { variant: 'success' });
 * ```
 */
export const useSnackbar = ({
  clearOnNavigate = false,
}: UseSnackbarOptions = {}): ProviderContext => {
  const { closeSnackbar, enqueueSnackbar: notistackEnqueueSnackbar, ...rest } = useNotiSnackbar();

  const history = useHistory();
  const [enqueuedItems, setEnqueuedItems] = useState<Array<SnackbarKey>>([]);

  const enqueueSnackbar = useCallback<
    (message: EnqueueSnackbarOptions[0], options?: EnqueueSnackbarOptions[1]) => SnackbarKey
  >(
    (message: EnqueueSnackbarOptions[0], options?: EnqueueSnackbarOptions[1]): SnackbarKey => {
      const key = notistackEnqueueSnackbar(message, options);

      setEnqueuedItems(items => [...items, key]);

      return key;
    },
    [notistackEnqueueSnackbar],
  );

  useEffect(() => {
    let unregister: UnregisterCallback | undefined;

    const listener = () => {
      enqueuedItems.forEach(snackbarKey => closeSnackbar(snackbarKey));
    };

    if (clearOnNavigate) {
      unregister = history.listen(listener);
    }

    return () => {
      unregister?.();
    };
  }, [clearOnNavigate, closeSnackbar, history, enqueuedItems]);

  return {
    closeSnackbar,
    enqueueSnackbar,
    ...rest,
  };
};
